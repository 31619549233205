import * as React from "react";
import * as cx from "classnames";
import {State} from "react-powerplug";

import {sBEM} from "common/type-helpers";

import {CalendarFilter} from "./calendar-filter/index";
import {Calendar} from "./calendar/index";

import "./CalendarSection.scss";
import {DateInterval} from "components/header-in-main/components/calendar-section/calendar/Calendar";
import {webConnect} from "core/redux";
import {INTERVAL_SET} from "core/state/meta/actions";
import {getSelectedInterval} from "core/state/meta/selectors";
import {getFirstLinkDate, getLastLinkDate} from "core/state/websites/selectors";
import {getProjectById} from "core/state/projects/selectors";

interface CalendarPublicProps {
  updateInterval: (interval: DateInterval) => any;
  interval: DateInterval;
  minDate: Date,
  maxDate: Date
}

const dateMin = (min: Date, date: Date): Date =>
  date < min
    ? min
    : date;



const dateMax = (max: Date, date: Date): Date =>
  date > max
    ? max
    : date;

export const BaseCalendarSection: sBEM<CalendarPublicProps> = ({
                                                                 interval,
                                                                 className,
                                                                 updateInterval,
                                                                 minDate,
                                                                 maxDate
                                                               }) => (
  <State
    initial={{
      interval: interval || {},
      pickState: 0
    }}
  >
    {({state, setState}) => (
      <div className={cx(className, "calendar-section")}>
        <Calendar
          className="calendar-section__calendar"
          selection={state.interval}
          minDate={minDate}
          maxDate={maxDate}
          pickDate={date => {
            switch (state.pickState) {
              case 0:
                setState({
                  pickState: 1,
                  interval: {
                    start: dateMin(minDate, dateMax(maxDate, date)),
                    end: dateMin(minDate, dateMax(maxDate, date)),
                  }
                });
                break;
              case 1:
                setState({
                  pickState: 0,
                  interval: {
                    start: dateMin(minDate,
                      date < state.interval.start!
                        ? date
                        : state.interval.start!
                    ),
                    end: dateMax(maxDate, date > state.interval.end! ? date : state.interval.end!)
                  }
                });
            }
          }}
        />
        <CalendarFilter
          className="calendar-section__filter"
          setInterval={({start, end}: DateInterval) => setState({
            pickState: 0,
            interval: {
              start:  start!,
              end: end!,
            }
          })}
          interval={state.interval}
          onSubmit={() => updateInterval(state.interval)}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    )}
  </State>
);

const td = (t: string | undefined) => t && new Date(t);

const ConnectedBaseCalendar = webConnect(
  (state, {projectId}: { projectId: string, toggle: () => void }) => ({
    interval: getSelectedInterval(state, projectId) || {},
    minDate: td(getProjectById(state, projectId).start) || getFirstLinkDate(state, projectId),
    maxDate: td(getProjectById(state, projectId).end) || getLastLinkDate(state, projectId),
  }),
  (dispatch, {toggle}: { toggle: () => void }) => ({
    updateInterval: (interval: DateInterval) => {
      dispatch({type: INTERVAL_SET, payload: interval});
      toggle();
    }
  })
)(BaseCalendarSection);

export const CalendarSection: sBEM<{ toggle: () => void, projectId: string }> = props => (
  <ConnectedBaseCalendar {...props} />
);
