import { put, takeLatest, call, all } from "redux-saga/effects";
import { TITLES_LOAD, TITLES_SET } from "./actions";
import { SagaIterator } from "redux-saga";

import { backend } from "core/config";
import { fetch } from "common/fetch";

const getTitles = (projectId: string, clientId: string) =>
  fetch(`${backend}/${clientId}/titles/${projectId}`).then(data => data.json());

function* titlesLoad_saga({ payload }: any) {
  const { projectId, clientId } = payload;
  try {
    yield put({ type: TITLES_SET, payload: { projectId, loading: true } });
    const data = yield call(getTitles, projectId, clientId);
    yield put({ type: TITLES_SET, payload: { projectId, clientId, data } });
  } catch (e) {
    yield put({ type: TITLES_SET, payload: { projectId, error: true } });
  }
}

export function* titlesSaga(): SagaIterator {
  yield all([takeLatest(TITLES_LOAD, titlesLoad_saga)]);
}
