export const shortenDate = (date: number): string => {
  const delta = (Date.now() - date) / 1000;
  if (delta < 60) return "seconds ago";
  if (delta < 60 * 60) return Math.round(delta / 60) + " minutes ago";
  if (delta < 60 * 60 * 24) return Math.round(delta / (60 * 60)) + " hours ago";
  if (delta < 60 * 60 * 24 * 31)
    return Math.round(delta / (60 * 60 * 24)) + " days ago";
  if (delta < 60 * 60 * 24 * 31 * 12)
    return Math.round(delta / (60 * 60 * 24 * 31)) + " months ago";
  return "ages ago";
};
