import * as React from "react";
import Logotype from "components/header/components/logotype";

import LoginForm from "./LoginForm";

import "./Login.scss";
import {webConnect} from "core/redux";
import {userLoginDidFail} from "core/state/user/selectors";
import {userLogin} from "core/state/user/actions";

import ImageMap from "static/images/map.png";
import ImageMap2X from "static/images/map@2x.png";
// import {digitize} from "common/shortenNumbers";

// const START_NUMBER = 20000;
// const START_DATE = 1532602569783;
// const dt = Date.now() - START_DATE;

// class RandomNumberGenerator extends React.Component<{ children: (n: number) => React.ReactNode }, { n: number }> {
//   private tm: number = 0;
//
//   state = {
//     n: START_NUMBER + (dt / 875) + (dt / 2314) * Math.abs(Math.sin(dt))
//   }
//
//   componentDidMount() {
//     this.tm = window.setInterval(this.update, 500);
//   }
//
//   componentWillUnmount() {
//     window.clearInterval(this.tm);
//   }
//
//   update = () => this.setState({n:
//     this.state.n +
//     2.33 * Math.abs(Math.sin(dt) +
//       Math.sin(this.state.n / 3.3 + dt / 123) * 2 +
//       Math.sin(this.state.n)
//     )});
//
//   render() {
//     //const dt= Date.now() - START_DATE;
//     //const number = START_NUMBER + (dt/875) + (dt/2314)*Math.abs(Math.sin(dt));
//     return this.props.children(Math.round(this.state.n));
//   }
// }

const Page: React.SFC<{
  title?: string;
  loginAction: (data: any) => any;
  isError: boolean;
}> = ({title, loginAction, isError}) => (
  <main className="author">
    <section className="author__left">
      <Logotype className="author__logotype" href="#"/>
      <LoginForm
        className="author__form"
        method="post"
        action="#"
        isError={isError}
        loginAction={loginAction}
      />
    </section>
    <div className="author__right">
      <p className="author__detected-wrapper">
        {/*infringements Detected*/}
        {/*<RandomNumberGenerator>*/}
          {/*{number =>*/}
            {/*<span className="author__detected" children={digitize(number)}/>*/}
          {/*}*/}
        {/*</RandomNumberGenerator>*/}
      </p>
      <div className="author__image-wrapper">
        <img
          className="author__image"
          src={ImageMap}
          srcSet={`${ImageMap2X} 2x`}
          alt="Map"
        />
      </div>
      <p className="author__note" children="You Create it. We Protect it"/>
    </div>
  </main>
);

export default webConnect(
  state => ({
    isError: userLoginDidFail(state)
  }),
  {
    loginAction: userLogin
  }
)(Page);
