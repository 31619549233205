import * as React from "react";
import * as cx from "classnames";

import SvgNotice from "svg-react-loader!./images/icons/notice.svgx";
import SvgExit from "svg-react-loader!./images/icons/exit.svgx";
import SvgBack from "svg-react-loader!./images/icons/back.svgx";
import SvgSort from "svg-react-loader!./images/icons/triangle.svgx";
import SvgClose from "svg-react-loader!./images/icons/close.svgx";
import SvgPen from "svg-react-loader!./images/icons/pen.svgx";

import "./SvgLink.scss";
import { sBEM } from "common/type-helpers";
import { reactType } from "common/reactType";

interface Props {
  mod?: string;
  linkCaption?: string;
  href?: string;
  svgIcon?: React.ReactNode;
  hidden?: boolean;
  active?: boolean;
  rotate?: boolean;
  inlineBlock?: boolean;
  onClick?: () => void;
  Component?: React.ComponentClass<any>;
}

export const BaseSvgLink: sBEM<Props> = ({
  className,
  mod,
  linkCaption,
  href,
  svgIcon,
  hidden,
  active,
  rotate,
  inlineBlock,
  onClick,
  Component = reactType(<span />)
}) => (
  <Component
    className={cx(className, "svg-link", {
      [`svg-link--${mod}`]: !!mod,
      "svg-link--hidden": hidden,
      "svg-link--active-sort": active,
      "svg-link--rotate": rotate,
      "svg-link--inline-block": inlineBlock
    })}
    href={href}
    onClick={onClick}
  >
    <span className="svg-link__caption" children={linkCaption} />
    <span className="svg-link__svg-wrapper" children={svgIcon} />
  </Component>
);

const ButtonSvgLink: sBEM<Props> = props => (
  <BaseSvgLink {...props} Component={reactType(<button />)} />
);

export const ExitSvgLink: sBEM<Props> = props => (
  <BaseSvgLink {...props} mod="exit" children="exit" svgIcon={<SvgExit />} />
);

export const BackSvgLink: sBEM<Props> = props => (
  <BaseSvgLink {...props} mod="back" children="back" svgIcon={<SvgBack />} />
);

export const NoticeSvgLink: sBEM<Props> = props => (
  <ButtonSvgLink
    {...props}
    mod="notice"
    children="notice"
    svgIcon={<SvgNotice />}
  />
);

export const CloseSvgLink: sBEM<Props> = props => (
  <ButtonSvgLink
    {...props}
    mod="close"
    children="close"
    svgIcon={<SvgClose />}
  />
);

export const AddSvgLink: sBEM<Props> = props => (
  <ButtonSvgLink
    {...props}
    mod="add-member"
    children="add"
    svgIcon={<SvgPen />}
  />
);

export const SortSvgLink: sBEM<
  Props & { selected?: boolean; order?: number | boolean }
> = ({ selected, order, ...props }) => (
  <ButtonSvgLink
    {...props}
    mod="sort"
    active={selected}
    rotate={order === -1}
    children="sorting"
    svgIcon={<SvgSort />}
    inlineBlock
  />
);
