import {MetaState} from "core/state/meta/reducer";
import {countryToIso} from "core/state/meta/country2Iso";
import {getTitles} from "core/state/titles/selectors";
import { DateInterval } from "components/header-in-main/components/calendar-section/calendar/Calendar";

let previousProjectId: string | null | undefined = null;
export const getSelectedInterval = ({meta}: { meta: MetaState }, projectId?: string | null): DateInterval => 
{
  if (projectId != previousProjectId) meta.visibleInterval = { start: undefined, end: undefined };
  
  var res = projectId && previousProjectId && projectId != previousProjectId && (previousProjectId = projectId) && (meta.visibleInterval = { start: undefined, end: undefined })
  || projectId && ((previousProjectId = projectId) && meta.visibleInterval)
  || meta.visibleInterval;
  return res;
}
  

  

export const getTitlesSelected = (store: any, projectId: string) => {
  const {meta}: { meta: MetaState } = store;
  const selected = meta.titlesSelected[projectId];

  // preselect "all" titles
  if (!selected || !Object.keys(selected).length) {
    const data = getTitles(store, projectId);
    return data.reduce((acc: any, item: any) => {
      acc[item.id] = true;
      return acc;
    }, {});
  }
  return selected;
};

export const getCountries = ({meta}: { meta: MetaState }) =>
  meta.countries.reduce((acc: any, {name, id, iso}) => {
    acc[id] = {
      name,
      id,
      code: iso || countryToIso[id]
    };
    return acc;
  }, {});

export const isoToCountryId = (iso: string, {meta}: { meta: MetaState }): number => {
  const country = meta.countries.filter((item) => item.iso == iso)[0];
  return country ? country.id : 0;
};

export const isDownloadInProgress = ({meta}: { meta: MetaState }) => meta.downloadStatus;