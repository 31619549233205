import * as React from "react";
import * as cx from 'classnames';
import Locky from 'react-locky';
import {Toggle} from "react-powerplug";

import SvgArrow from "svg-react-loader!./images/icons/triangle.svgx";

import "./DropDown.scss";

export const DropDown: React.SFC<{
  selected: string,
  style: React.CSSProperties,
  className: string,
  options: {
    name: string
  }[];
  onChange: (item: any) => any;
}> = ({onChange, selected, options, style, className}) => (
  <div style={style} className={cx(className, "dropdown")}>
    <Toggle initial={false}>
      {({on, toggle}) => (
        <Locky className="dropdown__wrapper" enabled={on} onEscape={toggle} noDefault events={{click: 'report'}}>
          <p className="dropdown__result" onClick={toggle}>
            {selected}
            <span className={cx("dropdown__icon", {["dropdown__icon--rotate"]:on})}>
              <SvgArrow style={{width: '6px', height: '4px', fill: '#0C3B7B'}}/>
            </span>
          </p>
          {on && (
            <ul className="dropdown__list">
              {options.map(item => (
                <li
                  className={cx("dropdown__item", item.name===selected && 'dropdown__item--current')}
                  onClick={() => (onChange(item), toggle())}
                  children={item.name}
                />
              ))}
            </ul>
          )}
        </Locky>
      )}
    </Toggle>
  </div>
);
