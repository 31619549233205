import { NotificationState } from "core/state/notifications/reducer";

export const getNotification = (id: number) => ({
  notifications
}: {
  notifications: NotificationState;
}) => notifications.data[id];
export const getNotifications = ({
  notifications
}: {
  notifications: NotificationState;
}) => notifications.data;
