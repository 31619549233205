var React = require('react');

function Analysis (props) {
    return React.createElement("svg",props,React.createElement("g",{"strokeWidth":"1","fill":"none","fillRule":"evenodd","transform":"translate(-199.000000, -735.000000)","strokeLinecap":"round"},React.createElement("polyline",{"strokeWidth":"3","points":"201 744.887135 205.820554 748.608395 214.000977 737"})));
}

Analysis.displayName = "Analysis";

Analysis.defaultProps = {"width":"17px","height":"16px","viewBox":"0 0 17 16","version":"1.1"};

module.exports = Analysis;

Analysis.default = Analysis;
