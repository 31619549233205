import * as React from "react";
import * as cx from "classnames";

import {Minus, sBEM} from "common/type-helpers";
import {WebsitesStateData, WebsitesStateDataItem, WebsiteVisit} from "core/state/websites/reducer";
import {Predicate, PublicProps, TableDataControl} from "./Table";
import {shortenNumber} from "common/shortenNumbers";
import {webConnect} from "core/redux";
import {
    getCountryMatch,
    getSiteMatch,
    getWebsite,
    getWebsites,
    getWebsitesForCountryInMatch
} from "core/state/websites/selectors";
import {DataForTerritoriesPeersTable, DataForTitlesTable} from "./mocks";
import {WEBSITE_TYPES} from "core/state/websites/state";
import {Link, Route, withRouter} from "react-router-dom";
import SvgArrow from "svg-react-loader!./images/icons/arrow.svgx";
import Arrow from "svg-react-loader!./images/icons/button-arrow.svgx";
import {RouteComponentProps, Switch} from "react-router";
import {getCountries} from "core/state/meta/selectors";
import {SmallBlueButton} from "components/UIKit/button";

interface PreconnectedData {
    counter: any;
    titleHead: any;
}

interface PrepopulatedData {
    counter: any;
    titleHead: any;
    tableData: any;
}

const BaseViewTerritoriesLink: React.SFC<RouteComponentProps<any> & { element: any }> = ({match, element}) => (
    <Link
        className="table-data__link"
        to={`${match.url}/website/${element.host}`}
    >
        view territories
        <SvgArrow className="table-data__svg"/>
    </Link>
);

const RoutedViewTerritoriesLink = withRouter(BaseViewTerritoriesLink);

const ViewTerritoriesLink = (row: any) => (
    <RoutedViewTerritoriesLink element={row}/>
);

const InnerRemoveWebsitesLinkComponent: React.SFC<RouteComponentProps<any>> = ({
                                                                              match
                                                                          }) => (
    <SmallBlueButton
        Component={Link}
        to={match.url}
        caption="Show all websites"
        className={cx("table-data__back", "table-data__back--websites")}
        svg={<Arrow style={{transform: 'rotate(180deg)', marginLeft: 10, marginRight: -9}}/>}
    />
);

const RemoveWebsitesLinkComponent: React.SFC<RouteComponentProps<any>> = ({match}) => (
  <Switch>
    <Route path={`${match.url}/website/:id/`} render={(rprops) => <InnerRemoveWebsitesLinkComponent {...rprops} />}/>
    <Route render={(rprops) => <InnerRemoveWebsitesLinkComponent {...rprops} />} />
  </Switch>
);

const RemoveWebsitesLink = withRouter(RemoveWebsitesLinkComponent);

const WebsitePredicates: Predicate[] = [
  {
    name: 'Covered',
    selector: (item:WebsitesStateDataItem) => item.outOfCoverage,
    view: {
      header: <div className="table-data__type-caption">Covered</div>
    }
  },
  {
    name: 'Out of Coverage',
    selector: (item:WebsitesStateDataItem) => !item.outOfCoverage,
    view: {
      header: <div className="table-data__type-caption">Out of Coverage</div>
    }
  }
];

const InnerWebsitesTableData: sBEM<Minus<PublicProps & {
    tableData: WebsitesStateData;
    selectedSite: any;
    selectedCountry: any;
},
    PreconnectedData>> = ({selectedSite, selectedCountry, ...props}) => (
    <TableDataControl
        {...props}
        counter
        isSelected={(col: any) => col.host === selectedSite}
        titleHead={{
            cols: [
                {
                    width: 30,
                    content: "websites",
                    key: "host"
                },
                {
                    content: "Mon. visits",
                    width: 20,
                    display: (n: number) => (n ? shortenNumber(n) : 0) || 'N/A',
                    key: "visitsCount"
                },
                {
                    content: "Type",
                    width: 20,
                    display: (type: string) => WEBSITE_TYPES[type],
                    key: "type"
                },
                {
                    content: "Links",
                    width: 15,
                    key: "count",
                    display: shortenNumber,
                },
                {
                    content: "Compl.",
                    width: 15,
                    key: "compliance",
                    display: (n: number) => (n ? shortenNumber(n) : 0) || 'N/A',
                }
            ]
        }}
        tableFooter={selectedCountry ? <RemoveWebsitesLink/> : null}
        absoluteCol={ViewTerritoriesLink}
        predicates={WebsitePredicates}
        groupControl
        defaultGroup="All websites"
        //tableData={props.tableData}
    />
);

const ConnectedWebsitesTableData = webConnect(
    (
        state,
        {
            projectId,
            match,
            location
        }: { projectId: string } & RouteComponentProps<any>
    ) => ({
        tableData: getWebsitesForCountryInMatch(state, projectId, location, match),
        selectedSite: getSiteMatch(match, location),
        selectedCountry: getCountryMatch(match, location)
    })
)(InnerWebsitesTableData);

export const WebsitesTableData = withRouter(ConnectedWebsitesTableData);

export const TitlesTableData: sBEM<Minus<PublicProps, PrepopulatedData>> = props => (
    <TableDataControl
        {...props}
        counter
        titleHead={{
            cols: [
                {
                    width: 40,
                    content: "Titles"
                },
                {
                    content: "# of Peers",
                    width: 20,
                    display: shortenNumber,
                },
                {
                    content: "Torrent files",
                    width: 20,
                    display: shortenNumber,
                },
                {
                    content: "Download size",
                    width: 20,
                    display: shortenNumber,
                }
            ]
        }}
        absoluteCol={ViewTerritoriesLink}
        tableData={DataForTitlesTable}
    />
);

const InnerBaseViewWebsitesLink: React.SFC<RouteComponentProps<any> & { element: any }> = ({match, element}) => (
    <Link
        className={cx("table-data__link", "table-data__link--reverse")}
        to={`${match.url}/country/${element.code}`}
    >
        view websites
        <SvgArrow className="table-data__svg"/>
    </Link>
);

const BaseViewWebsitesLink: React.SFC<RouteComponentProps<any> & { element: any }> = (props) => (
  <Switch>
    <Route path={`${props.match.url}/website/:id/`} render={(rprops) => <InnerBaseViewWebsitesLink {...props} {...rprops} />}/>
    <Route render={() => <InnerBaseViewWebsitesLink {...props} />} />
  </Switch>
);

const RoutedViewWebsitesLink = withRouter(BaseViewWebsitesLink);

const ViewWebsitesLink = (row: any) => <RoutedViewWebsitesLink element={row}/>;

const RemoveTerritoriesLinkComponent: React.SFC<RouteComponentProps<any>> = ({
                                                                                 match
                                                                             }) => (
    <SmallBlueButton
        Component={Link}
        to={match.url}
        caption="Show all countries"
        className={cx("table-data__back", "table-data__back--countries")}
        svg={<Arrow/>}
    />
);

const RemoveTerritoriesLink = withRouter(RemoveTerritoriesLinkComponent);

const UnconnectedTerritoriesTableData: sBEM<Minus<PublicProps, PrepopulatedData> & {
    tableData: any;
    selectedCountry: string;
    siteSelected: string;
}> = ({selectedCountry, siteSelected, ...props}) => (
    <TableDataControl
        {...props}
        isSelected={(col: any) => col.code === selectedCountry}
        titleHead={{
            cols: [
                {
                    width: 60,
                    content: "Territories"
                },
                {
                    right: true,
                    content: "Total monthly visits",
                    width: 40,
                    display: shortenNumber
                }
            ]
        }}
        tableFooter={siteSelected ? <RemoveTerritoriesLink/> : null}
        right={{
            visit: true
        }}
        absoluteCol={ViewWebsitesLink}
    />
);

export const TerritoriesTableData = withRouter(
    webConnect(
        (
            state,
            {
                projectId,
                match,
                location
            }: { projectId: string } & RouteComponentProps<any>
        ) => {
            const countries = getCountries(state);
            const siteSelected = getSiteMatch(match, location);
            const sites = siteSelected
                ? [
                    getWebsite(state, projectId, getSiteMatch(match, location)) || {
                        visits: []
                    }
                ]
                : getWebsites(state, projectId);

            const result = {};
            const tableData: any[] = [];
            sites.forEach(
                (site: any) =>
                    site.visits &&
                    site.visits.map((visit: WebsiteVisit) => {
                        const name = countries[visit.countryId].name;
                        const hits = Math.round(site.visitsCount * visit.visitsFraction);
                        if (!result[name]) {
                            result[name] = {
                                name,
                                hits,
                                code: countries[visit.countryId].code
                            };
                            tableData.push(result[name]);
                        } else {
                            result[name].hits += hits;
                        }
                    })
            );

            return {
                tableData,
                selectedCountry: getCountryMatch(match, location),
                siteSelected
            };
        }
    )(UnconnectedTerritoriesTableData)
);

export const TerritoriesPeersTableData: sBEM<Minus<PublicProps, PrepopulatedData>> = props => (
    <TableDataControl
        {...props}
        modTable="territories-peers"
        titleHead={{
            cols: [
                {
                    width: 40,
                    content: "Territories"
                },
                {
                    width: 40,
                    display: number => (
                        <React.Fragment>
                            <span className="table-data__scale-outer"/>
                            <span
                                className="table-data__scale"
                                style={{width: `${number}%`}}
                            />
                        </React.Fragment>
                    )
                },
                {
                    right: true,
                    content: "# of Peers",
                    width: 20,
                    display: shortenNumber
                }
            ]
        }}
        tableData={DataForTerritoriesPeersTable}
        right={{
            visit: true
        }}
        withoutDataLink
    />
);
