import * as React from "react";
import * as cx from "classnames";

import "./DeleteTeamMember.scss";
import { sBEM } from "common/type-helpers";
import { BigBlueButton } from "components/UIKit/button";
//import { connect } from "react-redux";
import { deleteUser } from "core/state/user/actions";
import {webConnect} from "core/redux";

const DeleteTeamMemberForm: sBEM<{
 // userId: string;
  onClose: () => void;
  deleteUser: () => {};
}> = ({ className, onClose, deleteUser }) => (
  <div className={cx(className, "delete-form")}>
    <BigBlueButton
      className="delete-form__button"
      caption="yes"
      onClick={deleteUser}
    />
    <BigBlueButton
      className="delete-form__button"
      caption="no"
      onClick={onClose}
    />
  </div>
);

export default webConnect(
  null,
  (dispatch, { userId, onClose }: { userId: string; onClose: () => void }) => ({
    deleteUser: () => {
      dispatch(deleteUser({ id: userId }));
      onClose();
    }
  })
)(DeleteTeamMemberForm);
