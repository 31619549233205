import * as React from "react";

import Header from "components/header";
import { HeaderInMainScanningOnly } from "components/header-in-main";
import { DashboardScanningOnly } from "components/dashboard";
import { FilterTitlesModal } from "components/modal";

import "./ScanningOnly.scss";
import { closeModal, openModal } from "core/state/modal/actions";
import { connect } from "react-redux";
import { getActiveModalName } from "core/state/modal/selectors";
import { StoreShape } from "core/reducers";
import { withPackageDataLoader } from "components/dataHOC/mountLoaders";
import { loadTitles } from "core/state/titles/actions";
import {
  loadWebsiteLinks,
  loadWebsites,
  loadWebsiteTypes
} from "core/state/websites/actions";
import { getTitlesSelected } from "core/state/meta/selectors";
import { countTrue } from "common/utils";
import { fetchCountries } from "core/state/meta/actions";

const MODAL_NAME = "FilterTitlesModal";

const mapStateToProp = (
  state: StoreShape,
  { projectId }: { projectId: string }
) => ({
  activeModal: getActiveModalName(state),
  titlesSelected: countTrue(getTitlesSelected(state, projectId))
});

const Page: React.SFC<{
  titlesSelected: number;
  activeModal: string;
  projectId: string;
  openFilterModal: () => void;
  closeFilterModal: () => void;
}> = ({
  titlesSelected,
  projectId,
  activeModal,
  openFilterModal,
  closeFilterModal
}) => (
  <div className="page">
    <Header className="page__header" />
    <main className="page__main">
      <HeaderInMainScanningOnly
        className="page__navigation"
        titlePage="Scanning only"
        captionSelected={`${titlesSelected} Titles selected`}
        onCaptionSelectedClick={openFilterModal}
        projectId={projectId}
      />
      <DashboardScanningOnly
        className="page__dashboard"
        projectId={projectId}
      />
    </main>
    <FilterTitlesModal
      className="page__modal"
      visible={activeModal === MODAL_NAME}
      onClose={closeFilterModal}
    />
  </div>
);

const mapDispatchToState = (dispatch: any) => ({
  openFilterModal: () => dispatch(openModal(MODAL_NAME)),
  closeFilterModal: () => dispatch(closeModal(MODAL_NAME))
});

export default withPackageDataLoader({
  page: [fetchCountries],
  hook: [loadTitles, loadWebsites, loadWebsiteTypes, loadWebsiteLinks]
})(connect(mapStateToProp, mapDispatchToState)(Page));
