function repeatData100Times<T>(value: T[]) {
  return Array(2)
    .fill(value)
    .reduce((acc, x) => [...acc, ...x], []);
}

export const DataForWebsitesTable = repeatData100Times([
  {
    name: "hdrezka.me",
    visit: 2197000,
    type: "online",
    links: 2000,
    complete: "75%"
  },
  {
    name: "bigcinema.to",
    visit: 2051000,
    type: "DCC",
    links: 28,
    complete: "90%"
  },
  {
    name: "extratorrent.cc",
    visit: 1895000,
    type: "Streaming",
    links: 25,
    complete: "85%"
  },
  {
    name: "extratorrent.cc",
    visit: 1315111,
    type: "Streaming",
    links: 21,
    complete: "72%"
  },
  {
    name: "bigcinema.to",
    visit: 1211000,
    type: "Streaming",
    links: 18,
    complete: "30%"
  },
  {
    name: "hdrezka.me",
    visit: 2196000,
    type: "online",
    links: 14,
    complete: "75%"
  },
  {
    name: "bigcinema.to",
    visit: 2051000,
    type: "DCC",
    links: 28,
    complete: "90%"
  },
  {
    name: "extratorrent.cc",
    visit: 1895000,
    type: "Streaming",
    links: 25,
    complete: "85%"
  },
  {
    name: "extratorrent.cc",
    visit: 1315000,
    type: "Streaming",
    links: 21,
    complete: "72%"
  },
  {
    name: "bigcinema.to",
    visit: 1315000,
    type: "DCC",
    links: 15,
    complete: "21%"
  }
]);

export const DataForTitlesTable = repeatData100Times([
  {
    name: "Interstellar (2014)",
    peers: 206757500,
    torrent: 53,
    size: 53
  },
  {
    name: "Star Wars: Episode VI - Return…",
    peers: 28757575,
    torrent: 141,
    size: 141
  },
  {
    name: "Aliens",
    peers: 25,
    torrent: 10,
    size: 10
  },
  {
    name: "2001: A Space Odyssey",
    peers: 21,
    torrent: 53,
    size: 53
  },
  {
    name: "Men in Black",
    peers: 18,
    torrent: 141,
    size: 141
  },
  {
    name: "Le Voyage dans la lune",
    peers: 14,
    torrent: 10,
    size: 10
  },
  {
    name: "Armageddon",
    peers: 28,
    torrent: 53,
    size: 53
  },
  {
    name: "Guardians of the Galaxy",
    peers: 25,
    torrent: 141,
    size: 141
  },
  {
    name: "Apollo 13",
    peers: 21,
    torrent: 10,
    size: 10
  },
  {
    name: "The Martian",
    peers: 15,
    torrent: 53,
    size: 53
  }
]);

export const DataForTerritoriesTable = repeatData100Times([
  { name: "Russian Federation", visit: 2197000 },
  { name: "United States", visit: 2051000 },
  { name: "Ukraine", visit: 1895000 },
  { name: "India", visit: 1315000 },
  { name: "United Kingdom", visit: 1211000 },
  { name: "Russian Federation", visit: 2197000 },
  { name: "United States", visit: 2051000 },
  { name: "Ukraine", visit: 1895000 },
  { name: "India", visit: 1315000 },
  { name: "Belaruss", visit: 1315000 }
]);

export const DataForTerritoriesPeersTable = [
  { name: "Russian Federation", scale: 60, visit: 2197000 },
  { name: "United States", scale: 50, visit: 2051000 },
  { name: "Ukraine", scale: 30, visit: 1895000 },
  { name: "India", scale: 5, visit: 1315000 },
  { name: "United Kingdom", scale: 5, visit: 1211000 }
];
