export default {
  authenticated: false,
  authenticationFailed: false,
  selectedUser: "",
  masterUser: "",
  details: {
    "0": {
      id: "0",
      name: "unknown",
      timeZone: 12,
      timeFormat: 0,
      dailyReports: true,
      sendNoticeOnNewCopy: true,
      emails: [
        {
          id: "0",
          mail: "test@maol.ru",
          name: "Tester",
          sentDailyReports: false,
          sentNotices: false
        }
      ],
      canImpersonate: [
        {
          id: "0",
          name: "213635ac-adad-4265-9b47-ee329ea277e8",
          archived: false
        }
      ]
    }
  }
};
