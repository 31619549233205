import * as React from "react";
import * as cx from "classnames";

import "./AccountDashboard.scss";
import { sBEM } from "common/type-helpers";
import { ConnectedAccountTeam } from "./components/account-team";
import { AccountNotifications } from "./components/account-notifications";
import { AccountTime } from "./components/account-time";

export const BaseAccountDashboard: sBEM<{
  openAddMember: () => any;
  openEditMember: (id: string) => any;
  openDeleteMember: (id: string) => any;
  openEmailReceivers: (key: string) => any;
}> = ({
  className,
  openAddMember,
  openEditMember,
  openEmailReceivers,
  openDeleteMember
}) => (
  <div className={cx(className, "account")}>
    <ConnectedAccountTeam
      className={"account__team"}
      onClickAdd={openAddMember}
      onClickEdit={openEditMember}
      onClickDelete={openDeleteMember}
    />
    <AccountNotifications
      className={"account__notifications"}
      onClick={openEmailReceivers}
    />
    <AccountTime className={"account__time"} />
  </div>
);

export const AccountDashboard = BaseAccountDashboard;
