import { WebsitesState } from "core/state/websites/reducer";
import {metaDataSelector, metaSelector} from "core/state/comon";
import { matchPath } from "react-router-dom";
import { isoToCountryId } from "core/state/meta/selectors";
import * as H from "history";
import { match } from "react-router";

export const getWebsites = (
  { websites }: { websites: WebsitesState },
  projectId: string
) => metaSelector(websites, projectId, "sites", []);
export const getWebsiteTypes = (
  { websites }: { websites: WebsitesState },
  projectId: string
) => metaSelector(websites, projectId, "types", []);
export const getWebsitesLinks = (
  { websites }: { websites: WebsitesState },
  projectId: string
) => metaSelector(websites, projectId, "links", {}).intervals || [];

export const isWebsitesLinksLoading = (
  { websites }: { websites: WebsitesState },
  projectId: string
) => !!metaDataSelector(websites, projectId, "links").loading


export const getFirstLinkDate = (
  { websites }: { websites: WebsitesState },
  projectId: string
): Date => new Date(metaSelector(websites, projectId, "links", {}).firstLink || "2012");

export const getLastLinkDate = (
  { websites }: { websites: WebsitesState },
  projectId: string
): Date => new Date(metaSelector(websites, projectId, "links", {}).lastLink || Date.now());

export const getWebsite = (
  { websites }: { websites: WebsitesState },
  projectId: string,
  website: string
) =>
  getWebsites({ websites }, projectId).find(
    (site: any) => site.host === website
  );

export const getCountryMatch = (
  match: match<any>,
  location: H.Location
): string => {
  const countryMatch: any = matchPath(location.pathname, {
    path: `*/country/:iso`
  });
  return countryMatch ? countryMatch.params.iso : "";
};

export const getSiteMatch = (
  match: match<any>,
  location: H.Location
): string => {
  const siteMatch: any = matchPath(location.pathname, {
    path: `${match.url}/website/:website`
  });
  return siteMatch ? siteMatch.params.website : "";
};

const filterByCountry = (websites: any, countryId: number) =>
  countryId
    ? websites
        .map((site: any) => ({
          ...site,
          visits:
            site.visits &&
            site.visits.filter((visit: any) => visit.countryId == countryId)
        }))
        .filter((site: any) => site.visits && site.visits.length)
        .map((site: any) => ({
          ...site,
          visitsCount: Math.round(
            site.visitsCount * site.visits[0].visitsFraction
          )
        }))
    : websites;

export const getWebsitesForCountryInMatch = (
  state: any,
  projectId: string,
  location: H.Location,
  match: match<any>
) =>
  filterByCountry(
    getWebsites(state, projectId),
    isoToCountryId(getCountryMatch(match, location), state)
  );
