let counter = 0;

const map = new Map<any, number>();

export const uid = (item: any): string => {
  if (!map.has(item)) {
    map.set(item, counter++);
    return uid(item);
  }
  return "uid" + map.get(item);
};
