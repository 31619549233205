export const PROJECT_TYPE_PACKAGE = 0;
export const PROJECT_TYPE_PREMIER = 1;
export const PROJECT_TYPE_SCANNING_RESULTS = 2;
export const PROJECT_TYPE_SCANNING_ONLY = 3;
export const PROJECT_TYPE_GEO = 4;

export default {
  data: [
    {
      titlesScanned: 0,
      websitesFound: 0,
      linksCount: 0,
      id: "4",
      type: 2,
      detected: "date",
      detectedOn: "site",
      start: "2017-06-15T00:00:00",
      end: "2018-06-10T00:00:00",
    },
    {
      titlesScanned: 20,
      websitesFound: 0,
      linksCount: 1522569,
      title: "Despicable Me",
      id: "5",
      type: 2,
      start: "2017-06-15T00:00:00",
      end: "2018-06-10T00:00:00",
    },
    { titlesScanned: 5, websitesFound: 0, linksCount: 69706, id: "9", type: 2 },
    {
      type: PROJECT_TYPE_PREMIER,
      id: "1",

      title: "Despicable Me",
      detectedLink: "2521",
      linksCount: "1752",
      CDIssue: "561",
      DMCAIssue: "1400"
    },
    {
      type: "NotAvailablePremierCard",

      title: "Marvel: The Avengers 3"
    },
    {
      type: PROJECT_TYPE_PACKAGE,
      id: "2",

      title: "Digital Project",
      detectedLink: "1475",
      linksCount: "456",
      CDIssue: "541"
    },
    {
      type: PROJECT_TYPE_SCANNING_RESULTS,
      id: "3",

      title: "Scanning project",
      detectedLink: "1500",
      titlesIssue: "89",
      websitesIssue: "251"
    },
    {
      type: PROJECT_TYPE_GEO,
      id: "44",

      title: "Scanning project",
      detectedIPS: "115",
      titlesIssue: "37",
      websitesIssue: "147"
    }
  ],
  completed: [
    {
      titlesScanned: 0,
      websitesFound: 0,
      linksCount: 0,
      id: "4",
      type: 2,
      detected: "date",
      detectedOn: "site",
      start: "2017-06-15T00:00:00",
      end: "2018-06-10T00:00:00",
    },
    {
      titlesScanned: 20,
      websitesFound: 0,
      linksCount: 1522569,
      title: "Despicable Me",
      id: "5",
      type: 2,
      start: "2017-06-15T00:00:00",
      end: "2018-06-10T00:00:00",
    },
    { titlesScanned: 5, websitesFound: 0, linksCount: 69706, id: "9", type: 2 },
    {
      type: PROJECT_TYPE_PREMIER,
      id: "1",

      title: "Despicable Me",
      detectedLink: "2521",
      linksCount: "1752",
      CDIssue: "561",
      DMCAIssue: "1400"
    },
    {
      type: "NotAvailablePremierCard",

      title: "Marvel: The Avengers 3"
    },
    {
      type: PROJECT_TYPE_PACKAGE,
      id: "2",

      title: "Digital Project",
      detectedLink: "1475",
      linksCount: "456",
      CDIssue: "541"
    },
    {
      type: PROJECT_TYPE_SCANNING_RESULTS,
      id: "3",

      title: "Scanning project",
      detectedLink: "1500",
      titlesIssue: "89",
      websitesIssue: "251"
    },
    {
      type: PROJECT_TYPE_GEO,
      id: "44",

      title: "Scanning project",
      detectedIPS: "115",
      titlesIssue: "37",
      websitesIssue: "147"
    }
  ]
};
