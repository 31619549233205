import * as React from "react";
import Page from "./MasterAccount";
import { RouteComponentProps, withRouter } from "react-router";

class TopPage extends React.Component<RouteComponentProps<any>> {
  componentDidMount() {
    //this.props.history.push("/projects");
  }

  render() {
    return <Page />;
  }
}

export default withRouter(TopPage);
