import * as React from "react";
import * as ReactDOM from "react-dom";
import "babel-polyfill";

// import 'core-js/fn/object/assign';
// import 'core-js/fn/object/values';
// import 'core-js/fn/object/entries';
//
// import 'core-js/fn/map';
// import 'core-js/es6/map'
//
// import 'core-js/fn/array/fill';
// import 'core-js/fn/array/find';
// import 'core-js/fn/promise';
import 'whatwg-fetch';

import { Application } from "./Application/Application";

const root = document.createElement("div");
document.body.appendChild(root);

ReactDOM.render(<Application />, root);
