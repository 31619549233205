export const digitize = (number: number) =>
  String(number).replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");

export const dotDigitize = (number: number) =>
  String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const shortenNumber = (number: number): string => {
  if (number === undefined) return '';
  if (number > 100000000) return `${digitize(Math.round(number / 1000000))}M`;
  if (number > 10000) return `${digitize(Math.round(number / 1000))}k`;
  return digitize(number);
};
