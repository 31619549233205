export const USER_LOGIN = "user/LOGIN";
export const USER_LOGOUT = "user/LOGOUT";

export const USER_GET_ACCOUNT = "user/ACCOUNT_INFO--GET";
export const USER_AUTHENTICATED = "user/AUTHENTICATED--SET";
export const USER_ERROR = "user/ERROR--SET";
export const USER_ACCOUNT_INFO = "user/ACCOUNT_INFO--SET";

export const USER_SELECT = "user/SELECT";

export const USER_CREATE = "user/CREATE";
export const USER_EDIT = "user/EDIT";
export const USER_DELETE = "user/DELETE";
export const USER_SET_NOTIFICATION = "user/SET-NOTIFICATION";
export const USER_SET_BLOCK_NOTIFICATION = "user/SET-BLOCK-NOTIFICATION";

export const SET_TIMEZONE = "user/SET-TIMEZONE";
export const SET_TIMEFORMAT = "user/SET-TIMEFORMAT";

export const ARCHIVE_PROJECT = "user/ARCHIVE_PROJECT";
export const UNSUSPEND_PROJECT = "user/UNSUSPEND_PROJECT";

export const userLogin = (payload: any) => ({ type: USER_LOGIN, payload });
export const userLogout = () => ({ type: USER_LOGOUT });

export const selectUser = (payload: string) => ({ type: USER_SELECT, payload });

export const createUser = (payload: { name: string; mail: string }) => ({
  type: USER_CREATE,
  payload
});
export const editUser = (payload: {
  id: string;
  name: string;
  mail: string;
}) => ({ type: USER_EDIT, payload });
export const deleteUser = (payload: { id: string }) => ({
  type: USER_DELETE,
  payload
});
export const setUserNotification = (
  changes: { id: string; flag: boolean }[],
  option: "SendNoticeOnNewCopy" | "DailyReports"
) => ({
  type: USER_SET_NOTIFICATION,
  payload: { changes, option }
});
export const setBlockNotification = (key: string, flag: boolean) => ({
  type: USER_SET_BLOCK_NOTIFICATION,
  payload: { key, flag }
});

export const setTimezone = (zone: number) => ({
  type: SET_TIMEZONE,
  payload: zone
});
export const setTimeformat = (format: number) => ({
  type: SET_TIMEFORMAT,
  payload: format
});
export const archiveProject = (projectId: string) => ({
    type: ARCHIVE_PROJECT,
    payload: projectId
});
export const unsuspendProject = (projectId: string) => ({
    type: UNSUSPEND_PROJECT,
    payload: projectId
});
