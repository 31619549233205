import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { storeState } from "core/reducers";
import { storyStart } from "core/sagas";

const shouldUseDevToolsExtensionMiddleware = () => {
  const isProd = process.env.NODE_ENV !== "production";
  const hasDevToolsExtensions = window && "devToolsExtension" in window;

  return isProd && hasDevToolsExtensions
    ? (window as any).devToolsExtension()
    : (I: any) => I;
};

const buildStore = () => {
  const reducer = combineReducers({
    ...storeState
  });

  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(...middleware),
      shouldUseDevToolsExtensionMiddleware()
    )
  );

  sagaMiddleware.run(storyStart);
  return store;
};

export default buildStore;
