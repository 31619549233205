import {combineReducers} from "redux";
import {Action, handleActions} from "redux-actions";

import state from "./state";
import {DateInterval} from "components/header-in-main/components/calendar-section/calendar/Calendar";
import {
  COUNTRIES_SET,
  INTERVAL_SET,
  SELECT_TITLES, WEBSITES_DOWNLOAD_FINISHED, WEBSITES_DOWNLOAD_STARTED
} from "core/state/meta/actions";

export type MetaState = typeof state;
type TimeState = typeof state.visibleInterval;
type TitlesState = typeof state.titlesSelected;
type CountriesState = typeof state.countries;
export type CountryState = typeof state.countries[0];

const visibleInterval = handleActions(
  {
    [INTERVAL_SET]: (state: TimeState, action: Action<DateInterval>) =>
      action.payload
  },
  state.visibleInterval
);

type selectTitlesPayload = {
  projectId: string;
  titles: { [key: string]: boolean };
};

const titlesSelected = handleActions(
  {
    [SELECT_TITLES]: (
      state: TitlesState,
      action: Action<selectTitlesPayload>
    ): Partial<TitlesState> => ({
      ...state,
      [action.payload!.projectId]: action.payload!.titles
    })
  },
  state.titlesSelected
);

const countries = handleActions(
  {
    [COUNTRIES_SET]: (
      state: CountriesState,
      action: Action<{ data: CountriesState }>
    ): Partial<CountriesState> => action.payload!.data || state
  },
  state.countries
);

const downloadStatus = handleActions(
  {
    [WEBSITES_DOWNLOAD_STARTED]: () => Date.now(),
    [WEBSITES_DOWNLOAD_FINISHED]: () => 0,
  },
  0
);

const meta = combineReducers({
  visibleInterval,
  titlesSelected,
  countries,
  downloadStatus
});

export {meta};
