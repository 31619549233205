import * as React from "react";
import * as cx from "classnames";
import { sBEM } from "common/type-helpers";

import "./Empty.scss";

export const BaseEmpty: sBEM<{
  caption: string;
  graph?: boolean;
}> = ({ className, caption, graph }) => (
  <div
    className={cx(className, "empty", {
      "empty--graph": graph
    })}
  >
    <span children={caption} />
  </div>
);

export const CardEmpty: sBEM = props => (
  <BaseEmpty {...props} caption="Your future project" />
);

export const GraphEmpty: sBEM = props => (
  <BaseEmpty {...props} caption="Under construction" graph />
);
