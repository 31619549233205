import { Action, handleActions } from "redux-actions";

import state from "./state";
import { RESET_STORE } from "core/state/actions";
import { PROJECTS_STATE } from "core/state/projects/actions";

export type ProjectState = typeof state;
export type ProjectStateData = typeof state.data;
export type ProjectStateDataLine = typeof state.data[0];

const projects = handleActions(
  {
    [PROJECTS_STATE]: (
      state: ProjectState,
      action: Action<ProjectState>
    ): Partial<ProjectState> => ({
      ...action.payload
    }),



    [RESET_STORE]: (): Partial<ProjectState> => ({
      data: [],
      completed: []
    })
  },
  state
);

export { projects };
