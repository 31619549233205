import * as React from "react";

import Header from "components/header";
import { HeaderInMainPremier } from "components/header-in-main";
import { DashboardPremier } from "components/dashboard";

import "./Premier.scss";
import {
  loadWebsiteLinks,
  loadWebsites,
  loadWebsiteTypes
} from "core/state/websites/actions";
import { fetchCountries } from "core/state/meta/actions";
import { loadTitles } from "core/state/titles/actions";
import { withPackageDataLoader } from "components/dataHOC/mountLoaders";
import { getProjectById } from "core/state/projects/selectors";
import { StoreShape } from "core/reducers";
import { connect } from "react-redux";
import { MONTHS_NAMES_SHORT } from "common/listMonths";
import {toDate, toEndDate} from "components/card/Card";

const Page: React.SFC<{
  projectId: string;
  projectName: string;
  detected: string;
  detectedOn: string;
  start: string;
  end: string;
}> = ({ projectId, projectName, detected, detectedOn, start, end }) => (
  <div className="page">
    <Header className="page__header" />
    <main className="page__main">
      <HeaderInMainPremier
        className="page__navigation"
        titlePage={projectName}
        timing={detected}
        info="TS"
        href={detectedOn}
        projectId={projectId}

        date={`${toDate(start)} - ${toEndDate(end)}`}
      />
      <DashboardPremier className="page__dashboard" projectId={projectId} />
    </main>
  </div>
);

const formatDate = (indate: string) => {
  const date = new Date(indate);
  return `${date.getDate()} ${
    MONTHS_NAMES_SHORT[date.getMonth()]
  }, ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
};

const mapStateToProp = (
  state: StoreShape,
  { projectId }: { projectId: string }
) => ({
  start: getProjectById(state, projectId).start || "",
  end: getProjectById(state, projectId).end || "",
  projectName: getProjectById(state, projectId).title || "",
  detected: formatDate(getProjectById(state, projectId).detected || ""),
  detectedOn: getProjectById(state, projectId).detectedOn || ""
});

export default withPackageDataLoader({
  page: [fetchCountries],
  hook: [loadTitles, loadWebsites, loadWebsiteTypes, loadWebsiteLinks]
})(connect(mapStateToProp, null)(Page));
