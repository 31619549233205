import { DateInterval } from "components/header-in-main/components/calendar-section/calendar/Calendar";

export default {
  visibleInterval: { start: undefined, end: undefined } as DateInterval,
  titlesSelected: {
    0: {}
  },
  websiteSelection: 0,
  countrySelection: 0,
  countries: [
    { id: 1, name: "Russia", iso: "RU" },
    { id: 2, name: "United States" , iso: "US" },
    { id: 3, name: "India", iso: "IN" },
    {
      id: 4,
      name: "United Kingdom", iso: "GB"
    },
    { id: 5, name: "Ukraine", iso: "UA" }
  ],
  downloadStatus: 0
};
