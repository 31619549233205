import * as React from "react";
import * as cx from "classnames";

import { DocumentDownload } from "components/header-in-main/components/download";
import { Notifications } from "components/header/components/notifications";
import { MapTopWebsites } from "components/dashboard/components/top-websites";
import { CalendarSection } from "components/header-in-main/components/calendar-section";

import "./Tooltip.scss";
import { sBEM } from "common/type-helpers";
import { WebsiteMapStat } from "components/dashboard/components/top-websites/TopWebsites";

interface Props {
  mod?: string;
  content?: React.ReactNode;
  visible?: boolean;
  style?: React.CSSProperties
}

export const BaseTooltip: sBEM<Props> = ({
  className,
  mod,
  visible,
  content,
  style
}) => (
  <div
    style={style}
    className={cx(
      className,
      { [`${className}--${mod}`]: !!mod && !!className },
      { [`${className}--visible`]: !!visible && !!className },
      "tooltip",
      { [`tooltip--${mod}`]: !!mod }
    )}
  >
    {content}
  </div>
);

export const MapTooltip: sBEM<{ data: WebsiteMapStat[] }> = props => (
  <BaseTooltip
    {...props}
    mod="map"
    content={<MapTopWebsites className="tooltip__map" data={props.data} />}
  />
);

export const NoticeTooltip: sBEM<Props> = props => (
  <BaseTooltip
    {...props}
    mod="notice"
    content={<Notifications className="tooltip__notice" />}
  />
);

export const DownloadTooltip: sBEM<Props> = props => (
  <BaseTooltip
    {...props}
    mod="download"
    content={<DocumentDownload className="tooltip__download" />}
  />
);

export const CalendarTooltip: sBEM<Props & { toggle: () => void, projectId: string }> = ({
  toggle,
  projectId,
  ...props
}) => (
  <BaseTooltip
    {...props}
    mod="calendar"
    content={<CalendarSection className="tooltip__calendar" toggle={toggle} projectId={projectId}/>}
  />
);
