import * as React from "react";
import * as cx from "classnames";
import { Minus, sBEM } from "common/type-helpers";

import "./Checkbox.scss";
import { reactType } from "common/reactType";

interface Props {
  mod?: string;
  id?: string;
  name?: string;
  caption: string;
  checked?: boolean;
  Component?: React.ComponentClass;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BaseCheckbox: sBEM<Props> = ({
  className,
  mod,
  id,
  name,
  caption,
  checked,
  onChange,
  Component = reactType(<p />)
}) => (
  <Component className={cx(className, "checkbox")}>
    <input
      id={id}
      className="checkbox__input"
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <label
      htmlFor={id}
      className={cx("checkbox__caption", {
        [`checkbox__caption--${mod}`]: !!mod
      })}
      children={caption}
    />
  </Component>
);

const ListCheckbox: sBEM<Props> = props => (
  <BaseCheckbox {...props} Component={reactType(<li />)} />
);

type WithoutMod = Minus<Props, { mod?: string }>;

export const GreyCheckbox: sBEM<WithoutMod> = props => (
  <BaseCheckbox {...props} mod="grey" />
);
export const BlueListCheckbox: sBEM<WithoutMod> = props => (
  <ListCheckbox {...props} mod="blue-list" />
);
export const BlueCheckbox: sBEM<WithoutMod> = props => (
  <BaseCheckbox {...props} mod="blue" />
);
