import * as React from "react";
import * as cx from "classnames";

import "./AccountList.scss";
import {sBEM} from "common/type-helpers";
import {BigBlueButton, BigLightButton} from "components/UIKit/button";
import {Value} from "react-powerplug";

interface Account {
    id: string;
    name: string;
    date?: string;
    archived?: boolean;
}

export interface IAccountsList {
    letter: string;
    accounts: Account[];
}

export type AccountListProps = sBEM<{
    list: IAccountsList[];
    onSelect: (uid: string) => any;
    onClickArchive: (uid: string) => any;
    activeTab: number;
}>;

const postFilter = (list: IAccountsList[], name: string) =>
    list
        .map(account => ({
            ...account,
            accounts: account.accounts.filter(
                acc => acc.name.toLowerCase().indexOf(name.toLocaleLowerCase()) >= 0
            )
        }))
        .filter(account => account.accounts.length > 0);

const BaseAccountList: AccountListProps = ({className, list, onSelect, onClickArchive, activeTab}) => (
    <Value initial="">
        {({value, setValue}) => (
            <div className={cx(className, "account-list")}>
                <div className="account-list__search-wrapper">
                    <input
                        className="account-list__search"
                        type="search"
                        placeholder="Type Company name or first letter"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                    />
                </div>
                <ul className="account-list__alphabet-list">
                    {postFilter(list, value).map(list => (
                        <li className="account-list__alphabet-item" key={list.letter}>
                            <span className="account-list__letter" children={list.letter}/>
                            <ul className="account-list__list">
                                {list.accounts
                                  .map(account => (
                                    <li className="account-list__item" key={account.id}>
                                        <span className="account-list__dot"/>
                                        <span
                                            className="account-list__name"
                                            children={account.name}
                                        />
                                        {/*<span className="account-list__change">*/}
                                        {/*Last change:*/}
                                        {/*<span children={account.date || "now"} />*/}
                                        {/*</span>*/}
                                        <BigLightButton
                                            className={"account-list__archive"}
                                            caption={activeTab === 0 ? "Archive" : "Restore"}
                                            onClick={() => onClickArchive(account.id)}
                                        />
                                        <BigBlueButton
                                            className="account-list__link"
                                            caption="View"
                                            onClick={() => onSelect(account.id)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </Value>
);

export const AccountList: AccountListProps = props => (
    <BaseAccountList {...props} />
);
