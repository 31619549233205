import * as React from "react";
import * as cx from "classnames";

import { Toggle } from "react-powerplug";

import { BackSvgLink } from "components/UIKit/svglink";
import { MediumLightButton } from "components/UIKit/button";
import { CalendarTooltip } from "components/tooltip";

import "./HeaderInMain.scss";
import { sBEM, Minus } from "common/type-helpers";
import { uid } from "core/uid";
import { Link } from "react-router-dom";
import { CalendarActionButton } from "components/header-in-main/components/calendar-action-button";
import { URLConsumer } from "components/URLConsumer/URLConsumer";
import {DownloadButton} from "components/header-in-main/components/downloadButton";

interface ListTabs {
  mod: string;
  items: {
    mod?: string;
    onClick?: () => any;
    content: string;
  }[];
}

interface Props {
  mod?: string;
  titleMod?: string;
  infoMod?: "visible";
  selectedHidden?: boolean;
  reportHidden?: boolean;
  calendarHidden?: boolean;
  backHidden?: boolean;
  selectedType?: "websites" | "titles";
  calendarVisible?: boolean;
  titlePage: string;
  timing?: string;
  info?: string;
  href?: string;
  date?: React.ReactNode

  listTabs?: ListTabs;
  captionSelected?: string;
  onCaptionSelectedClick?: () => void;

  projectId: string
}

export const BaseHeaderInMain: sBEM<Props> = ({
  className,
  mod,
  titleMod,
  infoMod,
  listTabs = { mod: "invisible", items: [] },
  calendarHidden,
  titlePage,
  captionSelected = "",
  onCaptionSelectedClick,
  timing,
  href,
  info,
  date,
  selectedHidden,
  backHidden,
  reportHidden,
  projectId
}) => (
  <header
    className={cx(
      className,
      { [`${className}--${mod}`]: !!mod && !!className },
      "site-navigation",
      {
        [`site-navigation--${mod}`]: !!mod
      }
    )}
  >
    <div className="site-navigation__left">
      { date && <div className="site-navigation__date">{date}</div>}
      <h2
        className={cx("site-navigation__title", {
          [`site-navigation__title--${titleMod}`]: !!titleMod
        })}
        children={titlePage}
      />
      <URLConsumer>
        {({ clientId, projectId }) => {
          let link = `/${clientId}/projects`;
          if (projectId && projectId.endsWith('compl')) link += '/completed';
          
          return <Link to={link} className="site-navigation__back">
            <BackSvgLink
              className="site-navigation__back-button"
              hidden={backHidden}
            />
          </Link>
        }}
      </URLConsumer>
      {/*<div*/}
        {/*className={cx("site-navigation__info-wrapper", {*/}
          {/*[`site-navigation__info-wrapper--${infoMod}`]: !!infoMod*/}
        {/*})}*/}
      {/*>*/}
        {/*<p className="site-navigation__date-detected" children={timing} />*/}
        {/*<p className="site-navigation__info" children={info} />*/}
        {/*<a*/}
          {/*className="site-navigation__link-project"*/}
          {/*href={href}*/}
          {/*children={href}*/}
        {/*/>*/}
      {/*</div>*/}
      <MediumLightButton
        className="site-navigation__selected"
        caption={captionSelected}
        hidden={selectedHidden}
        onClick={onCaptionSelectedClick}
      />
      <ul
        className={cx(
          "site-navigation__tab-wrapper",
          `site-navigation__tab-wrapper--${listTabs.mod}`
        )}
      >
        {listTabs.items.map(item => (
          <li
            tabIndex={0}
            key={uid(item)}
            className={cx("site-navigation__tab", {
              [`site-navigation__tab--${item.mod}`]: !!item.mod
            })}
            onClick={item.onClick}
            children={item.content}
          />
        ))}
      </ul>
    </div>
    <div className="site-navigation__right">
      {!calendarHidden && (
        <div className="site-navigation__calendar-wrapper">
          <Toggle>
            {({ on, toggle }) => (
              <React.Fragment>
                <CalendarActionButton
                  className="site-navigation__calendar"
                  projectId={projectId}
                  onClick={toggle}
                />
                <CalendarTooltip
                  className={cx("site-navigation__tooltip")}
                  visible={on}
                  toggle={toggle}
                  projectId={projectId}
                />
              </React.Fragment>
            )}
          </Toggle>
        </div>
      )}
      {!reportHidden && <DownloadButton/>}
    </div>
  </header>
);

interface ExtraProps {
  listTabs: any;
  selectedHidden: any;
  calendarHidden: any;
  reportHidden: any;
  backHidden: any;
  titleMod: any;
}

export const HeaderInMainProjects: sBEM<
  Minus<Props, ExtraProps> & { activeTab: number; onClick: (n: number) => any }
> = ({ activeTab, onClick, ...props }) => (
  <BaseHeaderInMain
    {...props}
    listTabs={{
      mod: "visible",
      items: [
        {
          mod: activeTab === 0 ? "active" : undefined,
          onClick: () => onClick(0),
          content: "in progress"
        },
        {
          mod: activeTab === 1 ? "active" : undefined,
          onClick: () => onClick(1),
          content: "completed"
        }
      ]
    }}
    selectedHidden
    calendarHidden
    reportHidden
    backHidden
    titleMod="projects"
  />
);

export const HeaderInMainPremier: sBEM<Props> = props => (
  <BaseHeaderInMain {...props} infoMod="visible" selectedHidden />
);

export const HeaderInMainPackage: sBEM<Props> = props => (
  <BaseHeaderInMain {...props} />
);

export const HeaderInMainScanningResult: sBEM<Props> = props => (
  <BaseHeaderInMain {...props} calendarHidden />
);

export const HeaderInMainScanningOnly: sBEM<Props> = props => (
  <BaseHeaderInMain {...props} backHidden calendarHidden />
);

export const HeaderInMainGeoAnalysis: sBEM<Props> = props => (
  <BaseHeaderInMain {...props} calendarHidden />
);

export const HeaderInMainAccount: sBEM<Props> = props => (
  <BaseHeaderInMain
    {...props}
    calendarHidden
    selectedHidden
    reportHidden
    titleMod={"account"}
  />
);
