var React = require('react');

function Arrow (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"evenodd","transform":"translate(-489.000000, -23.000000)"},React.createElement("polygon",{"fillRule":"nonzero","transform":"translate(492.000000, 28.000000) scale(-1, 1) translate(-492.000000, -28.000000) ","points":"495 31.536842 493.710696 33 489 28 493.710696 23 495 24.463158 491.667802 28"})));
}

Arrow.displayName = "Arrow";

Arrow.defaultProps = {"width":"6px","height":"10px","viewBox":"0 0 6 10","version":"1.1"};

module.exports = Arrow;

Arrow.default = Arrow;
