import * as React from "react";
import {History} from "history";
import { URLConsumer } from "components/URLConsumer/URLConsumer";

import Header from "components/header";
import { HeaderInMainProjects } from "components/header-in-main";
import ActiveProjectsList from "./ActiveProjectsList";
import CompletedProjectsList from "./CompletedProjectsList";

import "./Projects.scss";
const COMPLETED_PROJECTS = 1;
const ACTIVE_PROJECTS = 0;

const setActiveTabWrapper = (history: History | null, {clientId}: {clientId: string}): (data: number) => void =>  
(data: number) => {
  if (history == null) return;
  data == ACTIVE_PROJECTS ? history.push(`/${clientId}/projects`) : history.push(`/${clientId}/projects/completed`);
};

const isActive = ({completed}: {completed: string}): boolean => {
  return !completed || completed == 'active';
};

const Page = ({history} : {history: History | null}) => (
  <URLConsumer>
    {
      (match) =>{
      return <div className="page">
        <Header className="page__header" mod="projects" />
        <main className="page__main">
          <HeaderInMainProjects
            className="page__navigation"
            titlePage="Projects"
            mod="projects"
            activeTab={isActive(match) ? ACTIVE_PROJECTS : COMPLETED_PROJECTS }
            onClick={setActiveTabWrapper(history, match)}
            projectId=""
          />
          {isActive(match) && <ActiveProjectsList className="page__projects" />}
          {!isActive(match) && (
            <CompletedProjectsList className="page__projects" />
          )}
        </main>
      </div>
      }
    }
  </URLConsumer>
);

export default Page;
