import {
  PersonMail,
  SiteImpersonate,
  UserState
} from "core/state/user/reducer";

export const getCurrentUserDetails = (user: UserState) =>
  (user.details && user.details[user.selectedUser]) || {};

export const getMasterUser = (user: UserState) =>
  (user.details && user.details[user.masterUser]) || {};

export const isAuthenticated = ({ user }: { user: UserState }): boolean =>
  user.authenticated;
export const userLoginDidFail = ({ user }: { user: UserState }): boolean =>
  user.authenticationFailed;
export const getUserName = ({ user }: { user: UserState }): string =>
  getCurrentUserDetails(user).name || "undefined user";
export const getCurrentClientId = ({ user }: { user: UserState }): string =>
  user.selectedUser;
export const getEmails = ({ user }: { user: UserState }): PersonMail[] =>
  getCurrentUserDetails(user).emails || [];
export const getCopyEmails = ({ user }: { user: UserState }): PersonMail[] =>
  getEmails({ user }).filter(({ sentNotices }) => sentNotices);
export const getDailyEmails = ({ user }: { user: UserState }): PersonMail[] =>
  getEmails({ user }).filter(({ sentDailyReports }) => sentDailyReports);

export const getUserNotifications = ({ user }: { user: UserState }) => ({
  sentDailyReports: getCurrentUserDetails(user).dailyReports,
  sentNotices: getCurrentUserDetails(user).sendNoticeOnNewCopy
});

export const someoneImpersonated = ({ user }: { user: UserState }): boolean =>
  user.authenticated &&
  !!user.selectedUser &&
  getSitesCanImpersonate({ user }).some(site => site.id === user.selectedUser);

export const getCurrentClientName = (
  { user }: { user: UserState },
  clientId = getCurrentClientId({ user })
): string =>
  (
    getSitesCanImpersonate({ user }).find(site => site.id === clientId) || {
      name: ""
    }
  ).name;
export const isMasterAccount = ({ user }: { user: UserState }): boolean =>
  (getMasterUser(user).canImpersonate || []).length > 1;
export const getSitesCanImpersonate = ({
  user
}: {
  user: UserState;
}): SiteImpersonate[] => getMasterUser(user).canImpersonate || [];
