import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { PROJECTS_LOAD, PROJECTS_STATE } from "./actions";
import { SagaIterator } from "redux-saga";

import { backend } from "core/config";
import { fetch } from "common/fetch";
import { getCurrentClientId } from "core/state/user/selectors";

const fetchProjects = (uid: string) =>
  fetch(`${backend}/${uid}/projects`).then(data => data.json());

function* projectsLoad_saga() {
  try {
    yield put({ type: PROJECTS_STATE, payload: { loading: true } });
    const uid = yield select(getCurrentClientId);
    if (uid) {
      const data = yield call(fetchProjects, uid);
      yield put({ type: PROJECTS_STATE, payload: { data: data.inProgress, completed: data.completed } });
    }
  } catch (e) {
    yield put({ type: PROJECTS_STATE, payload: { error: true } });
  }
}

export function* projectsSaga(): SagaIterator {
  yield all([takeLatest(PROJECTS_LOAD, projectsLoad_saga)]);
}
