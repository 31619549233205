import {
  ProjectState,
  ProjectStateDataLine
} from "core/state/projects/reducer";

export const getProjects = ({ projects }: { projects: ProjectState }) =>
  projects.data || [];
export const getCompletedProjects = ({ projects }: { projects: ProjectState }) =>
  projects.completed || [];
export const getProjectById = (
  { projects }: { projects: ProjectState },
  projectId: string
): ProjectStateDataLine =>
  ((projects.data && projects.data.find(props => props.id! === projectId)) ||
  (projects.completed && projects.completed.find(props => props.id! === projectId)) ||
    {}) as any;
