import * as React from 'react';
import * as cx from 'classnames';
import Loader from 'svg-react-loader!./loader.svgx';

export class CircleIndicator extends React.Component<{className: string}, { tick: number }> {

  state = {
    tick: 0
  };

  private tm: number = 0;
  private timeIn: number = Date.now();

  componentDidMount() {
    this.tm = window.setInterval(this.tick, 100);
  }

  componentWillUnmount() {
    window.clearInterval(this.tm);
  }

  tick = () => this.setState(({tick}) => ({tick: tick + 1}))

  render() {
    const angle = 30 * Math.round(5 * (Date.now() - this.timeIn) / 1000);
    return (
      <div className={cx(this.props.className,"circle-progress-indicator")}>
        <Loader style={{transform: `rotate(${angle}deg)`}}/>
      </div>
    )
  }
}