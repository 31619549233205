import * as React from "react";
import * as cx from "classnames";

import {Spring} from "react-spring";
import {Hover} from 'react-powerplug'

import "./Graph.scss";
import {sBEM} from "common/type-helpers";
import {webConnect} from "core/redux";
import {StoreShape} from "core/reducers";
import {getWebsitesLinks} from "core/state/websites/selectors";
import {MONTHS_NAMES_SHORT} from "common/listMonths";
import {shortenNumber} from "common/shortenNumbers";
import {BaseTooltip} from "components/tooltip/Tooltip";

interface Item {
}

interface Colors {
  [key: string]: string;
}

interface Month {
  [key: string]: number | string;
}

type ListMonths = Month[];

const graphKeyToLabel = {
  found: 'detected'
};

const Legend: React.SFC<{ item: Item; colors: Colors }> = ({
                                                             item,
                                                             colors
                                                           }) => (
  <ul className="graph__legend-list">
    {Object.keys(item).map(key => (
      <li className="graph__legend" key={key}>
        <span
          className="graph__legend-color"
          style={{backgroundColor: colors[key]}}
        />
        <span className="graph__legend-index" children={shortenNumber(item[key])}/>
        <span className="graph__legend-caption" children={graphKeyToLabel[key] || key}/>
      </li>
    ))}
  </ul>
);

const Axis: React.SFC<{ step: number; steps: number; med: number }> = ({
                                                                         step,
                                                                         steps,
                                                                         med
                                                                       }) => (
  <ol className="graph__axis-base">
    {Array(steps + 1)
      .fill(1)
      .map((_, index) => (
        <li
          className="graph__axis-line"
          key={index}
          style={{bottom: `${100 * (index / steps)}%`}}
        >
          <span children={Math.round(step * index / med * med)}/>
        </li>
      ))}
  </ol>
);

const getMaximalValue = (listMonths: ListMonths) => {
  let max = 0;
  Object.keys(listMonths).forEach(month =>
    Object.keys(listMonths[month]).forEach(
      keyName => (max = Math.max(max, listMonths[month][keyName]))
    )
  );
  return max;
};

const getMinStep = (max: number): number => {
  let d = 10;
  while (1) {
    if (max <= d * 0.25) {
      return d * 0.25;
    }
    if (max <= d * 0.5) {
      return d * 0.5;
    }
    if (max <= d * 0.75) {
      return d * 0.75;
    }
    if (max <= d) {
      return d;
    }

    d *= 10;
  }
  return 0;
};

const filterCols = (x: any) =>
  Object.keys(x).reduce((acc: any, item) => {
    if (typeof x[item] === "number" && item !== "type") {
      acc[item] = x[item];
    }
    return acc;
  }, {});

const printDate = (
  moreThanYear: boolean,
  moreThanMonth: boolean,
  moreThanDay: boolean,
  startDate: Date,
  endDate: Date,
) => {
  if (moreThanYear) {
    return startDate.getFullYear();
  }
  if (moreThanMonth) {
    return MONTHS_NAMES_SHORT[startDate.getMonth()];
  }
  if (moreThanDay) {
    if (startDate.getMonth() === endDate.getMonth()) {
      return MONTHS_NAMES_SHORT[startDate.getMonth()] + " " + startDate.getDate() + '-' + endDate.getDate();
    }
    return (
      MONTHS_NAMES_SHORT[startDate.getMonth()] + " " + startDate.getDate() +
      " - " +
      MONTHS_NAMES_SHORT[endDate.getMonth()] + " " + endDate.getDate()
    );
  }
  return startDate.getDate();
};

export const BaseGraph: sBEM<{
  colors: Colors;
  listMonths: ListMonths;
}> = ({className, colors, listMonths}) => {
  let lm = listMonths
    .map(lm => filterCols(lm))
    .slice(Math.max(0, listMonths.length - 12), 12);

  const valueMax = getMaximalValue(lm);
  const step = getMinStep(valueMax);
  const max = step;
  const stepMed = max / 5;

  if (!listMonths || !listMonths.length || !lm[0]) {
    return null;
  }

  const moreThanYear = listMonths[0].type === 3;
  const moreThanMonth = listMonths[0].type === 2;
  const moreThanDay = listMonths[0].type === 1;

  const valueAcc = {...lm[0]};
  lm.forEach((item, index) =>
    index > 0 && Object.keys(item).forEach(key => valueAcc[key] += item[key])
  );

  return (
    <div className={cx(className, "graph")}>
      <Legend item={valueAcc} colors={colors}/>
      <div className="graph__graph-wrapper">
        <Axis step={stepMed} steps={5} med={step / 10}/>
        <ol className="graph__month-list">
          {Object.keys(lm).map(lindex => (
            <li className="graph__month" key={lindex}>
              <div className="graph__data-wrapper">
                {printDate(
                  moreThanYear,
                  moreThanMonth,
                  moreThanDay,
                  new Date(listMonths[lindex].intervalStartDate),
                  new Date(listMonths[lindex].intervalEndDate),
                )}
                <ul className="graph__index-list">
                  {Object.keys(lm[lindex]).map(keyName => (
                    <Spring
                      key={keyName}
                      from={{height: 0}}
                      to={{height: 100 * (lm[lindex][keyName] / max)}}
                    >
                      {({height}) => (
                        <Hover>
                          {({isHover, bindHover}) => (
                            <li
                              key={keyName}
                              className="graph__index"
                              style={{
                                height: `${height}%`,
                                backgroundColor: colors[keyName]
                              }}
                              {...bindHover}
                            >
                              <BaseTooltip mod="graph" className="graph__tooltip" visible={isHover} content={
                                <dl style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <dt>{graphKeyToLabel[keyName] || keyName}</dt>
                                  <dd>{lm[lindex][keyName]}</dd>
                                </dl>
                              }/>
                            </li>
                          )}
                        </Hover>
                      )}
                    </Spring>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

const UnconnectedPremierGraph: sBEM<{ listMonths: ListMonths }> = props => (
  <BaseGraph
    {...props}
    colors={{
      found: "#5CC46D",
      removed: "#C8E5CD",
      google: "#E3CE4C"
    }}
  />
);

export const PremierGraph: sBEM<{ projectId: string }> = webConnect(
  (state: StoreShape, {projectId}: { projectId: string }) => ({
    listMonths: getWebsitesLinks(state, projectId)
  })
)(UnconnectedPremierGraph) as any;

export const UnconnectedPackageGraph: sBEM<{
  listMonths: ListMonths;
}> = props => (
  <BaseGraph
    {...props}
    colors={{
      found: "#5CC46D",
      removed: "#C8E5CD"
    }}
  />
);

export const PackageGraph: sBEM<{ projectId: string }> = webConnect(
  (state: StoreShape, {projectId}: { projectId: string }) => ({
    listMonths: getWebsitesLinks(state, projectId)
  })
)(UnconnectedPackageGraph) as any;
