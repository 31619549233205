import { meta, MetaState } from "core/state/meta/reducer";
import { packages, PackagesState } from "core/state/packages/reducer";
import { stat, StatState } from "core/state/stat/reducer";
import { user, UserState } from "core/state/user/reducer";
import { modal, ModalState } from "core/state/modal/reducer";
import {
  notifications,
  NotificationState
} from "core/state/notifications/reducer";
import { projects, ProjectState } from "core/state/projects/reducer";
import { websites, WebsitesState } from "core/state/websites/reducer";
import { titles, TitlesState } from "core/state/titles/reducer";

export type StoreShape = {
  meta: MetaState;
  packages: PackagesState;
  stat: StatState;
  user: UserState;
  modal: ModalState;
  notifications: NotificationState;
  projects: ProjectState;
  websites: WebsitesState;
  titles: TitlesState;
};

type ReducerShape<T extends { [key: string]: any }> = { [K in keyof T]: any };

export const storeState: ReducerShape<StoreShape> = {
  meta,
  packages,
  stat,
  user,
  modal,
  notifications,
  projects,
  websites,
  titles
};
