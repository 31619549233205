import * as React from "react";
import * as cx from "classnames";

import {
  PremierGraph,
  PackageGraph
} from "components/dashboard/components/graph/index";

import "./GraphSection.scss";
import { sBEM } from "common/type-helpers";
import {GraphEmpty} from "components/empty";

export const BaseGraphSection: sBEM<{
  graph: React.ReactNode;
  emptySection?: boolean;
}> = ({ className, graph, emptySection }) => (
  <div className={cx(className, "graph-section")}>
    <h3
      className={cx("graph-section__title", {
        "graph-section__title--empty": emptySection
      })}
      children="Link statistics"
    />
    {graph}
  </div>
);

export const PremierGraphSection: sBEM<{ projectId: string }> = props => (
  <BaseGraphSection
    {...props}
    graph={
      <PremierGraph
        className="graph-section__graph"
        projectId={props.projectId}
      />
    }
  />
);

export const PackageGraphSection: sBEM<{ projectId: string }> = props => (
  <BaseGraphSection
    {...props}
    graph={
      <PackageGraph
        className="graph-section__graph"
        projectId={props.projectId}
      />
    }
  />
);

export const ScanningGraphSection: sBEM<{ projectId: string }> = props => (
  <BaseGraphSection
    {...props}
    graph={<GraphEmpty className="graph-section__graph" />}
    emptySection
  />
);
