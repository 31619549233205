import * as React from "react";

// CAREFULL!!

import { connect as reduxConnect } from "beautiful-react-redux";
//import { connect as reduxConnect } from "react-redux";
import { StoreShape as internalStoreShape } from "./reducers";
import { Dispatch } from "redux";
import { Diff } from "utility-types";

function webConnect<
  MappedProps extends Object,
  DispatchedProps extends Object,
  OwnProps extends Object
>(
  mapStateToProps: ((
    store: internalStoreShape,
    ownProps?: OwnProps
  ) => MappedProps | null) | null,
  mapDispatchToProps?:
    | ((dispatch: Dispatch, ownProps?: OwnProps) => DispatchedProps)
    | DispatchedProps
    | null
): <Props extends MappedProps & DispatchedProps>(
  WrappedComponent: React.ComponentType<Props>
) => React.ComponentType<
  Diff<Props, MappedProps & DispatchedProps> & OwnProps
> {
  return WrappedComponent =>
    reduxConnect(mapStateToProps, mapDispatchToProps as any)(
      WrappedComponent as any
    ) as any;
}

export {
  webConnect
  //StoreShape
};
