import { handleActions } from "redux-actions";

import state from "./state";
import { CLOSE_MODAL, OPEN_MODAL } from "./actions";

export type ModalState = typeof state;

const modal = handleActions(
  {
    [OPEN_MODAL]: (state: ModalState, action) => ({
      stack: [action.payload, ...state.stack]
    }),
    [CLOSE_MODAL]: (state: ModalState, action) => ({
      stack: state.stack.filter(modal => modal !== action.payload)
    })
  },
  state
);

export { modal };
