export const INTERVAL_SET = "meta/interval/SET";
export const SELECT_TITLES = "meta/titles/SET";

export const COUNTRIES_GET = "meta/countries/GET";
export const COUNTRIES_SET = "meta/countries/SET";

export const REGISTER_HOOK = "meta/hook/register";
export const UNREGISTER_HOOK = "meta/hook/register";

export const WEBSITES_DOWNLOAD_STARTED = "meta/websites/DOWNLOAD-START";
export const WEBSITES_DOWNLOAD_FINISHED = "meta/websites/DOWNLOAD-END";


export const selectTitles = (
  projectId: string,
  titles: { [key: string]: boolean }
) => ({
  type: SELECT_TITLES,
  payload: { projectId, titles }
});

export const registerUIHook = (fn: () => any) => ({
  type: REGISTER_HOOK,
  payload: fn
});
export const unregisterUIHook = (fn: () => any) => ({
  type: UNREGISTER_HOOK,
  payload: fn
});

export const fetchCountries = () => ({ type: COUNTRIES_GET });
