import * as React from "react";

const context = React.createContext({
  projectId: "0",
  clientId: "0",
  completed: ""
});

export const URLConsumer = context.Consumer;
export const URLProvider = context.Provider;
