import * as React from "react";
import * as cx from "classnames";

import "./EditTeamMember.scss";
import { sBEM } from "common/type-helpers";
import { Field } from "components/UIKit/field";
import { BigBlueButton } from "components/UIKit/button";
import { webConnect } from "core/redux";
import { getEmails } from "core/state/user/selectors";
import { FormEvent } from "react";
import { editUser } from "core/state/user/actions";

const EditTeamMemberForm: sBEM<{
  name: string;
  email: string;
  saveChanges: (e: any) => any;
  onClose: () => void;
}> = ({ className, name, email, saveChanges, onClose }) => (
  <form
    className={cx(className, "edit-member")}
    onSubmit={e => {
      e.preventDefault();
      saveChanges(e);
      onClose();
    }}
  >
    <Field
      className="edit-member__field"
      type="text"
      placeholder="Name"
      name="Name"
      defaultValue={name}
    />
    <Field
      className="edit-member__field"
      type="email"
      placeholder="E-mail"
      name="Email"
      defaultValue={email}
    />
    <BigBlueButton className="edit-member__submit" submit caption="Update" />
  </form>
);

export default webConnect(
  (state, { userId }: { userId: string }) => {
    const user = getEmails(state).find(({ id }) => id === userId);
    return user
      ? {
          name: user.name,
          email: user.mail
        }
      : {
          name: "",
          email: ""
        };
  },
  (dispatch, { userId }: { userId: string }) => ({
    saveChanges: (e: FormEvent<HTMLFormElement>) => {
      const formData = Array.from(e.target as any).reduce(
        (acc: any, input: HTMLInputElement) => {
          acc[input.name] = input.value;
          return acc;
        },
        {}
      );

      return dispatch(
        editUser({
          id: userId,
          name: formData.Name,
          mail: formData.Email
        })
      );
    }
  })
)(EditTeamMemberForm);
