import * as React from "react";
import { Header, MasterHeader } from "./Header";
import { PreHeader } from "components/pre-header/PreHeader";
import { webConnect } from "core/redux";
import {
  isMasterAccount,
  getCurrentClientName,
  getCurrentClientId
} from "core/state/user/selectors";
import { selectUser } from "core/state/user/actions";

const UnconnectedHeader: React.SFC<{
  isMaster: boolean;
  isControlled: string;
  className: string;
  mod?: string;
  onAccountChange: () => any;
}> = ({ isMaster, isControlled, className, mod, onAccountChange }) =>
  isMaster ? (
    <React.Fragment>
      {isControlled && (
        <PreHeader
          className="page__pre-header"
          accountName={isControlled}
          onAccountChange={onAccountChange}
        />
      )}
      {isControlled ? (
        <Header className={className} mod={mod} />
      ) : (
        <MasterHeader className={className} mod={mod} />
      )}
    </React.Fragment>
  ) : (
    <Header className={className} mod={mod} />
  );

export { MasterHeader, Header };

export default webConnect(
  state => ({
    isMaster: isMasterAccount(state),
    isControlled: getCurrentClientId(state) && getCurrentClientName(state)
  }),
  dispatch => ({
    onAccountChange: () => dispatch(selectUser(""))
  })
)(UnconnectedHeader);
