import * as React from "react";
import { Switch, Route, Redirect } from "react-router";
import {
  someoneImpersonated,
  isAuthenticated,
  isMasterAccount
} from "core/state/user/selectors";

import { URLProvider } from "components/URLConsumer/URLConsumer";

import LoginForm from "pages/login";
import GeoAnalysis from "pages/geo-analysis";
import Package from "pages/package";
import Premier from "pages/premier";
import Projects, { RedirectToCurrentClientProjects } from "pages/projects";
import ScanningOnly from "pages/scanning-only";
import ScanningResult from "pages/scanning-result";
import { initializeEvent } from "core/state/actions";
import Account from "pages/account";
import MasterAccount from "pages/master-account";
import { connect } from "react-redux";
import { StoreShape } from "core/reducers";
import { selectUser } from "core/state/user/actions";

const withURLProvided = <T extends {}>(
  Component: React.ComponentType<T>
): React.ComponentType<T & { match?: any }> => ({
  match,
  ...rest
}: {
  match?: any;
}) => (
  <URLProvider value={(match || {}).params}>
    <Component {...rest} />
  </URLProvider>
);

const DecoratedLoginForm = withURLProvided(LoginForm);
const DecoratedGeoAnalysis = withURLProvided(GeoAnalysis);
const DecoratedPackage = withURLProvided(Package);
const DecoratedPremier = withURLProvided(Premier);
const DecoratedProjects = withURLProvided(Projects);
const DecoratedScanningOnly = withURLProvided(ScanningOnly);
const DecoratedScanningResult = withURLProvided(ScanningResult);
const DecoratedAccount = withURLProvided(Account);

const Routes = () => (
  <Switch>
    <Redirect exact={true} from="/" to="/projects" />
    <Route path="/:clientId/account" component={DecoratedAccount} />

    <Route
      path="/:clientId/geo-analysis/:projectId"
      component={DecoratedGeoAnalysis}
    />
    <Route path="/:clientId/package/:projectId" component={DecoratedPackage} />
    <Route path="/:clientId/premiere/:projectId" component={DecoratedPremier} />
   
    <Route path="/projects" component={RedirectToCurrentClientProjects} />

    <Route path="/:clientId/projects/:completed" component={DecoratedProjects} />
    <Route path="/:clientId/projects" component={DecoratedProjects} />
    
    <Route
      path="/:clientId/scanning/base/:projectId"
      component={DecoratedScanningOnly}
    />
    <Route
      path="/:clientId/scanning/result/:projectId"
      component={DecoratedScanningResult}
    />

    {/*default*/}
    <Route component={Projects} />
  </Switch>
);

class UnconnectedUpdateSelectedUser extends React.Component<{
  match?: any;
  select: (n: string) => any;
}> {
  componentDidMount() {
    this.props.select(this.props.match.params.clientId);
  }

  render() {
    return <MasterAccount />;
  }
}

const UpdateSelectedUser = connect(null, dispatch => ({
  select: (uid: string) => dispatch(selectUser(uid))
}))(UnconnectedUpdateSelectedUser);

const MasterRoutes = () => (
  <Switch>
    <Route path="/login" component={MasterAccount} />
    <Route path="/projects" component={MasterAccount} />

    <Route path="/:clientId/" component={UpdateSelectedUser} />

    <Route component={MasterAccount} />
  </Switch>
);

class AuthBlocker extends React.Component<{
  isAuthenticated: boolean;
  shouldImpersonate: boolean;
  canImpersonate: boolean;
  initializeEvent: () => any;
}> {
  componentDidMount() {
    this.props.initializeEvent();
  }

  render() {
    if (!this.props.isAuthenticated) {
      return <DecoratedLoginForm title="Enter?" />;
    }
    if (this.props.shouldImpersonate && this.props.canImpersonate) {
      return <MasterRoutes />;
    }
    return <Routes />;
  }
}

export default connect(
  (state: StoreShape) => ({
    isAuthenticated: isAuthenticated(state),
    shouldImpersonate: !someoneImpersonated(state),
    canImpersonate: isMasterAccount(state)
  }),
  { initializeEvent },
  null,
  {
    pure: false
  }
)(AuthBlocker);
