import * as React from "react";
import * as cx from "classnames";
import {Value} from "react-powerplug";

import {MediumBlueButton} from "components/UIKit/button";
import {
  LinksListOfCountries,
  PeersListOfCountries
} from "./components/list-of-countries";
import {
  WebsitesTableData,
  TitlesTableData,
  TerritoriesTableData,
  TerritoriesPeersTableData
} from "./components/table-data";
import {
  PremierGraphSection,
  PackageGraphSection,
  ScanningGraphSection
} from "./components/graph-section";
import {Map} from "./components/map/Map";

import "./Dashboard.scss";
import {WebsitesChart} from "./components/chart";
import {RenderFn, sBEM} from "common/type-helpers";
import {webConnect} from "core/redux";
import {getSelectedInterval} from "core/state/meta/selectors";
import {
  getFirstLinkDate,
  getLastLinkDate,
  getWebsitesLinks,
  isWebsitesLinksLoading
} from "core/state/websites/selectors";

interface ICountryHover {
  code: string;
  level: number;
  hovered: boolean;
}

const Hover: React.SFC<{
  initial: ICountryHover;
  children: RenderFn<{
    hovered: ICountryHover | null;
    hover: (a: ICountryHover | null) => void;
  }>;
}> = ({initial, children}) => (
  <Value initial={initial}>
    {({value: hovered, setValue: hover}) => children({hovered, hover})}
  </Value>
);

interface Slots {
  sectionGraph?: React.ReactNode;
  sectionChart?: React.ReactNode;
}

interface Settings {
  hiddenLinksList?: boolean;
  hiddenPeersList?: boolean;
  hiddenWebsitesTable?: boolean;
  hiddenTitlesTable?: boolean;
  hiddenTerritoriesTable?: boolean;
  hiddenTerritoriesPeersTable?: boolean;
  visibleGetInfo?: boolean;
  projectId: string;
  isOutOfTimeInterval: boolean;
}

export const UnconnectedBaseDashboard: sBEM<Slots & Settings> = ({
                                                                   className,
                                                                   sectionGraph,
                                                                   sectionChart,
                                                                   hiddenLinksList,
                                                                   hiddenPeersList,
                                                                   hiddenWebsitesTable,
                                                                   hiddenTitlesTable,
                                                                   hiddenTerritoriesTable,
                                                                   hiddenTerritoriesPeersTable,
                                                                   visibleGetInfo,
                                                                   projectId,
                                                                   isOutOfTimeInterval
                                                                 }) => (
  <div className={cx(className, "dashboard", isOutOfTimeInterval && "dashboard--out-of-interval")}>
    <div className="dashboard__map-wrapper">
      <Hover initial={{code: "", level: 0, hovered: false}}>
        {({hover, hovered}) => (
          <React.Fragment>
            <LinksListOfCountries
              className="dashboard__countries-list"
              hidden={hiddenLinksList}
              projectId={projectId}
              onLineHover={(line, level: number) =>
                line
                  ? hover({code: line.code, level: level, hovered: true})
                  : hover({...hovered, hovered: false} as any)
              }
            />
            <PeersListOfCountries
              className="dashboard__countries-list"
              hidden={hiddenPeersList}
              onLineHover={(line, level: number) =>
                line
                  ? hover({code: line.code, level: level, hovered: true})
                  : hover({...hovered, hovered: false} as any)
              }
            />
            <Map
              className="dashboard__map"
              hovered={hovered && hovered.hovered}
              countryHovered={hovered && hovered.code}
              yLevel={hovered && hovered.level}
              projectId={projectId}
            />
          </React.Fragment>
        )}
      </Hover>
    </div>
    {sectionGraph}
    {sectionChart}
    <div className="dashboard__tables">
      <div className="dashboard__right-table-wrapper">
        <WebsitesTableData
          className="dashboard__table"
          hidden={hiddenWebsitesTable}
          projectId={projectId}
        />
        <TitlesTableData
          className="dashboard__table"
          hidden={hiddenTitlesTable}
        />
        <div
          className={cx("dashboard__get-info", {
            "dashboard__get-info--visible": visibleGetInfo
          })}
        >
          <p className="dashboard__get-caption" children="To get full report"/>
          <MediumBlueButton
            className="dashboard__get-link"
            caption="contact us"
          />
        </div>
      </div>
      <div className="dashboard__left-table-wrapper">
        <TerritoriesTableData
          className="dashboard__table"
          hidden={hiddenTerritoriesTable}
          projectId={projectId}
        />
        <TerritoriesPeersTableData
          className="dashboard__table"
          hidden={hiddenTerritoriesPeersTable}
        />
      </div>
    </div>
  </div>
);

export const BaseDashboard = webConnect(
  (state, {projectId}: { projectId: string }) => {
    const firstLink = getFirstLinkDate(state, projectId);
    const lastLink = getLastLinkDate(state, projectId);

    let interval = getSelectedInterval(state, projectId);
    if (!interval.start) {
      interval = {
        start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        end: new Date(Date.now())
      }
    }

    const center1 = (+firstLink + +lastLink) / 2;
    const center2 = (+interval.start! + +interval.end!) / 2;

    const w1 = (+lastLink - +firstLink) / 2;
    const w2 = (+interval.end! - +interval.start!) / 2;

    const isLoading = isWebsitesLinksLoading(state, projectId);
    const dateOverlap =  Math.abs(+center1 - center2) < (w1 + w2);
    const data = getWebsitesLinks(state, projectId).length > 0;

    //console.log(dateOverlap, firstLink, lastLink, center1, center2, interval);

    return {
      isOutOfTimeInterval: !isLoading && (!dateOverlap || !data)
    }
  }
)(UnconnectedBaseDashboard);

export const DashboardPremier: sBEM<{ projectId: string }> = props => (
  <BaseDashboard
    {...props}
    hiddenPeersList
    hiddenTitlesTable
    hiddenTerritoriesPeersTable
    sectionGraph={
      <PremierGraphSection
        className="dashboard__graph-section"
        projectId={props.projectId}
      />
    }
    sectionChart={
      <WebsitesChart className="dashboard__chart" projectId={props.projectId}/>
    }
  />
);

export const DashboardPackage: sBEM<{ projectId: string }> = props => (
  <BaseDashboard
    {...props}
    hiddenPeersList
    hiddenTitlesTable
    hiddenTerritoriesPeersTable
    sectionGraph={
      <PackageGraphSection
        className="dashboard__graph-section"
        projectId={props.projectId}
      />
    }
    sectionChart={
      <WebsitesChart className="dashboard__chart" projectId={props.projectId}/>
    }
  />
);

export const DashboardScanningResult: sBEM<{ projectId: string }> = props => (
  <BaseDashboard
    {...props}
    hiddenPeersList
    hiddenTitlesTable
    hiddenTerritoriesPeersTable
    sectionGraph={
      <ScanningGraphSection
        className="dashboard__graph-section"
        projectId={props.projectId}
      />
    }
    sectionChart={
      <WebsitesChart className="dashboard__chart" projectId={props.projectId}/>
    }
  />
);

export const DashboardScanningOnly: sBEM<{ projectId: string }> = props => (
  <BaseDashboard
    {...props}
    hiddenPeersList
    hiddenTitlesTable
    hiddenTerritoriesPeersTable
    visibleGetInfo
    sectionGraph={
      <ScanningGraphSection
        className="dashboard__graph-section"
        projectId={props.projectId}
      />
    }
    sectionChart={
      <WebsitesChart className="dashboard__chart" projectId={props.projectId}/>
    }
  />
);

export const DashboardGeoAnalysis: sBEM<{ projectId: string }> = props => (
  <BaseDashboard
    {...props}
    hiddenLinksList
    hiddenWebsitesTable
    hiddenTerritoriesTable
  />
);
