import { all } from "redux-saga/effects";
import { userSaga } from "core/state/user/saga";
import { projectsSaga } from "core/state/projects/saga";
import { titlesSaga } from "core/state/titles/saga";
import { websitesSaga } from "core/state/websites/saga";
import { hooksSaga } from "core/state/hooks/saga";
import { metaSaga } from "core/state/meta/saga";

export function* storyStart() {
  yield all([
    userSaga(),
    projectsSaga(),
    titlesSaga(),
    websitesSaga(),
    metaSaga(),

    hooksSaga()
  ]);
}
