import * as React from "react";
import * as cx from "classnames";
import { Value } from "react-powerplug";

import "./MailReceivers.scss";
import { sBEM } from "common/type-helpers";
import { BigBlueButton } from "components/UIKit/button";
import { uid } from "core/uid";
import { webConnect } from "core/redux";
import { getEmails } from "core/state/user/selectors";
import { setUserNotification } from "core/state/user/actions";

interface DataUser {
  id: string;
  name: string;
  mail: string;
  flag: boolean;
}

type ListUsers = DataUser[];

const change = (users: ListUsers, id: string, flag: boolean) => {
  const result = [...users];
  const index = result.indexOf(result.find(u => u.id === id)!);
  result[index] = {
    ...result[index],
    flag
  };
  return result;
};

export const BaseMailReceiversForm: sBEM<{
  users: ListUsers;
  changeNotifications: (users: ListUsers, originalUsers: ListUsers) => any;
  onClose: () => void;
}> = ({ className, users: originalUsers, changeNotifications, onClose }) => (
  <Value initial={originalUsers}>
    {({ value: users, setValue }) => (
      <form className={cx(className, "mail-receivers")}>
        <ul className="mail-receivers__list">
          {users.map(item => (
            <li className="mail-receivers__item" key={uid(item)}>
              <span className={"mail-receivers__id"} children={item.id} />
              <div className={"mail-receivers__info-wrapper"}>
                <span className={"mail-receivers__name"} children={item.name} />
                <span className={"mail-receivers__mail"} children={item.mail} />
              </div>
              <div className="mail-receivers__inputs-wrapper">
                <p className="mail-receivers__input-wrapper">
                  <input
                    type="radio"
                    name={`${uid(item)}-status`}
                    id={`${uid(item)}-yes`}
                    className="mail-receivers__input"
                    checked={item.flag}
                    onChange={e =>
                      e.target.checked && setValue(change(users, item.id, true))
                    }
                  />
                  <label
                    className="mail-receivers__label"
                    children="yes"
                    htmlFor={`${uid(item)}-yes`}
                  />
                </p>
                <p className="mail-receivers__input-wrapper">
                  <input
                    type="radio"
                    name={`${uid(item)}-status`}
                    id={`${uid(item)}-no`}
                    className="mail-receivers__input"
                    checked={!item.flag}
                    onChange={e =>
                      e.target.checked &&
                      setValue(change(users, item.id, false))
                    }
                  />
                  <label
                    className="mail-receivers__label"
                    children="no"
                    htmlFor={`${uid(item)}-no`}
                  />
                </p>
              </div>
            </li>
          ))}
        </ul>
        <BigBlueButton
          className="mail-receivers__submit"
          submit={false}
          caption="Apply"
          onClick={() => {
            changeNotifications(users, originalUsers);
            onClose();
          }}
        />
      </form>
    )}
  </Value>
);

const collectChanges = (users: ListUsers, originalUsers: ListUsers) => {
  const result: { id: string; flag: boolean }[] = [];
  users.forEach((v, index) => {
    if (v.flag != originalUsers[index].flag) {
      result.push({ id: v.id, flag: v.flag });
    }
  });
  return result;
};

export const MailReceiversForm = webConnect(
  state => ({
    users: getEmails(state).map(({ id, name, sentNotices }) => ({
      id,
      name,
      flag: sentNotices
    }))
  }),
  {
    changeNotifications: (users: ListUsers, originalUsers: ListUsers) =>
      setUserNotification(
        collectChanges(users, originalUsers),
        "SendNoticeOnNewCopy"
      )
  }
)(BaseMailReceiversForm);

export const MailDailyReceiversForm = webConnect(
  state => ({
    users: getEmails(state).map(({ id, name, mail, sentDailyReports }) => ({
      id,
      name,
      mail,
      flag: sentDailyReports
    }))
  }),
  {
    changeNotifications: (users: ListUsers, originalUsers: ListUsers) =>
      setUserNotification(collectChanges(users, originalUsers), "DailyReports")
  }
)(BaseMailReceiversForm);
