import * as React from "react";
import * as cx from "classnames";
import { sBEM } from "common/type-helpers";

import { MediumBlueButton } from "components/UIKit/button/index";

import "./CalendarFilter.scss";
import { uid } from "core/uid";
import { DateInterval } from "components/header-in-main/components/calendar-section/calendar/Calendar";

const roundDate = (inDate: Date | number, Y = 1, M = 1, D = 1) => {
  const date = new Date(inDate);
  return new Date(
    date.getFullYear() * Y,
    date.getMonth() * M,
    date.getDate() * D || 1
  );
};

export type setIntervalFn = (interval: DateInterval) => any;

const DAYS = 1000 * 60 * 60 * 24;

const SELECT_CAPTION = [
  {
    caption: "Today",
    action: (setInterval: setIntervalFn) =>
      setInterval({
        start: roundDate(new Date()),
        end: roundDate(Date.now() + 1 * DAYS)
      }),
      getDates: () => ({
        start: roundDate(new Date()),
        end: roundDate(Date.now() + 1 * DAYS)
      })
  },
  {
    caption: "Last 7 Days",
    action: (setInterval: setIntervalFn) =>
      setInterval({
        start: roundDate(Date.now() - 7 * DAYS),
        end: roundDate(new Date())
      }),
      getDates: () => ({
        start: roundDate(Date.now() - 7 * DAYS),
        end: roundDate(new Date())
      })
  },
  // {
  //   caption: "Last 30 Days",
  //   action: (setInterval: setIntervalFn) =>
  //     setInterval({
  //       start: roundDate(Date.now() - 30 * DAYS),
  //       end: roundDate(new Date())
  //     })
  // },
  // {
  //   caption: "Last month",
  //   action: (setInterval: setIntervalFn) =>
  //     setInterval({
  //       start: roundDate(new Date(), 1, 1, 0),
  //       end: roundDate(new Date())
  //     })
  // },
  {
    caption: "Last month",
    action: (setInterval: setIntervalFn) => {
      const now = new Date();
      return setInterval({
        //year, monthIndex [, day
        start: roundDate(new Date(now.getFullYear(), now.getMonth()-1, now.getDate())),
        end: roundDate(new Date())
      })
    },
    getDates: () => {
      const now = new Date();
      return {
        start: roundDate(new Date(now.getFullYear(), now.getMonth()-1, now.getDate())),
        end: roundDate(new Date())
      }
    }
  },

  {
    caption: "All time",
    action: (setInterval: setIntervalFn) =>
      setInterval({
        start: roundDate(0),
        end: roundDate(new Date())
      }),

    getDates: () => ({
      start: roundDate(0),
        end: roundDate(new Date())
    })
  }
];

const getMaxDate = (a: Date, b: Date): Date =>
  a > b
    ? a
    : b;

const getMinDate = (a: Date, b: Date): Date =>
  a < b
    ? a
    : b;

// const testInterval = (i1: DateInterval) => (i2: DateInterval) => (
//   (+i1.start!) === (+i2.start!) && (+i1.end!)=== (+i2.end!)
// );

// const isActive = (interval:DateInterval, action: (cb: setIntervalFn)=> boolean) =>
//     action(testInterval(interval))

const isIntervalSelected = (interval:DateInterval, selectedInterval: DateInterval): boolean =>
    ((+interval.start!) === (+selectedInterval.start!) && (+interval.end!)=== (+selectedInterval.end!))

const trimInterval = (interval: DateInterval, minDate: Date, maxDate: Date): DateInterval => ({
  start: getMaxDate(interval.start!, minDate), 
  end: getMinDate(interval.end!, maxDate) 
})

const onClickAction = (interval:  DateInterval, setInterval: setIntervalFn): any => {
  return setInterval(interval);
}

export const BaseCalendarFilter: sBEM<{
  setInterval: setIntervalFn;
  onSubmit: () => void;
  interval: DateInterval;
  minDate: Date;
  maxDate: Date;
}> = ({ className, setInterval, onSubmit, interval, minDate, maxDate }) => (
  <form className={cx(className, "calendar-filter")}>
    <ul className="calendar-filter__list">
      {SELECT_CAPTION.map((item, index) => (
        <li className="calendar-filter__item" key={uid(item)}>
          <input
            id={`calendarFilter-id-${index}`}
            className="calendar-filter__field"
            type="radio"
            name="select"
            onClick={() => onClickAction(trimInterval(item.getDates(), minDate, maxDate), setInterval)}
          />
          <label
            htmlFor={`calendarFilter-id-${index}`}
            className={cx("calendar-filter__caption", isIntervalSelected(interval, trimInterval(item.getDates(), minDate, maxDate)) && "calendar-filter__caption--selected")}
            children={item.caption}
          />
        </li>
      ))}
    </ul>
    <MediumBlueButton
      className="calendar-filter__submit"
      caption="Select"
      onClick={onSubmit}
    />
  </form>
);

export const CalendarFilter: sBEM<{
  setInterval: setIntervalFn;
  onSubmit: () => void;
  interval: DateInterval;
  minDate: Date;
  maxDate: Date;
}> = props => <BaseCalendarFilter {...props} />;
