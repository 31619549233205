var React = require('react');

function Scanning (props) {
    return React.createElement("svg",props,React.createElement("g",{"strokeWidth":"1","fill":"none","fillRule":"evenodd","transform":"translate(-192.000000, -320.000000)","strokeLinecap":"round","strokeLinejoin":"round"},React.createElement("g",{"transform":"translate(70.000000, 190.000000)","strokeWidth":"3"},React.createElement("g",{"transform":"translate(124.000000, 132.000000)"},[React.createElement("path",{"d":"M24.0112305,5.01989746 C22.8863932,6.08988444 21.8660266,7.0988814 20.9501305,8.04688834 C20.4740981,3.5243861 16.6486313,0 12,0 C8.46558815,0 5.40702701,2.03735911 3.93465642,5.0017375","key":0}),React.createElement("path",{"d":"M0.0838623047,13.0375538 C0.927612305,12.216294 1.91955949,11.2178703 3.05970387,10.0422829 C3.57629616,14.5218564 7.3819661,18 12,18 C15.5187746,18 18.5658997,15.9806288 20.0457079,13.0375538","key":1})]))));
}

Scanning.displayName = "Scanning";

Scanning.defaultProps = {"width":"28px","height":"22px","viewBox":"0 0 28 22","version":"1.1"};

module.exports = Scanning;

Scanning.default = Scanning;
