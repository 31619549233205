import * as React from "react";
import * as cx from "classnames";

import { IconField } from "components/UIKit/field";
import { GreyCheckbox } from "components/UIKit/checkbox";
import { BigBlueButton } from "components/UIKit/button";

interface PropTypes {
  className: string;
  action: string;
  method: string;
  loginAction: (obj: any) => void;
  isError: boolean;
}

class LoginForm extends React.Component<
  PropTypes,
  {
    error: boolean;
    login?: string;
    password?: string;
    keepme?: string;
  }
> {
  state = {
    error: false
  };

  static getDerivedStateFromProps(props: PropTypes) {
    return {
      error: props.isError
    };
  }

  readback = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({
      error: false,
      [event.target.name]: event.target.value
    });

  tryLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.setState(
      {
        error: false
      },
      () => {
        this.props.loginAction(this.state);
      }
    );
  };

  render() {
    const { className, action, method } = this.props;
    return (
      <form
        className={cx(className, "author-form")}
        action={action}
        method={method}
        onSubmit={this.tryLogin}
      >
        <IconField
          className="author-form__field"
          mod="login"
          type="text"
          name="login"
          placeholder="User name"
          onChange={this.readback}
        />
        <div className="author-form__field-wrapper">
          <IconField
            className="author-form__field"
            mod="password"
            type="password"
            name="password"
            placeholder="Password"
            onChange={this.readback}
          />
          <a
            href="#"
            className="author-form__fogget"
            type="button"
            children="Forgot?"
          />
        </div>

        <div className="author-form__error">
          {this.state.error ? "Could not get you in" : "\u00A0"}
        </div>

        <GreyCheckbox
          className="author-form__checkbox"
          id="save"
          name="save"
          caption="Keep me logged"
          onChange={this.readback}
        />
        <BigBlueButton className="author-form__submit" submit caption="Login" />
      </form>
    );
  }
}

export default LoginForm;
