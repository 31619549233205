var React = require('react');

function Notice (props) {
    return React.createElement("svg",props,React.createElement("g",{"fillRule":"evenodd","transform":"translate(-1104.000000, -26.000000)"},React.createElement("g",{"transform":"translate(1104.000000, 26.000000)"},[React.createElement("path",{"stroke":"none","d":"M8,0 L8,0 C8.55228475,-1.01453063e-16 9,0.44771525 9,1 L9,4 L7,4 L7,1 C7,0.44771525 7.44771525,1.01453063e-16 8,0 Z","key":0}),React.createElement("path",{"fill":"none","strokeWidth":"2","d":"M8,3 C5.21174467,3 2.91478581,5.18939015 2.78115523,7.97444144 L2.4741965,14.3718984 L1.66178294,16.0570417 L14.3289692,16.0098734 L13.5362742,14.4005745 L13.2232336,7.97496467 C13.0875106,5.18905498 10.7892138,3 8,3 Z","key":1}),React.createElement("path",{"stroke":"none","d":"M6,18 L10,18 L10,18 C10,19.1045695 9.1045695,20 8,20 L8,20 C6.8954305,20 6,19.1045695 6,18 Z","key":2})])));
}

Notice.displayName = "Notice";

Notice.defaultProps = {"width":"16px","height":"20px","viewBox":"0 0 16 20","version":"1.1"};

module.exports = Notice;

Notice.default = Notice;
