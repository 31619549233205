export const TYPE_DDL = 1;
export const TYPE_P2P = 2;
export const TYPE_Online = 3;
export const TYPE_LinkSite = 4;
export const TYPE_UGC = 5;

export const WEBSITE_TYPES = {
  [TYPE_DDL]: "Cyberlocker",
  [TYPE_P2P]: "Bittorrent",
  [TYPE_Online]: "Streaming",
  [TYPE_LinkSite]: "Linking Site",
  [TYPE_UGC]: "UGC"
};

export default {
  "0": {
    types: {
      loaded: true,
      error: false,
      data: [{type: 2, count: 8595}]
    },
    data: {
      loaded: true,
      error: false,
      data: [
        {
          host: "1337x.to",
          visitsCount: 133333,
          visits: [{countryId: 1, visitsFraction: 0.5}],
          type: 0,
          count: 6226,
          outOfCoverage: true,
        }
      ]
    }
  },
  "5": {
    links: {
      loaded: true,
      error: false,
      data: {
        foundTotal: 155124,
        removedTotal: 66986,
        firstLink: "2018-01-01T00:00:00",
        lastLink: "2018-01-01T00:00:00",
        intervals: [
          {
            intervalStartDate: "2018-01-01T00:00:00",
            found: 39786,
            removed: 21320,
            type: 0
          },
          {
            intervalStartDate: "2018-02-01T00:00:00",
            found: 26522,
            removed: 21836
          },
          {
            intervalStartDate: "2018-03-01T00:00:00",
            found: 33249,
            removed: 7582
          },
          {
            intervalStartDate: "2018-04-01T00:00:00",
            found: 23912,
            removed: 6918
          },
          {
            intervalStartDate: "2018-05-01T00:00:00",
            found: 31655,
            removed: 9330
          }
        ]
      }
    },
    types: {
      loaded: true,
      error: false,
      data: [
        {type: 2, count: 8595},
        {type: 1, count: 7409},
        {type: 5, count: 1870},
        {
          type: 3,
          count: 836
        },
        {type: 4, count: 122}
      ]
    },
    sites: {
      loaded: true,
      error: false,
      data: [
        {host: "1337x.to", visitsCount: 1, type: 1, count: 6226, outOfCoverage: false},
        {
          host: "rapidgator.net",
          visitsCount: 0,
          type: 1,
          count: 1328,
          outOfCoverage: true,
        },
        {host: "uploaded.net", visitsCount: 0, type: 2, count: 1092, outOfCoverage: false},
        {
          host: "picktorrent.com",
          visitsCount: 0,
          type: 2,
          count: 1061,
          outOfCoverage: true,
        },
        {host: "nitroflare.com", visitsCount: 0, type: 0, count: 917, outOfCoverage: false},
        {
          host: "ok.ru",
          visitsCount: 0,
          type: 3,
          count: 777,
          outOfCoverage: false,
        },
        {host: "openload.co", visitsCount: 0, type: 0, count: 704, outOfCoverage: false},
        {
          host: "filefactory.com",
          visitsCount: 0,
          type: 4,
          count: 604,
          outOfCoverage: false,
        },
        {host: "turbobit.net", visitsCount: 0, type: 0, count: 583, outOfCoverage: false},
        {
          host: "torrentz2.eu",
          visitsCount: 0,
          type: 5,
          count: 409,
          outOfCoverage: false,
        }
      ]
    }
  }
};
