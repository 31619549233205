export const WEBSITES_LOAD = "websites/GET";
export const WEBSITES_SET = "websites/SET";
export const WEBSITES_TYPES_LOAD = "website-types/GET";
export const WEBSITES_TYPES_SET = "website-types/SET";
export const WEBSITES_LINKS_LOAD = "website-links/GET";
export const WEBSITES_LINKS_SET = "website-links/SET";
export const WEBSITES_DOWNLOAD = "websites/DOWNLOAD";

export const loadWebsites = (payload: any) => ({
  type: WEBSITES_LOAD,
  payload
});
export const loadWebsiteTypes = (payload: any) => ({
  type: WEBSITES_TYPES_LOAD,
  payload
});
export const loadWebsiteLinks = (payload: any) => ({
  type: WEBSITES_LINKS_LOAD,
  payload
});

export const downloadWebsite = (payload: any, type: "csv" | "xls" | "final" | "compl") => ({
  type: WEBSITES_DOWNLOAD,
  payload: { ...payload, type }
});
