import * as React from "react";
import * as cx from "classnames";

import {Toggle} from "react-powerplug";

import {ExitSvgLink, NoticeSvgLink} from "components/UIKit/svglink/index";
import {NoticeTooltip} from "components/tooltip/index";

import "./UserNavigation.scss";
import {getUserName} from "core/state/user/selectors";
import {sBEM} from "common/type-helpers";
import {webConnect} from "core/redux";
import {userLogout} from "core/state/user/actions";
import {Link} from "react-router-dom";
import {URLConsumer} from "components/URLConsumer/URLConsumer";
import Locky from 'react-locky';

export const StatelessUserNavigation: sBEM<{
  mod?: string;
  userName: string;
  logout: () => any;
}> = ({className, mod, userName, logout}) => (
  <nav
    className={cx(className, "navigation", {
      [`navigation--${mod}`]: !!mod
    })}
  >
    <ul className="navigation__list">
      { false && /* notifications are disabled*/ <li className="navigation__item">
        <Toggle>
          {({on, toggle}) => (
            <React.Fragment>
              <NoticeSvgLink className="navigation__notice" onClick={toggle}/>
              <Locky enabled={on} onEscape={toggle} noDefault events={{click: 'report'}}>
                <NoticeTooltip
                  className={cx("navigation__tooltip")}
                  visible={on}
                />
              </Locky>
            </React.Fragment>
          )}
        </Toggle>
      </li> }
      <li className="navigation__item">
        <p className="navigation__user-wrapper">
          Hi,&nbsp;
          <URLConsumer>
            {({clientId}) => (
              <Link
                to={`/${clientId}/account`}
                className="navigation__user"
                children={userName}
              />
            )}
          </URLConsumer>
        </p>
        <Link
          className="navigation__exit"
          children={<ExitSvgLink/>}
          onClick={logout}
          to="/"
        />
      </li>
    </ul>
  </nav>
);

export const UserNavigation = webConnect(
  (state: any) => ({userName: getUserName(state)}),
  dispatch => ({
    logout: () => dispatch(userLogout())
  })
)(StatelessUserNavigation);
