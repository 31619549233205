import * as React from "react";
import * as cx from "classnames";
import {sBEM} from "common/type-helpers";

import "./Calendar.scss";
import {Value} from "react-powerplug";
import {StrollableContainer} from "react-stroller";
import {DAY_NAMES_SHORT, MONTHS_NAMES_SHORT} from "common/listMonths";

const fillYears = (from: Date, to: Date) => (
  Array(to.getFullYear() - from.getFullYear() + 1).fill(1).map((x, index) => from.getFullYear()  + index)
);

export interface DateInterval {
  start?: Date;
  end?: Date;
}

interface CalendarDay {
  disabled: boolean;
  day: number;
  date: Date;
  month: number;
  year: number;
}

type SetDateFn = (day: Date) => any;

const generateDays = (month: number, year: number): CalendarDay[] => {
  const start = new Date(year, month, 1);
  const end = new Date(year, month + 1, 0);

  const startDay = start.getDay() % 7;
  const endDay = end.getDay() % 7;

  const result: CalendarDay[] = [];

  new Array(startDay).fill(1).forEach((_, index) => {
    const day = new Date(year, month, -startDay + index + 1).getDate();
    result.push({
      disabled: true,
      day,
      month: month - 1,
      year,
      date: new Date(year, month - 1, day)
    });
  });

  new Array(end.getDate()).fill(1).forEach((_, index) => {
    const day = new Date(year, month, index + 1).getDate();
    result.push({
      disabled: false,
      day,
      month: month + 0,
      year,
      date: new Date(year, month, day)
    });
  });

  new Array(6 - endDay).fill(1).forEach((_, index) => {
    const day = new Date(year, month + 1, index + 1).getDate();
    result.push({
      disabled: true,
      day: new Date(year, month + 1, index + 1).getDate(),
      month: month + 1,
      year,
      date: new Date(year, month + 1, day)
    });
  });

  return result;
};

const StrollBar = () => <div className="calendar__scrollbar"/>;

const YearPanel: React.SFC<{
  from: Date,
  to: Date,
  year: number;
  setYear: (month: number) => void;
}> = ({from, to, year, setYear}) => (
  <div className="calendar__year-month-wrapper">
    <div className="calendar__up-content-hidden"/>
    <div className="calendar__down-content-hidden"/>
    <div className="calendar__scroll-wrapper">
      <StrollableContainer draggable bar={StrollBar} overscroll>
        <ol className="calendar__year-month-list">
          {fillYears(from, to).map((value) => (
            <li key={value} className={"calendar__year-month-item"}>
              <button
                className={cx("calendar__year-month-select", {
                  ["calendar__year-month-select--active"]: year === value
                })}
                type="button"
                onClick={() => setYear(value)}
              >
                <span className="calendar__month-caption" children={value}/>
              </button>
            </li>
          ))}
        </ol>
      </StrollableContainer>
    </div>
  </div>
);

const MonthPanel: React.SFC<{
  month: number;
  setMonth: (month: number) => void;
}> = ({month, setMonth}) => (
  <div className="calendar__year-month-wrapper">
    <div className="calendar__up-content-hidden"/>
    <div className="calendar__down-content-hidden"/>
    <div className="calendar__scroll-wrapper">
      <StrollableContainer draggable bar={StrollBar} overscroll>
        <ol className="calendar__year-month-list">
          {MONTHS_NAMES_SHORT.map((item, index) => (
            <li key={item} className={"calendar__year-month-item"}>
              <button
                className={cx("calendar__year-month-select", {
                  ["calendar__year-month-select--active"]: month === index
                })}
                type="button"
                onClick={() => setMonth(index)}
              >
                <span className="calendar__month-caption" children={item}/>
              </button>
            </li>
          ))}
        </ol>
      </StrollableContainer>
    </div>
  </div>
);

const DayPanelHead = () => (
  <ol className="calendar__day-head-list">
    {DAY_NAMES_SHORT.map((item, index) => (
      <li
        key={item + index}
        className="calendar__day-head-item"
        children={item}
      />
    ))}
  </ol>
);

const Day: React.SFC<{
  day: CalendarDay;
  selection: DateInterval;
  onClick: SetDateFn;
  from:Date,
  to: Date,
}> = ({day, selection, onClick, from, to}) => (
  <button
    className={cx("calendar__day-select", {
      "calendar__day-select--disabled": day.disabled,
      "calendar__day-select--interval-start": +day.date === +selection.start!,
      "calendar__day-select--interval-end": +day.date === +selection.end!,
      "calendar__day-select--interval-selected":
      day.date > selection.start! && day.date < selection.end!,
      "calendar__day-select--interval-disabled":
        !(day.date >= from! && day.date <= to!),
    })}
    type="button"
    onClick={() => onClick(day.date)}
    children={day.day}
  />
);

const StatelessDayPanelBody: React.SFC<{
  days: CalendarDay[];
  selection: DateInterval;
  month: number;
  pickDate: SetDateFn;
  from:Date,
  to: Date,
}> = ({days, selection, month, pickDate, from, to}) => (
  <ol className="calendar__day-list">
    {days.map(day => (
      <li className="calendar__day-item" key={"x" + month + +day.date}>
        <Day day={day} selection={selection} onClick={pickDate} from={from} to={to}/>
      </li>
    ))}
  </ol>
);

const DayPanelBody: React.SFC<{
  month: number;
  year: number;
  selection: DateInterval;
  pickDate: SetDateFn;
  from:Date,
  to: Date,
}> = ({month, year, selection, pickDate, from, to}) => (
  <StatelessDayPanelBody
    days={generateDays(month, year)}
    selection={selection}
    pickDate={pickDate}
    month={month}
    from={from}
    to={to}
  />
);

export const BaseCalendar: sBEM<{
  selection: DateInterval;
  pickDate: SetDateFn;
  minDate: Date,
  maxDate: Date
}> = ({className, selection, pickDate, minDate, maxDate}) => (
  <Value initial={new Date().getFullYear()}>
    {({value: year, setValue: setYear}) => (
      <Value initial={new Date().getMonth()}>
        {({value: month, setValue: setMonth}) => (
          <div className={cx(className, "calendar")}>
            <YearPanel year={year} setYear={setYear} from={minDate} to={maxDate}/>
            <MonthPanel month={month} setMonth={setMonth}/>
            <div className="calendar__day-wrapper">
              <DayPanelHead/>
              <DayPanelBody
                month={month}
                year={year}
                selection={selection}
                pickDate={pickDate}
                from={minDate}
                to={maxDate}
              />
            </div>
          </div>
        )}
      </Value>
    )}
  </Value>
);

export const Calendar: sBEM<{
  selection: DateInterval;
  pickDate: SetDateFn;
  minDate: Date;
  maxDate: Date;
}> = props => <BaseCalendar {...props} />;
