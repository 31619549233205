import * as React from "react";
import * as cx from "classnames";
import { State } from "react-powerplug";

import { SmallLightButton, BigBlueButton } from "components/UIKit/button/index";
import { BlueListCheckbox } from "components/UIKit/checkbox/index";
import { StrollableContainer } from "react-stroller";

import "./FilterForm.scss";
import { sBEM } from "common/type-helpers";
import { webConnect } from "core/redux";
import { getTitles } from "core/state/titles/selectors";
import { getWebsites } from "core/state/websites/selectors";
import { uid } from "core/uid";
import { getTitlesSelected } from "core/state/meta/selectors";
import { selectTitles } from "core/state/meta/actions";

type ListSelected = {
  id: string;
  name: string;
}[];

type SelectedHash = {
  [key: string]: boolean;
};

function values<T>(state: T) {
  return Object.keys(state).reduce((acc, x) => [...acc, state[x]], []);
}

function allSet<T>(items: ListSelected, value: boolean): SelectedHash {
  return items.reduce((acc: any, x: any) => {
    acc[x.id] = value;
    return acc;
  }, {});
}

const StrollBar = () => <div className="filter__scrollbar" />;

export const BaseFilterForm: sBEM<{
  captionForm: string;
  listSelected: ListSelected;
  selected: SelectedHash;
  projectId: string;
  selectTitles: (projectId: string, selected: SelectedHash) => any;
  onClose?: () => void;
}> = ({
  className,
  captionForm,
  selected,
  listSelected,
  projectId,
  selectTitles,
  onClose
}) => (
  <State initial={selected}>
    {({ state, setState }) => (
      <form className={cx(className, "filter")}>
        <div className="filter__header">
          <h3
            className="filter__title"
            children={captionForm.replace(
              "%d",
              String(values(state).filter(Boolean).length)
            )}
          />
          <SmallLightButton
            className="filter__on"
            caption="Select all"
            onClick={() => setState(allSet(listSelected, true))}
          />
          <SmallLightButton
            className="filter__off"
            caption="Deselect"
            onClick={() => setState(allSet(listSelected, false))}
          />
        </div>
        <div className="filter__scroll-wrapper">
          <StrollableContainer draggable bar={StrollBar} overscroll>
            <ul className="filter__list">
              {listSelected.map(item => (
                <BlueListCheckbox
                  key={uid(item)}
                  className="filter__item"
                  caption={item.name}
                  name={`film-${item.id}`}
                  id={`id-${item.id}`}
                  checked={state[item.id]}
                  onChange={() => setState({ [item.id]: !state[item.id] })}
                />
              ))}
            </ul>
          </StrollableContainer>
        </div>
        <BigBlueButton
          className="filter__submit"
          caption="Select"
          disabled={values(state).filter(x => x).length === 0}
          onClick={() => {
            selectTitles(projectId, state);
            onClose && onClose();
          }}
        />
      </form>
    )}
  </State>
);

export const TitlesFilterForm = webConnect(
  (state, { projectId }: { projectId: string }) => ({
    listSelected: getTitles(state, projectId),
    selected: getTitlesSelected(state, projectId),
    captionForm: "%d Titles Selected"
  }),
  {
    selectTitles
  }
)(BaseFilterForm);

export const WebsitesFilterForm = webConnect(
  (state, { projectId }: { projectId: string }) => ({
    listSelected: getWebsites(state, projectId) as any,
    selected: {},
    captionForm: "%d Websites Selected",
    selectTitles: (a: any, b: any) => {}
  })
)(BaseFilterForm);
