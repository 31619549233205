import * as React from "react";
import * as cx from "classnames";

import "./Button.scss";
import { sBEM } from "common/type-helpers";
import { reactType } from "common/reactType";

interface Props {
  caption: React.ReactNode;
  svg?: any;
  submit?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  to?: string;
  Component?: React.ComponentClass<any>;
}

interface ColorSizeProps {
  size: "big" | "medium" | "small";
  color: "blue" | "light" | "white";
}

type LikeButton = sBEM<Props>;

export const Button: sBEM<Props & ColorSizeProps> = ({
  className,
  submit,
  caption,
  svg,
  size,
  color,
  hidden,
  disabled,
  onClick,
  Component = reactType(<button />),
  ...props
}) => (
  <Component
    className={cx(className, "button", `button--${size}`, `button--${color}`, {
      "button--hidden": hidden,
      "button--disabled": disabled
    })}
    type={submit ? "submit" : "button"}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {svg && <span className="button__icon" children={svg} />}
    <span children={caption} />
  </Component>
);

export const MediumLightButton: LikeButton = props => (
  <Button {...props} size="medium" color="light" />
);

export const SmallLightButton: LikeButton = props => (
  <Button {...props} size="small" color="light" />
);

export const SmallWhiteButton: LikeButton = props => (
  <Button {...props} size="small" color="white" />
);

export const SmallBlueButton: LikeButton = props => (
  <Button {...props} size="small" color="blue" />
);

export const MediumBlueButton: LikeButton = props => (
  <Button {...props} size="medium" color="blue" />
);

export const BigBlueButton: LikeButton = props => (
  <Button {...props} size="big" color="blue" />
);

export const BigLightButton: LikeButton = props => (
    <Button {...props} size="big" color="light" />
);

const LinkLikeButton: sBEM<Props & ColorSizeProps> = props => (
  <Button {...props} Component={reactType(<a />)} />
);

export const BigBlueLinkButton: sBEM<Props> = props => (
  <LinkLikeButton {...props} size="big" color="blue" />
);
