import {put, takeLatest, call, all, select} from "redux-saga/effects";
import {
  WEBSITES_DOWNLOAD,
  WEBSITES_LINKS_LOAD,
  WEBSITES_LINKS_SET,
  WEBSITES_LOAD,
  WEBSITES_SET,
  WEBSITES_TYPES_LOAD,
  WEBSITES_TYPES_SET
} from "./actions";
import {SagaIterator} from "redux-saga";

import {backend} from "core/config";
import {fetch} from "common/fetch";
import {inJSON} from "common/inFormData";
import {
  getSelectedInterval,
  getTitlesSelected
} from "core/state/meta/selectors";
import {getUserName} from "core/state/user/selectors";
import {MONTHS_NAMES_SHORT} from "common/listMonths";
import {WEBSITES_DOWNLOAD_FINISHED, WEBSITES_DOWNLOAD_STARTED} from "core/state/meta/actions";

type WebsitesRequestSettings = {
  projectId: string;
  titleIds: number[];
  fromDate: number | null;
  endDate: number | null;
};

const getWebsites = (clientId: string, settings: WebsitesRequestSettings) =>
  fetch(`${backend}/${clientId}/statistics/topWebsites`, {
    method: "POST",
    body: inJSON(settings),
    headers: {
      "content-type": "application/json"
    }
  }).then(data => data.json());

const getWebsitesTypes = (
  clientId: string,
  settings: WebsitesRequestSettings
) =>
  fetch(`${backend}/${clientId}/statistics/websiteTypes`, {
    method: "POST",
    body: inJSON(settings),
    headers: {
      "content-type": "application/json"
    }
  }).then(data => data.json());

const getWebsitesLinks = (
  clientId: string,
  settings: WebsitesRequestSettings
) =>
  fetch(`${backend}/${clientId}/statistics/linksFound`, {
    method: "POST",
    body: inJSON(settings),
    headers: {
      "content-type": "application/json"
    }
  }).then(data => data.json());

const getDowloads = (
  clientId: string,
  type: string,
  settings: WebsitesRequestSettings
) =>{
  const urlStart = `${backend}/${clientId}/reports`;
  let promise = null;
  if (type === 'final') {
    promise = fetch(urlStart + `/getFinalReport`, {
      method: "POST",
      body: inJSON(settings),
      headers: {
        "content-type": "application/json"
      }
  }) ;
  }
  else if (type === 'compl') {
    promise = fetch(urlStart + `/getComplianceReport`, {
      method: "POST",
      body: inJSON(settings),
      headers: {
        "content-type": "application/json"
      }
  });
}
else {
  promise = fetch(urlStart, {
    method: "POST",
    body: inJSON({
      filter: settings,
      type: type === "xls" ? 0 : 1
    }),
    headers: {
      "content-type": "application/json"
    }
  });
}

  return promise.then(data => data.blob());
}
  

const withLeadingZero = (a: number) => (a < 9 ? `0${a}` : String(a));

const formatData = (a: Date): string | null =>
  a
    ? `${a.getFullYear()}-${withLeadingZero(a.getMonth() + 1)}-${withLeadingZero(
    a.getDate()
    )}`
    : null;

function* getProjectSettings(projectId: string) {
  const titles = yield select(getTitlesSelected, projectId);
  const date = yield select(getSelectedInterval, projectId);
  return {
    projectId,
    titleIds: Object.keys(titles).map((key) => titles[key] ? +key : null).filter(Number),
    fromDate: formatData(date.start),
    endDate: formatData(date.end)
  } as WebsitesRequestSettings;
}

function* websitesLoad_saga({payload}: any) {
  const {projectId, clientId} = payload;

  try {
    yield put({
      type: WEBSITES_SET,
      payload: {projectId, clientId, loading: true, error:false}
    });
    const settings: WebsitesRequestSettings = yield call(
      getProjectSettings,
      projectId
    );
    const data = yield call(getWebsites, clientId, settings);
    yield put({type: WEBSITES_SET, payload: {projectId, data, loading: false}});
  } catch (e) {
    yield put({
      type: WEBSITES_SET,
      payload: {projectId, clientId, error: true}
    });
  }
}

function* websitesTypesLoad_saga({payload}: any) {
  const {projectId, clientId} = payload;
  try {
    yield put({
      type: WEBSITES_TYPES_SET,
      payload: {projectId, loading: true, error:false}
    });
    const settings: WebsitesRequestSettings = yield call(
      getProjectSettings,
      projectId
    );
    const data = yield call(getWebsitesTypes, clientId, settings);
    yield put({type: WEBSITES_TYPES_SET, payload: {projectId, data, loading: false}});
  } catch (e) {
    yield put({
      type: WEBSITES_TYPES_SET,
      payload: {projectId, error: true}
    });
  }
}

function* websitesLinksLoad_saga({payload}: any) {
  const {projectId, clientId} = payload;
  try {
    yield put({
      type: WEBSITES_LINKS_SET,
      payload: {projectId, loading: true, error:false }
    });
    const settings: WebsitesRequestSettings = yield call(
      getProjectSettings,
      projectId
    );
    const data = yield call(getWebsitesLinks, clientId, settings);
    yield put({type: WEBSITES_LINKS_SET, payload: {projectId, data, loading: false}});
  } catch (e) {
    yield put({
      type: WEBSITES_LINKS_SET,
      payload: {projectId, error: true}
    });
  }
}

function getExtensionByType(type: string) {
  return (type === 'compl' || type === 'final') && 'xls' || type;
}

function getReportFileExtension(type: string) : string {
  type = getExtensionByType(type);
  if (type == 'xls') return 'xlsx';

  return type;
}

function getReportFilenamePrefix(type: string): string {
  return (type === 'compl' && 'compliance report') ||
      (type === 'final' && 'final report') ||
      ('selected titles');
}

function* websitesDownload({payload}: any) {
  yield put({type: WEBSITES_DOWNLOAD_STARTED});
  try {
    const {projectId, clientId, type} = payload;

    const settings: WebsitesRequestSettings = yield call(
      getProjectSettings,
      projectId
    );
    const data = yield call(getDowloads, clientId, type, settings);

    const blob = new Blob([data], {type: "application/" + getExtensionByType(type)});
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    const userName = yield select(getUserName);
    const date = yield select(getSelectedInterval, projectId);
    const dateName = date && date.end ? (
      MONTHS_NAMES_SHORT[date.end.getMonth()] + ' ' + date.end.getDate() + ' ' + date.end.getFullYear()
    ) : '';

    link.download = `${`${userName} ${getReportFilenamePrefix(type)} ${dateName}`.trim()}.${getReportFileExtension(type)}`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  } catch (e) {
    alert("Could not download report");
    console.error(e);
  }
  yield put({type: WEBSITES_DOWNLOAD_FINISHED});
}



export function* websitesSaga(): SagaIterator {
  yield all([
    takeLatest(WEBSITES_LOAD, websitesLoad_saga),
    takeLatest(WEBSITES_TYPES_LOAD, websitesTypesLoad_saga),
    takeLatest(WEBSITES_LINKS_LOAD, websitesLinksLoad_saga),
    takeLatest(WEBSITES_DOWNLOAD, websitesDownload)
  ]);
}
