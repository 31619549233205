import { put, takeLatest, call, all } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import { backend } from "core/config";
import { fetch } from "common/fetch";

import { COUNTRIES_GET, COUNTRIES_SET } from "core/state/meta/actions";

const fetchCountries = () =>
  fetch(`${backend}/countries`).then(data => data.json());

function* projectsCountries_saga() {
  try {
    const data = yield call(fetchCountries);
    yield put({ type: COUNTRIES_SET, payload: { data } });
  } catch (e) {
    yield put({ type: COUNTRIES_SET, payload: { error: true } });
  }
}

export function* metaSaga(): SagaIterator {
  yield all([takeLatest(COUNTRIES_GET, projectsCountries_saga)]);
}
