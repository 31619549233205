import * as React from "react";
import * as cx from "classnames";
import { sBEM } from "common/type-helpers";

import "./CalendarActionButton.scss";
import { getSelectedInterval } from "core/state/meta/selectors";
import { webConnect } from "core/redux";

import SvgArrow from "svg-react-loader!./images/icons/arrow.svgx";
import SvgCalendar from "svg-react-loader!./images/icons/calendar.svgx";
import { MONTHS_NAMES_SHORT } from "common/listMonths";

interface Props {
  hidden?: boolean;
  onClick?: () => void;
}

interface Interval {
  startPeriod: string;
  finishPeriod: string;
}

export const BaseCalendarActionButton: sBEM<Props & Interval> = ({
  className,
  startPeriod,
  finishPeriod,
  hidden,
  onClick
}) => (
  <button
    className={cx(className, "calendar-action-button", {
      "calendar-action-button--hidden": hidden
    })}
    type="button"
    onClick={onClick}
  >
    <span
      className="calendar-action-button__icon  calendar-action-button__icon--calendar"
      children={<SvgCalendar />}
    />
    {startPeriod ? (
      <p className="calendar-action-button__period-wrapper">
        <span children={startPeriod} />
        -
        <span children={finishPeriod} />
      </p>
    ) : (
      <span>7 Days</span>
    )}
    <span
      className="calendar-action-button__icon  calendar-action-button__icon--arrow"
      children={<SvgArrow />}
    />
  </button>
);

const strDate = (date: Date | undefined): string =>
  date ? MONTHS_NAMES_SHORT[date.getMonth()] + " " + date.getDate() : "";

export const CalendarActionButton = webConnect((state, {projectId}: {projectId: string}) => ({
  startPeriod: strDate(getSelectedInterval(state, projectId).start),
  finishPeriod: strDate(getSelectedInterval(state, projectId).end)
}))(BaseCalendarActionButton);
