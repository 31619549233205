import * as React from "react";

import Header from "components/header";
import {AccountList} from "components/account-list";
import {StoreShape} from "core/reducers";
import {connect} from "react-redux";
import {Value} from "react-powerplug";

import {IAccountsList} from "components/account-list/AccountList";
import {getSitesCanImpersonate} from "core/state/user/selectors";
import {SiteImpersonate} from "core/state/user/reducer";
import {selectUser} from "core/state/user/actions";
import {HeaderInMainProjects} from "components/header-in-main";
import {gearbox} from "react-gearbox";
import {ArchiveProjectModal} from "components/modal";
import {RestoreProjectModal} from "components/modal/Modal";

const nop = () => ({});

const ProjectState = gearbox({
  archive: <Value initial="" children={nop}/>,
  restore: <Value initial="" children={nop}/>,
});

type GV<T> = { value: T; setValue: (t: T) => any };

type GearTypes = {
  archive: GV<string>;
  restore: GV<string>;
};

export const Page: React.SFC<{
  list: IAccountsList[][];
  onSelect: (uid: string) => any;
}> = ({list, onSelect}) => (
  <ProjectState render>
    {({
        archive: {value: showArchiveProject, setValue: toggleArchiveProject},
        restore: {value: showRestoreProject, setValue: toggleRestoreProject},
      }: GearTypes) => (
      <Value initial={0}>
        {({value: activeTab, setValue: setActiveTab}) => (
          <div className="page">
            <Header className="page__header" mod="projects"/>
            <main className="page__main">
              <HeaderInMainProjects
                className="page__navigation"
                titlePage="Clients"
                mod="projects"
                activeTab={activeTab}
                onClick={setActiveTab}
                projectId=""
              />
              <AccountList
                className="page__master"
                list={list[activeTab]}
                onSelect={onSelect}
                activeTab={activeTab}
                onClickArchive={activeTab === 0 ? toggleArchiveProject : toggleRestoreProject}
              />
            </main>
            <ArchiveProjectModal
              className="page__modal"
              visible={!!showArchiveProject}
              projectId={showArchiveProject}
              animation="grow"
              onClose={() => toggleArchiveProject("")}
            />
            <RestoreProjectModal
              className="page__modal"
              visible={!!showRestoreProject}
              projectId={showRestoreProject}
              animation="grow"
              onClose={() => toggleRestoreProject("")}
            />
          </div>
        )}
      </Value>
    )}
  </ProjectState>
);

const genAccountList = (state: StoreShape, filter: (site: SiteImpersonate) => boolean) => {
  const result: IAccountsList[] = [];
  let lastChar = "";
  let acc: IAccountsList | null = null;
  getSitesCanImpersonate(state)
    .slice()
    .filter(filter)
    .sort(
      (a: SiteImpersonate, b: SiteImpersonate) =>
        a.name.charCodeAt(0) - b.name.charCodeAt(0)
    )
    .forEach(site => {
      if (lastChar !== site.name[0]) {
        if (acc) {
          result.push(acc);
        }
        lastChar = site.name[0];
        acc = {
          letter: lastChar,
          accounts: []
        };
      }
      acc!.accounts.push({
        id: site.id,
        name: site.name
        //date?
      });
    });

  if (acc) {
    result.push(acc);
  }
  return result;
};

export default connect(
  (state: StoreShape) => ({
    list: [
      genAccountList(state, ({archived}) => !archived),
      genAccountList(state, ({archived}) => !!archived),
    ]
  }),
  dispatch => ({
    onSelect: (uid: string) => dispatch(selectUser(uid))
  })
)(Page);
