import * as React from "react";
import ReactMapChart from "react-map-chart";
import * as cx from "classnames";
import {pure} from "recompose";
import {CSSTransition} from "react-transition-group";

import "./Map.scss";
import {WithClassName} from "common/type-helpers";
import {
  CountryWithValue,
  getLinksGeoStatistic,
  getMapGeoStatistic
} from "core/state/stat/selectors";
import {webConnect} from "core/redux";
import {StoreShape} from "core/reducers";
import {MapTooltip} from "components/tooltip";
import {WebsiteMapStat} from "components/dashboard/components/top-websites/TopWebsites";

const hoveredStyle = {
  fill: "#4E72C4",
  stroke: "#2D5DA7",
  transform: "translateY(-0.5px)"
};

type StatLines = {
  [key: string]: WebsiteMapStat[];
};

interface Data {
  data: { [key: string]: number };
  stat: StatLines;
}

interface PublicState {
  hovered: any;
}

interface PublicProps {
  countryHovered: string | null;
  yLevel: number | null;
}

interface State {
  lineData?: WebsiteMapStat[] | null;
  oldLineData?: WebsiteMapStat[] | null;
  mousePosition: number[];
}

const PReactMapChart = pure(ReactMapChart);

export class StatelessMap extends React.Component<PublicState & Data & WithClassName & PublicProps,
  PublicState & State> {
  state = {
    hovered: null,
    lineData: null,
    oldLineData: null,
    mousePosition: [0, 0]
  };

  element: HTMLDivElement;
  lastHovered: string = '';

  componentDidUpdate(props: PublicProps) {
    if (this.props.yLevel != props.yLevel) {
      this.setState({mousePosition: [50, this.props.yLevel || 0]});
    }
  }

  onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as any;
    if(!target.dataset){
      return;
    }
    const code = target.dataset.code;
    const line = this.props.stat![code];
    const elPos = this.element.getBoundingClientRect();

    this.setState({
      mousePosition: [
        event.pageX - elPos.left + 25,
        event.pageY - elPos.top - window.scrollY
      ]
    });

    if (line) {
      this.setState({
        lineData: line
      });
    } else {
      this.setState({
        lineData: null,
        oldLineData: this.state.lineData || this.state.oldLineData
      });
    }
  };

  setRef = (ref: HTMLDivElement) => (this.element = ref);

  styler = (x: number, code: string) => {
    const hovered =
      (this.props.hovered && this.props.countryHovered) || this.state.hovered;
    const {data} = this.props;
    return {
      className: "map__path",
      style:
        x > 0
          ? {
            fill: `rgba(62,114,196,${x})`,
            stroke: "#7995BF",
            transform: "translateY(0px)",
            ...(hovered === code ? hoveredStyle : {})
          }
          : {},

      onMouseEnter: () => data[code] && this.setState({hovered: code}),
      onMouseLeave: () => this.setState({hovered: null})
    };
  };

  onMouseLeave = () => this.setState({hovered: null});

  render() {
    const hovered =
      (this.props.hovered && this.props.countryHovered) || this.state.hovered;
    this.lastHovered = hovered || this.lastHovered;
    const {data, stat} = this.props;
    const {mousePosition, lineData} = this.state;
    return (
      <div
        className={cx(this.props.className, "map")}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        ref={this.setRef}
      >
        <div
          style={{
            left: mousePosition[0],
            top: mousePosition[1],
            position: "absolute"
          }}
        >
          <CSSTransition
            in={(!!lineData && hovered) || this.props.hovered}
            timeout={600}
            classNames="tooltip__animation"
            unmountOnExit
          >
            <MapTooltip data={stat[(hovered || this.lastHovered)as any]}/>
          </CSSTransition>
        </div>
        <PReactMapChart
          className="map__svg"
          hovered={hovered || undefined}
          native
          projection="gall"
          styler={this.styler}
          data={data}
        />
      </div>
    );
  }
}

const convert = (data: { list: CountryWithValue[]; values: number[] }) => {
  const max = Math.max(...data.values);
  return data.list
    .sort((a, b) => -a.value + b.value)
    .slice(0, 5)
    .map(({code, value}) => ({
      [code]: value / max,
    }))
    .reduce((acc, key) => ({...acc, ...key}), {});
};

export const Map = webConnect(
  (state: StoreShape, {projectId}: { projectId: string }) => ({
    data: convert(getLinksGeoStatistic(state, projectId)),
    stat: getMapGeoStatistic(state, projectId)
  })
)(StatelessMap);
