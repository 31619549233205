import * as React from "react";
import * as cx from "classnames";

import {
  PremierCard,
  NotAvailablePremierCard,
  PackageCard,
  ScanningCard,
  GeoAnalysisCard
} from "../../components/card";

import {DateInterval} from "components/header-in-main/components/calendar-section/calendar/Calendar";
import {INTERVAL_SET} from "core/state/meta/actions";

import "./Projects.scss";
import { CardEmpty } from "components/empty";
import { sBEM } from "common/type-helpers";
import { webConnect } from "core/redux";
import { getCompletedProjects } from "core/state/projects/selectors";
import { ProjectStateData } from "core/state/projects/reducer";
import { withRouter } from "react-router-dom";
import {
  PROJECT_TYPE_GEO,
  PROJECT_TYPE_PACKAGE,
  PROJECT_TYPE_PREMIER,
  PROJECT_TYPE_SCANNING_RESULTS
} from "core/state/projects/state";
import { RouteComponentProps } from "react-router";
import { getCurrentClientId } from "core/state/user/selectors";

const renderMap = {
  [PROJECT_TYPE_PREMIER]: PremierCard,
  [PROJECT_TYPE_PACKAGE]: PackageCard,
  NotAvailablePremierCard: NotAvailablePremierCard,
  [PROJECT_TYPE_SCANNING_RESULTS]: ScanningCard,
  geo: GeoAnalysisCard,
  empty: CardEmpty
};

const typeMap = {
  [PROJECT_TYPE_PREMIER]: "premiere",
  [PROJECT_TYPE_PACKAGE]: "package",

  [PROJECT_TYPE_SCANNING_RESULTS]: "scanning/base",
  [PROJECT_TYPE_GEO]: "geo-analysis"
};

const ProjectsList: sBEM<
  { projects: ProjectStateData; client: string, updateInterval: (interval: DateInterval) => void} & RouteComponentProps<any>
> = ({ projects, client, className, history, updateInterval }) => (
  <section className={cx(className, "projects")}>
    <ul className="projects__list">
      {projects.map(({ type, id, ...rest }) => {
        const Element =
          renderMap[type] || (() => <span>type {type} is not defined</span>);
        const url = typeMap[type];
        return (
          <li className="projects__item" key={id}>
            <div onClick={() => { updateInterval({ start: undefined, end: undefined }); history.push(`/${client}/${url}/${id}`); } }>
              <Element
                className="projects__link projects__link--completed"
                completed
                {...rest}
              />
            </div>
          </li>
        );
      })}
    </ul>
  </section>
);

export default webConnect(state => ({
  projects: getCompletedProjects(state),
  client: getCurrentClientId(state)
}),
(dispatch) => ({
  updateInterval: (interval: DateInterval) => {
  dispatch({type: INTERVAL_SET, payload: interval});
  
}}))(withRouter(ProjectsList));
