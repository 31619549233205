import Page from "./Projects";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { getCurrentClientId } from "core/state/user/selectors";
import { StoreShape } from "core/reducers";
import { connect } from "react-redux";

class RedirectToCurrentClientProjectsExecutor extends React.Component<
  RouteComponentProps<any> & { currentClientId: string }
> {
  componentDidMount() {
    if (this.props.currentClientId) {
      this.props.history.push(`/${this.props.currentClientId}/projects`);
    }
  }

  render() {
    return <Page history={this.props.history} />;
  }
}

export const RedirectToCurrentClientProjects = withRouter(
  connect((state: StoreShape) => ({
    currentClientId: getCurrentClientId(state)
  }))(RedirectToCurrentClientProjectsExecutor)
);

export default Page;
