import * as React from "react";
import * as cx from "classnames";

import "./PreHeader.scss";
import { sBEM } from "common/type-helpers";
import { Link } from "react-router-dom";

export const PreHeader: sBEM<{
  accountName: string;
  onAccountChange: () => any;
}> = ({ className, accountName, onAccountChange }) => (
  <div className={cx(className, "pre-header")}>
    <div className="pre-header__wrapper">
      <Link
        className="pre-header__link"
        children="Change account"
        onClick={onAccountChange}
        to="/projects"
      />
      <p className="pre-header__note">
        You are working under:
        <span children={accountName} />
      </p>
    </div>
  </div>
);
