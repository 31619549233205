import * as React from "react";
import { URLConsumer } from "components/URLConsumer/URLConsumer";
import { registerUIHook, unregisterUIHook } from "core/state/meta/actions";
import { webConnect } from "core/redux";

type dispatchEvent = (options: any) => any;
type mountHook = {
  page?: dispatchEvent[];
  hook?: dispatchEvent[];
};

class SubMount extends React.Component<{
  dispatch: any;
  projectId: string;
  clientId: string;
  event: mountHook;
  registerUIHook: (fn: any) => any;
  unregisterUIHook: (fn: any) => any;
}> {
  componentDidMount() {
    this.loadData();
    this.loadHook();
    this.props.registerUIHook(this.loadHook);
  }

  componentWillUnmount() {
    this.props.unregisterUIHook(this.loadHook);
  }

  load = (key: string) => {
    const { dispatch, event, projectId, clientId } = this.props;
    const events = event[key];
    if (events) {
      events.forEach((action: dispatchEvent) =>
        dispatch(action({ projectId, clientId }))
      );
    }
  };

  loadData = () => this.load("page");
  loadHook = () => this.load("hook");

  render() {
    return React.Children.only(this.props.children);
  }
}

const dispatch = (a: any) => a;

const Mount = webConnect(() => ({}), {
  registerUIHook,
  unregisterUIHook,
  dispatch
})(SubMount);

export const withPackageDataLoader = (mount: mountHook) => (
  WrappedComponent: React.ComponentType
) => (props: any) => (
  <URLConsumer>
    {match => (
      <Mount
        projectId={match.projectId}
        clientId={match.clientId}
        event={mount}
      >
        <WrappedComponent {...props} {...match} />
      </Mount>
    )}
  </URLConsumer>
);
