import * as React from "react";
import * as cx from "classnames";
import { connect } from "react-redux";

import { sBEM } from "common/type-helpers";
import { BigBlueButton } from "components/UIKit/button";
import {archiveProject, unsuspendProject} from "core/state/user/actions";

import "./ArchiveProject.scss";

const ArchiveProjectForm: sBEM<{
  projectId: string;
  onClose: () => void;
  archiveProject: () => {};
  archive: boolean
}> = ({ className, onClose, archiveProject }) => (
  <div className={cx(className, "archive-form")}>
    <BigBlueButton
      className="archive-form__button"
      caption="yes"
      onClick={archiveProject}
    />
    <BigBlueButton
      className="archive-form__button"
      caption="no"
      onClick={onClose}
    />
  </div>
);

export default connect(
  null,
  (dispatch, { projectId, onClose, archive }: { projectId: string; onClose: () => void, archive: boolean }) => ({
    archiveProject: () => {
      if(archive) {
        dispatch(archiveProject(projectId));
      } else {
        dispatch(unsuspendProject(projectId));
      }
      onClose();
    }
  })
)(ArchiveProjectForm);
