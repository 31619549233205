import * as React from "react";
import * as cx from "classnames";

import { sBEM } from "common/type-helpers";
import { Field } from "components/UIKit/field";
import { BigBlueButton } from "components/UIKit/button";

import "./AddMember.scss";
import { webConnect } from "core/redux";
import { FormEvent } from "react";
import { createUser } from "core/state/user/actions";

const UnconnectedAddMemberForm: sBEM<{
  saveChanges: (e: any) => any;
  onClose: () => void;
}> = ({ className, saveChanges, onClose }) => (
  <form
    className={cx(className, "add-member")}
    onSubmit={e => {
      e.preventDefault();
      saveChanges(e);
      onClose();
    }}
  >
    <Field
      className="add-member__field"
      type="text"
      placeholder="Name"
      name="Name"
    />
    <Field
      className="add-member__field"
      type="email"
      placeholder="E-mail"
      name="Email"
    />
    <BigBlueButton
      className="add-member__submit"
      submit
      caption="Add team member"
    />
  </form>
);

const saveChanges = (e: FormEvent<HTMLFormElement>) => {
  const formData = Array.from(e.target as any).reduce(
    (acc: any, input: HTMLInputElement) => {
      acc[input.name] = input.value;
      return acc;
    },
    {}
  );

  return createUser({
    name: formData.Name,
    mail: formData.Email
  });
};

export const AddMemberForm = webConnect(() => null, {
  saveChanges
})(UnconnectedAddMemberForm);
