var React = require('react');

function ButtonArrow (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"nonzero"},React.createElement("g",{"transform":"translate(-807.000000, -2359.000000)"},React.createElement("g",{"transform":"translate(798.000000, 2352.000000)"},React.createElement("path",{"d":"M12.8864063,10.4996713 L20.0062929,10.4996713 C20.5551022,10.4996713 21,10.9475007 21,11.4999262 C21,12.0523517 20.5551022,12.5001811 20.0062929,12.5001811 L12.8862546,12.5001811 L14.7151555,14.2805781 C15.1104992,14.6654369 15.1210639,15.2972457 14.7388083,15.695107 C14.3597801,16.0896092 13.7327098,16.1021533 13.3382076,15.7231251 C13.3366447,15.7216235 13.3350868,15.7201168 13.3335338,15.718605 L9,11.5 L13.3335338,7.28139496 C13.7255396,6.89978546 14.3526787,6.90821333 14.7342882,7.30021913 C14.7358,7.30177213 14.7373067,7.30333008 14.7388083,7.30489296 C15.1210639,7.70275429 15.1104992,8.33456307 14.7151555,8.71942194 L12.8864063,10.4996713 Z"})))));
}

ButtonArrow.displayName = "ButtonArrow";

ButtonArrow.defaultProps = {"width":"12px","height":"9px","viewBox":"0 0 12 9","version":"1.1"};

module.exports = ButtonArrow;

ButtonArrow.default = ButtonArrow;
