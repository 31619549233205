import * as React from "react";

import { Spring } from "react-spring";

import "./Gears.scss";

const radians = (degrees: number) => degrees / 180 * Math.PI;

const polarCoordinate = (radius: number, angle: number) => [
  radius * Math.cos(radians(angle)),
  radius * Math.sin(radians(angle))
];

const generateArc = (radius: number, angleEnd: number, CCW: number) => {
  const to = polarCoordinate(radius, angleEnd);
  return `A${radius},${radius} 1 0 ${CCW} ${to[0]},${to[1]}`;
};

function generateSectorPath(
  pathStart: boolean,
  radius: number,
  start: number,
  end: number
) {
  const startCoord = polarCoordinate(radius, start);
  const startPath = `${(pathStart ? "M" : "L") + startCoord[0]},${
    startCoord[1]
  }`;

  // Arc angles
  const angle = end - start;
  const firstAngle = Math.abs(angle) > 180 ? start + 180 : end;
  const secondAngle = end;

  const CCW = angle > 0 ? 1 : 0;

  // Arcs
  const firstArc = generateArc(radius, firstAngle, CCW);
  const secondArc =
    Math.abs(angle) > 180 ? generateArc(radius, secondAngle, CCW) : "";

  return `${startPath} ${firstArc} ${secondArc}`;
}

function generateRadius(
  pathStart: boolean,
  angle: number,
  innerRadius: number,
  outerRadius: number
) {
  const startCoord = polarCoordinate(innerRadius, angle);
  const startPath = `${(pathStart ? "M" : "L") + startCoord[0]},${
    startCoord[1]
  }`;

  const endCoord = polarCoordinate(outerRadius, angle);
  const endPath = `L${endCoord[0]},${endCoord[1]}`;
  return `${startPath} ${endPath}`;
}

function generateSector(
  start: number,
  end: number,
  innerRadius: number,
  outerRadius: number
) {
  return `${generateSectorPath(true, innerRadius, start, end) +
    generateRadius(false, end, innerRadius, outerRadius) +
    generateSectorPath(false, outerRadius, end, start) +
    generateRadius(false, start, outerRadius, innerRadius)}z`;
}

function generateWireSector(
  start: number,
  end: number,
  innerRadius: number,
  outerRadius: number
) {
  return generateSectorPath(true, outerRadius, start, end);
}

interface Item {
  value: number;
}

export interface CalculatedItem extends Item {
  key: number;
  index: number;
  angle: number;
  prevAngle?: number;
  stat: { min: number; max: number; sum: number };
}

function dataMapper(data: Item[]): CalculatedItem[] {
  const min = data.reduce((acc, x) => Math.min(acc, x.value), Infinity);
  const max = data.reduce((acc, x) => Math.max(acc, x.value), -Infinity);
  const sum = data.reduce((acc, x) => acc + x.value, 0);
  const mul = 360 / sum;

  const stat = {
    sum,
    min,
    max
  };

  return data.map((item, index) => ({
    key: index,
    index,
    ...item,
    angle: mul * item.value,
    stat
  }));
}

const forwardAnimation = { from: 0, to: 1 };
const backwardAnimation = { from: 1, to: 0 };

interface GearProps {
  data: Item[];
  dataKey?: any;
  radius: number;
  innerRadius?: number;

  styler: (item: any) => object;

  before?: React.ReactNode;
  after?: React.ReactNode;

  className?: string;

  springConfig?: object;

  sizeFactor?: number;

  mode: "spin" | "grow";
  wireframe?: boolean;
  reverse?: boolean;

  basisAngle?: (x: number, offset: number) => number;

  growFactor?: (item: any, min: number, max: number) => number;
}

interface State {
  data?: Item[];
  nextData?: Item[];
  rest?: boolean;
  animation: { from: number; to: number };
}

const defaultBaseAngle = () => 0;
const defaultGrowFactor = (item: Item, min: number, max: number) =>
  (item.value - min) / max;

export class GearChart<T> extends React.Component<GearProps, State> {
  static defaultProps = {
    sizeFactor: 2.3,
    wireframe: false,
    innerRadius: 0
  };

  state: State = {
    data: this.props.data,
    nextData: undefined,
    rest: false,
    animation: forwardAnimation
  };

  gears(x: number, offset: number) {
    switch (this.props.mode) {
      case "spin":
        return this.gearsSpin(x, offset);
      case "grow":
        return this.gearsGrow(x, offset);
    }
  }

  generateGear(
    start: number,
    end: number,
    innerRadius: number,
    outerRadius: number
  ) {
    return this.props.wireframe
      ? generateWireSector(start, end, innerRadius, outerRadius)
      : generateSector(start, end, innerRadius, outerRadius);
  }

  gearsSpin(x: number, offset: number) {
    const {
      styler,
      innerRadius = 0,
      radius,
      basisAngle = defaultBaseAngle
    } = this.props;
    const { data = [] } = this.state;
    const normalized = dataMapper(data);

    const startPosition = {
      prevAngle: -90 + basisAngle(x, offset),
      angle: offset && (1 - x) * 360
    };

    const mapped = normalized.reduce((acc, item) => {
      const angle = item.angle * x;
      const prev = acc[acc.length - 1] || startPosition;

      return [
        ...acc,
        {
          ...item,
          angle,
          prevAngle: prev.prevAngle + prev.angle
        }
      ];
    }, []);

    return mapped.map(item => {
      const { key, angle, prevAngle } = item;
      return (
        <path
          key={key}
          d={this.generateGear(
            prevAngle,
            prevAngle + angle,
            innerRadius,
            radius
          )}
          {...styler(item)}
        />
      );
    });
  }

  gearsGrow(x: number, offset: number) {
    const {
      styler,
      innerRadius = 0,
      radius,
      basisAngle = defaultBaseAngle,
      growFactor = defaultGrowFactor
    } = this.props;
    const { data = [] } = this.state;
    const normalized = dataMapper(data);

    const startPosition = { prevAngle: -90 + basisAngle(x, offset), angle: 0 };

    const mapped = normalized.reduce((acc, item) => {
      const prev = acc[acc.length - 1] || startPosition;

      return [
        ...acc,
        {
          ...item,
          prevAngle: prev.prevAngle + prev.angle
        }
      ];
    }, []);

    return mapped.map(item => {
      const { key, angle, prevAngle, stat: { min, max } } = item;
      return (
        <path
          key={key}
          d={this.generateGear(
            prevAngle,
            prevAngle + angle,
            innerRadius,
            innerRadius +
              (radius - innerRadius) * x * growFactor(item, min, max)
          )}
          {...styler(item)}
        />
      );
    });
  }

  componentDidUpdate(prepProps: GearProps, prevState: State) {
    if (prepProps.dataKey !== this.props.dataKey) {
      this.setState({
        nextData: this.props.data
      });
      if (this.state.rest) {
        this.setState({
          animation: backwardAnimation
        });
      }
    }
  }

  onRest = () => {
    this.setState((state): any => {
      const { animation, nextData, rest } = state;

      if (nextData) {
        if (animation === backwardAnimation) {
          return {
            data: nextData,
            nextData: undefined,
            animation: forwardAnimation,
            rest: false
          };
        }
        return {
          animation: backwardAnimation,
          rest: false
        };
      }
      if (!rest) {
        return {
          rest: true
        };
      }
      return null;
    });
  };

  render() {
    const {
      className,
      before,
      after,
      radius,
      springConfig,
      sizeFactor = 2.3
    } = this.props;
    const { animation: { from, to } } = this.state;

    const viewport = radius * sizeFactor;
    const offset = viewport / 2;

    return (
      <svg viewBox={`0 0 ${viewport} ${viewport}`} className={className}>
        <g transform={`translate(${offset}, ${offset})`}>
          {before}
          <Spring
            from={{ x: from }}
            to={{ x: to }}
            onRest={this.onRest}
            config={springConfig as any}
          >
            {({ x }) => {
              const result = this.gears(x, from);
              if (this.props.reverse) {
                result.reverse();
              }
              return result;
            }}
          </Spring>
          {after}
        </g>
      </svg>
    );
  }
}
