export const metaDataSelector = (state: any, id: string, field: string | null) =>
  (field ? (state[id] || {})[field] : state[id] || {}) || {};

export const metaSelector = (
  state: any,
  id: string,
  field: string | null,
  defaultValue: any = []
) => {
  const data = metaDataSelector(state, id, field);
  if (data.error || !data.data) return defaultValue;
  return data.data;
};
