import * as React from "react";
import * as cx from "classnames";

import {
  TitlesFilterForm,
  WebsitesFilterForm
} from "components/header-in-main/components/filter";
import { CloseSvgLink } from "components/UIKit/svglink";

import "./Modal.scss";
import { sBEM } from "common/type-helpers";
import { URLConsumer } from "components/URLConsumer/URLConsumer";
import { AddMemberForm } from "components/account-dashboard/components/add-member";
import {
  MailReceiversForm,
  MailDailyReceiversForm
} from "components/account-dashboard/components/mail-receivers";
import { EditTeamMemberForm } from "components/account-dashboard/components/edit-team-member";
import { Transition } from "react-transition-group";
import Locky from "react-locky";
import { DeleteTeamMemberForm } from "components/account-dashboard/components/delete-team-members";
import {ArchiveProjectForm} from "components/account-list/components/archive-project";

interface PublicProps {
  visible?: boolean;
  animation?: "grow";
  onClose: () => void;
}

export const BaseModal: sBEM<
  {
    mod?: string;
    content: React.ReactNode;
    caption?: any;
  } & PublicProps
> = ({ className, mod, visible, animation, content, onClose, caption }) => (
  <Locky enabled={!!visible}>
    <div
      className={cx(
        className,
        { [`${className}--${mod}`]: !!className && !!mod },
        { [`${className}--visible`]: !!className && !!visible && !animation },
        {
          [`${className}--visible-grow`]:
            !!className && !!visible && animation === "grow"
        },
        "modal",
        { [`modal--${mod}`]: !!mod }
      )}
    >
      {caption && <h2 className="modal__caption" children={caption} />}
      <Transition in={visible} timeout={1000} unmountOnExit>
        {() => content}
      </Transition>
      <CloseSvgLink className="modal__close" onClick={onClose} />
    </div>
  </Locky>
);

export const FilterTitlesModal: sBEM<PublicProps> = props => (
  <URLConsumer>
    {({ projectId }) => (
      <BaseModal
        {...props}
        mod="filter"
        content={
          <TitlesFilterForm
            className="modal__filter"
            projectId={projectId}
            onClose={props.onClose}
          />
        }
      />
    )}
  </URLConsumer>
);

export const FilterWebsitesModal: sBEM<
  PublicProps & { projectId: string }
> = props => (
  <BaseModal
    {...props}
    mod="filter"
    content={
      <WebsitesFilterForm
        className="modal__filter"
        projectId={props.projectId}
        onClose={props.onClose}
      />
    }
  />
);

export const AddMemberModal: sBEM<PublicProps> = props => (
  <BaseModal
    {...props}
    mod="new-member"
    caption="Add new team member"
    content={
      <AddMemberForm className="modal__add-member" onClose={props.onClose} />
    }
  />
);

export const MailReceiversModal: sBEM<PublicProps & { mode: string }> = ({
  mode,
  ...props
}) => (
  <BaseModal
    {...props}
    mod="mail-receivers"
    caption="E-mail receivers"
    content={
      mode === "sentNotices" ? (
        <MailReceiversForm
          className="modal__mail-receivers"
          onClose={props.onClose}
        />
      ) : (
        <MailDailyReceiversForm
          className="modal__mail-receivers"
          onClose={props.onClose}
        />
      )
    }
  />
);

export const EditInformationModal: sBEM<PublicProps & { userId: string }> = ({
  userId,
  ...props
}) => (
  <BaseModal
    {...props}
    mod="edit-information"
    caption="Edit information"
    content={
      <EditTeamMemberForm
        userId={userId}
        onClose={props.onClose}
        className="modal__edit-information"
      />
    }
  />
);

export const DeleteUserModal: sBEM<
  PublicProps & { userId: string }
> = props => (
  <BaseModal
    {...props}
    mod="delete-user"
    caption={
      <React.Fragment>
        Are you sure you want<br />to delete user?
      </React.Fragment>
    }
    content={
      <DeleteTeamMemberForm
        userId={props.userId}
        onClose={props.onClose}
        className="modal__delete-user"
      />
    }
  />
);

export const ArchiveProjectModal: sBEM<
    PublicProps & { projectId: string }
    > = props => (
    <BaseModal
        {...props}
        mod="archive-project"
        caption={
            <React.Fragment>
                Are you sure you want<br />to archive this project?
            </React.Fragment>
        }
        content={
            <ArchiveProjectForm
                archive
                projectId={props.projectId}
                onClose={props.onClose}
                className="modal__archive-project"
            />
        }
    />
);

export const RestoreProjectModal: sBEM<
  PublicProps & { projectId: string }
  > = props => (
  <BaseModal
    {...props}
    mod="archive-project"
    caption={
      <React.Fragment>
        Are you sure you want<br />to restore this project?
      </React.Fragment>
    }
    content={
      <ArchiveProjectForm
        archive={false}
        projectId={props.projectId}
        onClose={props.onClose}
        className="modal__archive-project"
      />
    }
  />
);