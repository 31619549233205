var React = require('react');

function Back (props) {
    return React.createElement("svg",props,React.createElement("g",{"strokeWidth":"1","fillRule":"evenodd","transform":"translate(-9.000000, -10.000000)"},React.createElement("g",{"transform":"translate(8.000000, 10.000000)"},[React.createElement("polygon",{"stroke":"none","fillRule":"nonzero","points":"7.39434347 8.47528163 6.00397225 9.91294219 0.92400726 5.0000738 6.00397225 0.0872054131 7.39434347 1.52486597 3.80093678 5.0000738","key":0}),React.createElement("path",{"fill":"none","d":"M3,5 L13,5","strokeWidth":"2","key":1})])));
}

Back.displayName = "Back";

Back.defaultProps = {"width":"12px","height":"10px","viewBox":"0 0 12 10","version":"1.1"};

module.exports = Back;

Back.default = Back;
