import {getWebsites} from "core/state/websites/selectors";
import {getCountries} from "core/state/meta/selectors";
import {WebsiteMapStat} from "components/dashboard/components/top-websites/TopWebsites";

export interface Country {
  code: string;
  name: string;
}

export type CountryWithValue = Country & { value: number };
type ConnectedData = { list: CountryWithValue[]; values: number[] };
type visit = { countryId: number; visitsFraction: number };

export const getLinksGeoStatistic = (
  state: any,
  projectId: string
): ConnectedData => {
  const projectData = getWebsites(state, projectId);
  const countriesSet = getCountries(state);

  const countries = {};

  projectData.forEach(
    ({visitsCount, visits}: { visitsCount: number; visits: any }) => {
      visits &&
      visits.forEach((visit: visit) => {
        countries[visit.countryId] =
          (countries[visit.countryId] || 0) +
          visit.visitsFraction * visitsCount;
      });
    }
  );

  const keys = Object.keys(countries);
  return {
    list: keys.map(id => ({...countriesSet[id], value: countries[id]})),
    values: keys.map(id => countries[id])
  };
};

export interface WebsiteMapStat {
  host: string;
  visits: number;
  links: number;
}

export const getMapGeoStatistic = (
  state: any,
  projectId: string
): { [key: string]: WebsiteMapStat[] } => {
  const projectData = getWebsites(state, projectId);
  const countriesSet = getCountries(state);

  const countries = {};

  projectData.forEach(
    ({
       visitsCount,
       visits,
       host,
       count
     }: {
      visitsCount: number;
      visits: any;
      host: string;
      count: number;
    }) => {
      visits &&
      visits.forEach((visit: visit) => {
        const code = countriesSet[visit.countryId].code;
        if (!countries[code]) {
          countries[code] = [];
        }
        countries[code].push({
          host,
          links: count,
          visits: Math.round(visit.visitsFraction * visitsCount)
        });
      });
    }
  );

  Object.keys(countries)
    .map(code => {
      countries[code] = countries[code].sort(
        (a: WebsiteMapStat, b: WebsiteMapStat) => a.visits < b.visits
      );
      return code;
    })
    .map(
      code => (
        countries[code] = countries[code]
          .sort((a: WebsiteMapStat, b: WebsiteMapStat) => b.visits - a.visits)
          .splice(0, 5)
      )
    );

  return countries;
};

export const getPeersGeoStatistic = (): ConnectedData => ({
  list: [
    {code: "RU", name: "Russian Federation", value: 1981},
    {code: "US", name: "United States", value: 1814},
    {code: "UA", name: "Ukraine", value: 581},
    {code: "IN", name: "India", value: 44},
    {code: "GB", name: "United Kingdom", value: 21}
  ],
  values: [1981, 1814, 581, 44, 21]
});
