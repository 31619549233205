import * as React from "react";
import * as cx from "classnames";
import {sBEM} from "common/type-helpers";

import {Value} from 'react-powerplug';

import {shortenNumber} from "common/shortenNumbers";
import {GearChart} from "components/dashboard/components/gears/Gears";

import "./Chart.scss";
import {getWebsiteTypes} from "core/state/websites/selectors";
import {webConnect} from "core/redux";
import {WEBSITE_TYPES} from "core/state/websites/state";

const ItoI = (I: any) => I;

interface TableHeader {
  cols: {
    key: string;
    width: number;
    right?: boolean;
    display?: (x: any) => any;
  }[];
}

interface TableElement {
  type: number;
  count: number;
}

type TableData = TableElement[];

const DataTable: React.SFC<{
  titleHead: TableHeader;
  tableData: TableData;
  hovered: number,
  setHover: (a:number) => any
}> = ({titleHead, tableData, hovered, setHover}) => (
  <table className="chart__table">
    <tbody>
    {tableData.map((tableRow, index) => (
      <tr
        className={cx("chart__row", tableRow.type === hovered && "chart__row--selected", hovered && "chart__row--rest")}
        key={index}
        onMouseEnter={() => setHover(tableRow.type)}
        onMouseLeave={() => setHover(0)}
        >
        {titleHead.cols.map(col => (
          <td
            key={col.key}
            className={cx(
              "chart__col",
              {"chart__col--right": col.right},
              {["chart__col--" + col.key]: true}
            )}
            style={{width: `${col.width}%`}}
          >
            {(col.display || ItoI)(tableRow[col.key])}
          </td>
        ))}
      </tr>
    ))}
    </tbody>
  </table>
);

export const BaseChart: sBEM<{
  sectionTitle: string;
  sectionNote: string;
  titleHead: TableHeader;
  tableData: TableData;
  rawData: any;
}> = ({className, sectionTitle, sectionNote, titleHead, tableData, rawData}) => (
  <div className={cx(className, "chart")}>
    <h3 className={cx("chart__title")} children={sectionTitle}/>
    <p className={cx("chart__note")} children={sectionNote}/>
    <Value initial={0}>
      {({value: hovered, setValue: setHover}) => (
        <React.Fragment>
          <GearChart
            dataKey={rawData}
            data={tableData.map(item => ({...item, value: item.count}))}
            innerRadius={45}
            radius={100}
            reverse
            className="chart__gear"
            mode="grow"
            styler={value => ({
              className: `chart__gear-arc`,
              onMouseOver: () => setHover(value.type),
              onMouseOut: () => setHover(0),
              style: {
                fill: value.color,
                stroke: value.type===hovered ? '#000' : 'none',
                zIndex: value.type===hovered ? 100 : 0,
                position: 'relative',
                filter: "url(#gearDropShadow)"
              }
            })}
            growFactor={item => 1 - item.index * 0.15}
            basisAngle={(x, offset) => (offset ? 60 * (1 - x) : -60 * (1 - x) - 20)}
            before={
              <defs>
                <filter
                  id="gearDropShadow"
                  x="-50%"
                  y="-50%"
                  width="200%"
                  height="200%"
                >
                  <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0"/>
                  <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3"/>
                  <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                </filter>
              </defs>
            }
            after={
              <React.Fragment>
                <circle
                  x={0}
                  y={0}
                  r={48}
                  style={{stroke: "rgba(0,0,0,0.2)", strokeWidth: 6, fill: "none"}}
                />
                <circle x={0} y={0} r={45} style={{fill: "#FFF"}}/>
              </React.Fragment>
            }
          />
          <DataTable titleHead={titleHead} tableData={tableData} hovered={hovered} setHover={setHover}/>
        </React.Fragment>
      )}
    </Value>
  </div>
);

const typeToString = (type: number) => WEBSITE_TYPES[type];

const titleHead: TableHeader = {
  cols: [
    {
      width: 13,
      key: "color",
      display: color => (
        <span
          className="chart__color-data"
          style={{backgroundColor: color}}
        />
      )
    },
    {
      width: 70,
      key: "type",
      display: typeToString
    },
    {
      key: "count",
      width: 17,
      right: true,
      display: shortenNumber
    }
  ]
};

const colors = [
  "#4E88E4",
  "#29A0E4",
  "#39C6DE",
  "#42C9C6",
  "#58DCBE",
  "#60DA8E"
];

export const SortedWebsitesChart: sBEM<{ data: TableData }> = props => (
  <BaseChart
    {...props}
    sectionTitle="website types"
    sectionNote={"Links"}
    titleHead={titleHead}
    rawData={props.data}
    tableData={props.data
      .slice()
      .sort((b, a) => a.count - b.count)
      .map((item, index) => ({
        ...item,
        color: colors[index % colors.length]
      }))}
  />
);

export const WebsitesChart = webConnect(
  (state, {projectId}: { projectId: string }) => ({
    data: getWebsiteTypes(state, projectId)
  })
)(SortedWebsitesChart);
