import * as React from "react";
import * as cx from "classnames";
import { sBEM } from "common/type-helpers";

import "./RadioButton.scss";
import { reactType } from "common/reactType";

interface Props {
  id?: string;
  name?: string;
  caption: string;
  checked?: boolean;
  Component?: React.ComponentClass;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const BaseRadioButton: sBEM<Props> = ({
  className,
  id,
  name,
  caption,
  checked,
  onChange,
  Component = reactType(<p />)
}) => (
  <Component className={cx(className, "radio")}>
    <input
      id={id}
      className="radio__input"
      type="radio"
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id} className={"radio__caption"} children={caption} />
  </Component>
);

const ListRadioButton: sBEM<Props> = props => (
  <BaseRadioButton {...props} Component={reactType(<li />)} />
);

export const RadioButton: sBEM<Props> = props => <ListRadioButton {...props} />;
