import * as React from "react";
import * as cx from "classnames";

import "./Field.scss";
import { sBEM } from "common/type-helpers";

interface Field {
  icon?: string;
  mod?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BaseField: sBEM<Field> = ({
  className,
  icon,
  mod,
  type,
  placeholder,
  defaultValue,
  name,
  onChange
}) => (
  <p
    className={cx(className, "field", {
      [`field--${mod}`]: !!mod
    })}
  >
    <input
      className={cx("field__input", { [`field__input--${icon}`]: !!icon })}
      type={type}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  </p>
);

export const Field: sBEM<Field> = props => (
  <BaseField {...props} icon={undefined} />
);

export const IconField: sBEM<Field> = props => (
  <BaseField {...props} icon="icon" />
);
