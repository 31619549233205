import * as React from "react";
import * as cx from "classnames";

import Logotype from "./components/logotype";
import UserNavigation from "./components/usernavigation";

import "./Header.scss";
import { sBEM } from "common/type-helpers";

const BaseHeader: sBEM<{
  mod?: string;
  note?: React.ReactNode;
  center?: React.ReactNode;
}> = ({ className, mod, note, center }) => (
  <header
    className={cx(
      className,
      { [`${className}--${mod}`]: !!mod && !!className },
      "header",
      {
        [`header--${mod}`]: !!mod
      }
    )}
  >
    <div className="header__wrapper">
      <div className="header__right">
        <Logotype className="header__logotype" href="/" />
        {note}
      </div>
      {center}
      <div className="header__left">
        <UserNavigation className="header__navigation" />
      </div>
    </div>
  </header>
);

const Note = () => <p className="header__note">You Create it. We Protect it</p>;

export const Header: sBEM<{
  mod?: string;
}> = props => <BaseHeader {...props} note={<Note />} />;

const Center = () => (
  <div className="header__center">
    <p className="header__master" children="Master account" />
  </div>
);

export const MasterHeader: sBEM<{
  mod?: string;
}> = props => <BaseHeader {...props} center={<Center />} />;
