import * as React from 'react';
import * as cx from "classnames";
import {Toggle} from "react-powerplug";
import {BigBlueButton} from "components/UIKit/button";
import {DownloadTooltip} from "components/tooltip";
import {webConnect} from "core/redux";
import {isDownloadInProgress} from "core/state/meta/selectors";
import {CircleIndicator} from "components/header-in-main/components/circle-indicator";

const UnconnectedDownloadButton: React.SFC<{ downloading: number }> = ({downloading}) => (
  <div className="site-navigation__download-wrapper">
    <Toggle>
      {({on, toggle}) => (
        <React.Fragment>
          <BigBlueButton
            className="site-navigation__download"
            caption={
              downloading
                ? <div className="download__caption">
                    <CircleIndicator className="download__spinner" />
                    Generating
                  </div>
                : "Download report"
            }
            disabled={!!downloading}
            onClick={toggle}
          />
          <DownloadTooltip
            className={cx("site-navigation__tooltip")}
            visible={on}
          />
        </React.Fragment>
      )}
    </Toggle>
  </div>
);

export const DownloadButton = webConnect(
  state => ({
    downloading: isDownloadInProgress(state)
  })
)(UnconnectedDownloadButton);