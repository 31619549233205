var React = require('react');

function Plus (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"evenodd"},React.createElement("g",{"transform":"translate(-7.000000, -7.000000)"},React.createElement("path",{"d":"M13,11 L16,11 C16.5522847,11 17,11.4477153 17,12 C17,12.5522847 16.5522847,13 16,13 L13,13 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,13 L8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 L11,11 L11,8 C11,7.44771525 11.4477153,7 12,7 C12.5522847,7 13,7.44771525 13,8 L13,11 Z"}))));
}

Plus.displayName = "Plus";

Plus.defaultProps = {"width":"10px","height":"10px","viewBox":"0 0 10 10","version":"1.1"};

module.exports = Plus;

Plus.default = Plus;
