import * as React from "react";
import * as cx from "classnames";

import "./AccountTeam.scss";
import { sBEM } from "common/type-helpers";
import {
  SmallBlueButton,
  SmallLightButton,
  SmallWhiteButton
} from "components/UIKit/button";
import { uid } from "core/uid";

import SvgPen from "svg-react-loader!./assest/pen.svgx";
import SvgPlus from "svg-react-loader!./assest/plus.svgx";
import { PersonMail } from "core/state/user/reducer";

type listTeam = PersonMail[];

interface Props {
  accountTeamTitle: string;
}

interface PublicProps {
  listTeam: listTeam;
  onClickAdd: () => any;
  onClickEdit: (id: string) => any;
  onClickDelete: (id: string) => any;
}

export const BaseAccountTeam: sBEM<Props & PublicProps> = ({
  className,
  accountTeamTitle,
  listTeam,
  onClickAdd,
  onClickEdit,
  onClickDelete
}) => (
  <section className={cx(className, "account-team")}>
    <div className={"account-team__title-wrapper"}>
      <h3 className={"account-team__title"} children={accountTeamTitle} />
      <SmallBlueButton
        className={"account-team__add"}
        caption={"Add"}
        svg={<SvgPlus />}
        onClick={onClickAdd}
      />
    </div>
    <ul className={"account-team__list"}>
      {listTeam.map(item => (
        <li className={"account-team__item"} key={uid(item)}>
          <span className={"account-team__id"} children={item.id} />
          <div className={"account-team__info-wrapper"}>
            <span className={"account-team__name"} children={item.name} />
            <span className={"account-team__mail"} children={item.mail} />
          </div>
          <SmallWhiteButton
            className={"account-team__delete"}
            caption={"Delete"}
            onClick={() => onClickDelete(item.id)}
          />
          <SmallLightButton
            className={"account-team__edit"}
            caption={"Edit"}
            svg={<SvgPen />}
            onClick={() => onClickEdit(item.id)}
          />
        </li>
      ))}
    </ul>
  </section>
);

export const AccountTeam: sBEM<PublicProps> = props => (
  <BaseAccountTeam {...props} accountTeamTitle={"My team"} />
);
