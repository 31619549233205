import {Action, handleActions} from "redux-actions";
import produce from "immer"

import state from "./state";
import {
  ARCHIVE_PROJECT, UNSUSPEND_PROJECT,
  USER_ACCOUNT_INFO,
  USER_AUTHENTICATED,
  USER_ERROR,
  USER_SELECT
} from "core/state/user/actions";
import {RESET_STORE} from "core/state/actions";

export type UserState = typeof state;
const user0 = state.details["0"];
export type SiteImpersonate = typeof user0.canImpersonate[0];
export type PersonMail = typeof user0.emails[0];

const user = handleActions(
  {
    [USER_AUTHENTICATED]: (state: UserState): Partial<UserState> => ({
      ...state,
      authenticated: true,
      authenticationFailed: false
    }),

    [USER_ACCOUNT_INFO]: (
      state: UserState,
      action: Action<any>
    ): Partial<UserState> => ({
      ...state,
      details: {
        ...state.details,
        [action.payload.id]: action.payload
      },
      masterUser: state.masterUser || action.payload.id,
      selectedUser: state.selectedUser || action.payload.id
    }),

    [USER_SELECT]: (
      state: UserState,
      action: Action<string>
    ): Partial<UserState> => ({
      ...state,
      selectedUser: action.payload
    }),

    [USER_ERROR]: (
      state: UserState,
      action: Action<{ error: boolean }>
    ): Partial<UserState> => ({
      ...state,
      authenticated: false,
      authenticationFailed: !!action.error
    }),

    [ARCHIVE_PROJECT]: (
      state: UserState,
      action: Action<string>
    ): Partial<UserState> => (
      produce(state, draftState => {
          const can = draftState.details[draftState.masterUser].canImpersonate;
          const site = can.find(({id}: { id: string }) => id === action.payload);
          if (site) {
            site.archived = true
          }
        }
      )
    ),

    [UNSUSPEND_PROJECT]: (
      state: UserState,
      action: Action<string>
    ): Partial<UserState> => (
      produce(state, draftState => {
          const can = draftState.details[draftState.masterUser].canImpersonate;
          const site = can.find(({id}: { id: string }) => id === action.payload);
          if (site) {
            site.archived = false
          }
        }
      )
    ),

    [RESET_STORE]: (state: UserState): Partial<UserState> => ({
      ...state,
      details: undefined,
      selectedUser: undefined
    })
  },
  state
);

export {user};
