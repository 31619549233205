import * as React from "react";

import Header from "components/header";
import {HeaderInMainAccount} from "components/header-in-main";

import "./Account.scss";

import {AccountDashboard} from "components/account-dashboard";
import {
    AddMemberModal,
    EditInformationModal,
    MailReceiversModal,
    DeleteUserModal
} from "components/modal";
import {Toggle, Value} from "react-powerplug";
import {gearbox} from "react-gearbox";

const nop = () => ({});

const AccountState = gearbox({
    notifications: <Value initial="" children={nop}/>,
    add: <Toggle children={nop}/>,
    edit: <Value initial="" children={nop}/>,
    gdelete: <Value initial="" children={nop}/>
});

type GV<T> = { value: T; setValue: (t: T) => any };
type GT<T = boolean> = { on: T; toggle: () => any };

type GearTypes = {
    notifications: GV<string>;
    add: GT;
    edit: GV<string>;
    gdelete: GV<string>;
};

const Account: React.SFC = () => (
    <AccountState render>
        {({
              notifications: {
                  value: showEmailReceivers,
                  setValue: toggleEmailReceivers
              },
              add: {on: showAddMembers, toggle: toggleAddMembers},
              edit: {value: showEditMembers, setValue: toggleEditMembers},
              gdelete: {value: showDeleteMembers, setValue: toggleDeleteMembers}
          }: GearTypes) => (
            <div className="page">
                <Header className="page__header"/>
                <main className="page__main">
                    <HeaderInMainAccount
                        className="page__navigation"
                        titlePage="Personal account"
                        projectId=""
                    />
                    <AccountDashboard
                        className="page__dashboard"
                        openAddMember={toggleAddMembers}
                        openEditMember={toggleEditMembers}
                        openEmailReceivers={toggleEmailReceivers}
                        openDeleteMember={toggleDeleteMembers}
                    />
                </main>
                <AddMemberModal
                    className="page__modal"
                    visible={!!showAddMembers}
                    animation="grow"
                    onClose={toggleAddMembers}
                />
                <MailReceiversModal
                    className="page__modal"
                    visible={!!showEmailReceivers}
                    mode={showEmailReceivers}
                    animation="grow"
                    onClose={() => toggleEmailReceivers("")}
                />
                <EditInformationModal
                    className="page__modal"
                    visible={!!showEditMembers}
                    userId={showEditMembers}
                    animation="grow"
                    onClose={() => toggleEditMembers("")}
                />
                <DeleteUserModal
                    className="page__modal"
                    visible={!!showDeleteMembers}
                    userId={showDeleteMembers}
                    animation="grow"
                    onClose={() => toggleDeleteMembers("")}
                />
            </div>
        )}
    </AccountState>
);

export default Account;
