import * as React from "react";
import * as cx from "classnames";

import "./AccountTime.scss";
import { sBEM } from "common/type-helpers";
import { RadioButton } from "components/UIKit/radio";
import timeZones from "./time-zones";
import { webConnect } from "core/redux";
import { setTimeformat, setTimezone } from "core/state/user/actions";
import { getCurrentUserDetails } from "core/state/user/selectors";

interface DataZone {
  value: number;
  text: string;
}

interface DataFormat {
  id: number;
  format: string;
}

type listZone = DataZone[];
type formatZone = DataFormat[];

interface Props {
  accountTimeZoneTitle: string;
  accountTimeFormatTitle: string;
  listZone: listZone;
  formatZone: formatZone;
  method?: string;
  action?: string;

  timeZone: number;
  timeFormat: number;

  onTimeZoneChange: (n: number) => any;
  onTimeFormatChange: (n: number) => any;
}

export const BaseAccountTime: sBEM<Props> = ({
  className,
  accountTimeZoneTitle,
  accountTimeFormatTitle,
  listZone,
  formatZone,
  method,
  action,
  timeZone,
  timeFormat,
  onTimeZoneChange,
  onTimeFormatChange
}) => (
  <form
    className={cx(className, "account-time")}
    method={method}
    action={action}
  >
    <p className={"account-time__note"} children={accountTimeZoneTitle} />
    <select
      className={"account-time__select"}
      value={timeZone}
      onChange={e => onTimeZoneChange(+e.target.value)}
    >
      {listZone
        .filter(({ value }) => value === Math.round(value))
        .map(item => (
          <option
            className={"account-time__item"}
            key={item.value}
            value={item.value}
            children={item.text}
          />
        ))}
    </select>
    <p className={"account-time__note"} children={accountTimeFormatTitle} />
    <ul className={"account-time__list"}>
      {formatZone.map(item => (
        <RadioButton
          className={"account-time__item"}
          key={item.id}
          id={"radio-id-" + item.id}
          name={"timeZone"}
          caption={item.format}
          checked={item.id === timeFormat}
          onChange={e => e.target.value && onTimeFormatChange(item.id)}
        />
      ))}
    </ul>
  </form>
);

const Format = [
  {
    id: 0,
    format: "24h Format"
  },
  {
    id: 1,
    format: "12h Format"
  }
];

const UnconnectedAccountTime: sBEM<{
  timeZone: number;
  timeFormat: number;
  onTimeZoneChange: (n: number) => any;
  onTimeFormatChange: (n: number) => any;
}> = props => (
  <BaseAccountTime
    {...props}
    accountTimeZoneTitle={"Time zone"}
    accountTimeFormatTitle={"Time format"}
    listZone={timeZones}
    formatZone={Format}
  />
);

export const AccountTime = webConnect(
  state => ({
    timeZone: +getCurrentUserDetails(state.user).timeZone,
    timeFormat: +getCurrentUserDetails(state.user).timeFormat
  }),
  {
    onTimeZoneChange: setTimezone,
    onTimeFormatChange: setTimeformat
  }
)(UnconnectedAccountTime);
