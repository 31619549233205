export default {
  "0": {
    loading: false,
    error: false,
    data: [{ id: "2628", name: "Agents of S.H.I.E.L.D." }]
  },
  "5": {
    loading: false,
    error: false,
    data: [
      { id: "2628", name: "Agents of S.H.I.E.L.D." },
      { id: "4077", name: "Grey's Anatomy" },
      { id: "4117", name: "Lost" },
      { id: "4138", name: "Once Upon a Time" },
      { id: "4163", name: "Scandal" },
      {
        id: "13331",
        name: "How to Get Away with Murder"
      },
      { id: "21086", name: "Criminal Minds" },
      { id: "586770", name: "Castle" },
      {
        id: "657363",
        name: "American Crime"
      },
      { id: "657366", name: "Code Black" },
      { id: "657367", name: "Desperate Housewives" },
      {
        id: "658896",
        name: "American Housewife"
      },
      { id: "659225", name: "Marvelís Inhumans" },
      {
        id: "659254",
        name: "Kevin Saves the World"
      },
      { id: "659298", name: "Marvelís Runaways" },
      { id: "659314", name: "Alone Together " },
      {
        id: "659369",
        name: "For The People S01"
      },
      { id: "659376", name: "Siren" },
      { id: "659377", name: "Roseanne 2018" },
      {
        id: "659384",
        name: "The Crossing"
      }
    ]
  }
};
