var React = require('react');

function Triangle (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"evenodd","transform":"translate(-1237.000000, -1089.000000)"},React.createElement("polygon",{"points":"1240 1093 1237 1089 1243 1089"})));
}

Triangle.displayName = "Triangle";

Triangle.defaultProps = {"width":"6px","height":"4px","viewBox":"0 0 6 4","version":"1.1"};

module.exports = Triangle;

Triangle.default = Triangle;
