var React = require('react');

function Arrow (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"evenodd","transform":"translate(-1092.000000, -122.000000)"},React.createElement("polygon",{"points":"1095 126 1092 122 1098 122"})));
}

Arrow.displayName = "Arrow";

Arrow.defaultProps = {"width":"6px","height":"4px","viewBox":"0 0 6 4","version":"1.1"};

module.exports = Arrow;

Arrow.default = Arrow;
