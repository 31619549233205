import {put, takeLatest, call, all, select} from "redux-saga/effects";
import {
  ARCHIVE_PROJECT, UNSUSPEND_PROJECT,
  USER_ACCOUNT_INFO,
  USER_AUTHENTICATED,
  USER_DELETE,
  USER_EDIT,
  USER_ERROR,
  USER_GET_ACCOUNT,
  USER_LOGIN,
  USER_LOGOUT
} from "./actions";
import {SagaIterator} from "redux-saga";

import {backend} from "core/config";
import {RESET_STORE, INITIALIZE} from "core/state/actions";
import {inJSON} from "common/inFormData";
import {fetch} from "common/fetch";
import {
  SET_TIMEFORMAT,
  SET_TIMEZONE,
  USER_CREATE,
  USER_SELECT,
  USER_SET_BLOCK_NOTIFICATION,
  USER_SET_NOTIFICATION
} from "core/state/user/actions";
import {
  getCurrentClientId,
  getEmails,
  getUserNotifications
} from "core/state/user/selectors";

const fetchAccountsInfo = (clientId: string) =>
  clientId
    ? fetch(`${backend}/${clientId}/profile/info`).then(data => data.json())
    : fetch(`${backend}/profile/info`).then(data => data.json());

const authorizeUser = (UserName: string, Password: string) =>
  fetch(`${backend}/Accounts/Login`, {
    method: "POST",
    body: inJSON({UserName, Password}),
    headers: {
      "content-type": "application/json"
    }
  });

const userLogout = () => fetch(`${backend}/accounts/logout`);

const createUser = (clientId: string, FullName: string, Email: string) =>
  fetch(`${backend}/${clientId}/profile/email`, {
    method: "POST",
    body: inJSON([{FullName, Email}]),
    headers: {
      "content-type": "application/json"
    }
  });

const deleteUser = (clientId: string, id: string) =>
  fetch(`${backend}/${clientId}/profile/email/${id}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json"
    }
  });

const editUser = (
  clientId: string,
  payload: { id: string; name: string; mail: string }
) =>
  fetch(`${backend}/${clientId}/profile/email`, {
    method: "POST",
    body: inJSON([
      {Id: payload.id, FullName: payload.name, Email: payload.mail}
    ]),
    headers: {
      "content-type": "application/json"
    }
  });

const changeTime = (
  clientId: string,
  payload: { TimeZone?: number; Format?: number }
) =>
  fetch(`${backend}/${clientId}/profile/timeFormat`, {
    method: "POST",
    body: inJSON(payload),
    headers: {
      "content-type": "application/json"
    }
  });

export const changeNotifications = (clientId: string, payload: any) =>
  fetch(`${backend}/${clientId}/profile/email`, {
    method: "POST",
    body: inJSON(payload),
    headers: {
      "content-type": "application/json"
    }
  });

export const changeUserLevelNotifications = (
  clientId: string,
  SendNoticeOnNewCopy: boolean,
  DailyReports: boolean
) =>
  fetch(`${backend}/${clientId}/profile/notificationSettings`, {
    method: "POST",
    body: inJSON({SendNoticeOnNewCopy, DailyReports}),
    headers: {
      "content-type": "application/json"
    }
  });

export const archiveUser = (clientId: string) =>
  fetch(`${backend}/clients/archive/${clientId}`, {
    method: "POST",
    headers: {
      "content-type": "application/json"
    }
  });

export const unsuspendUser = (clientId: string) =>
  fetch(`${backend}/clients/restore/${clientId}`, {
    method: "POST",
    headers: {
      "content-type": "application/json"
    }
  });

function* userLogin_saga(action: {
  type: string;
  payload: { login: string; password: string };
}): SagaIterator {
  const {payload} = action;

  try {
    yield put({type: USER_ERROR, error: false});
    yield call(authorizeUser, payload.login, payload.password);
    yield put({type: USER_GET_ACCOUNT});
  } catch (e) {
    yield put({type: USER_ERROR, error: e, location: "userLogin_saga"});
  }
}

function* getAccountsInfo_saga() {
  try {
    const uid = yield select(getCurrentClientId);
    const data = yield call(fetchAccountsInfo, uid);
    yield put({type: USER_ACCOUNT_INFO, payload: data});
    yield put({type: USER_AUTHENTICATED});
  } catch (e) {
    yield put({type: USER_ERROR, error: e, location: "getAccountsInfo_saga"});
  }
}

function* userLogout_saga() {
  yield put({type: USER_ERROR});
  yield call(userLogout);
}

function* resetState_saga() {
  yield put({type: RESET_STORE});
}

function* userSelect_saga() {
  yield put({type: USER_GET_ACCOUNT});
}

function* addUser_saga(action: {
  type: string;
  payload: { name: string; mail: string };
}) {
  const uid = yield select(getCurrentClientId);
  yield call(createUser, uid, action.payload.name, action.payload.mail);
  yield put({type: USER_GET_ACCOUNT});
}

function* deleteUser_saga(action: { type: string; payload: { id: string } }) {
  const uid = yield select(getCurrentClientId);
  yield call(deleteUser, uid, action.payload.id);
  yield put({type: USER_GET_ACCOUNT});
}

function* editUser_saga(action: {
  type: string;
  payload: { id: string; name: string; mail: string };
}) {
  const uid = yield select(getCurrentClientId);
  yield call(editUser, uid, action.payload);
  yield put({type: USER_GET_ACCOUNT});
}

function* setTimezone_saga(action: { type: string; payload: number }) {
  const uid = yield select(getCurrentClientId);
  yield call(changeTime, uid, {TimeZone: action.payload});
  yield put({type: USER_GET_ACCOUNT});
}

function* setTimeformat_saga(action: { type: string; payload: number }) {
  const uid = yield select(getCurrentClientId);
  yield call(changeTime, uid, {Format: action.payload});
  yield put({type: USER_GET_ACCOUNT});
}

function* setUserNotifications_saga(action: {
  type: string;
  payload: {
    changes: { id: string; mail: string; flag: boolean }[];
    option: "SendNoticeOnNewCopy" | "DailyReports";
  };
}) {
  const data = yield select(getEmails);

  const pickData = (id: string) => {
    const line = data.find((u: any) => u.id === id);
    return line
      ? {
        Id: line.id,
        Email: line.mail,
        FullName: line.name,
        SendNoticeOnNewCopy: line.sentNotices,
        DailyReports: line.sentDailyReports
      }
      : {};
  };

  const uid = yield select(getCurrentClientId);
  yield call(
    changeNotifications,
    uid,
    action.payload.changes.map(ch => ({
      ...pickData(ch.id),
      [action.payload.option]: ch.flag
    }))
  );
  yield put({type: USER_GET_ACCOUNT});
}

function* setUserLevelNotification(action: {
  type: string;
  payload: {
    key: string;
    flag: boolean;
  };
}) {
  const settings = yield select(getUserNotifications);
  const newSettings = {
    ...settings,
    [action.payload.key]: action.payload.flag
  };
  const uid = yield select(getCurrentClientId);
  yield call(
    changeUserLevelNotifications,
    uid,
    newSettings.sentNotices,
    newSettings.sentDailyReports
  );
  yield put({type: USER_GET_ACCOUNT});
}

function* archiveProject_saga(action: {
  type: string;
  payload: string
}) {
  yield call(archiveUser, action.payload);
  yield put({type: USER_GET_ACCOUNT});
}

function* unsuspendProject_saga(action: {
  type: string;
  payload: string
}) {
  yield call(unsuspendUser, action.payload);
  yield put({type: USER_GET_ACCOUNT});
}

export function* userSaga(): SagaIterator {
  yield all([
    takeLatest(USER_LOGIN, userLogin_saga),
    takeLatest(USER_LOGOUT, userLogout_saga),
    takeLatest(USER_GET_ACCOUNT, getAccountsInfo_saga),
    takeLatest(USER_ERROR, resetState_saga),
    takeLatest(INITIALIZE, getAccountsInfo_saga),
    takeLatest(USER_SELECT, userSelect_saga),
    takeLatest(USER_CREATE, addUser_saga),
    takeLatest(USER_EDIT, editUser_saga),
    takeLatest(USER_DELETE, deleteUser_saga),

    takeLatest(SET_TIMEZONE, setTimezone_saga),
    takeLatest(SET_TIMEFORMAT, setTimeformat_saga),

    takeLatest(USER_SET_NOTIFICATION, setUserNotifications_saga),
    takeLatest(USER_SET_BLOCK_NOTIFICATION, setUserLevelNotification),

    takeLatest(ARCHIVE_PROJECT, archiveProject_saga),
    takeLatest(UNSUSPEND_PROJECT, unsuspendProject_saga),
  ]);
}
