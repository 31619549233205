import * as React from "react";
import * as cx from "classnames";

import {MemoizedFlow} from "react-memoize";
import {SortSvgLink} from "components/UIKit/svglink/index";

import "./TableData.scss";
import {sBEM} from "common/type-helpers";
import {StrollableContainer, StrollerState} from "react-stroller";
import {Value} from "react-powerplug";
import {DropDown} from "components/dashboard/components/dropDown";
import {uid} from "core/uid";


const ItoI = (I: any) => I;

const Pagination: React.SFC<{
  count: number;
  selected: number;
  onPageSelect: (index: number) => void;
}> = ({count = 0, selected, onPageSelect}) => (
  <ol className="table-data__pagination-list">
    {Array(Math.ceil(count / 10))
      .fill(1)
      .map((x, index) => (
        <li className="table-data__pagination-item" key={index}>
          <button
            className={cx("table-data__pagination-button", {
              "table-data__pagination-button--active": selected === index
            })}
            onClick={() => onPageSelect(index)}
          >
            {index * 10} - {(index + 1) * 10}
          </button>
        </li>
      ))}
  </ol>
);

interface TableHeaderCol {
  key?: string;
  width: number;
  right?: boolean;
  content?: string;
  display?: (x: any) => any;
}

export interface TableHeader {
  cols: TableHeaderCol[];
}

export interface TableRowNumeric {
  [key: string]: number;
}

export interface TableRow {
  [key: string]: number | string | boolean;
}

type TableRows = TableRow[];

export interface TableGroup {
  header?: React.ReactNode;
  items: TableRows;
};

export interface TableGroups {
  [key: string]: TableGroup;
};

export interface TableInterface {
  itemsCount: number;

  onPageSelect: (n: number) => void;
  onSortSelect: (index: number) => void;
  sortedBy: number;
  sortOrder: number;
  currentPage: number;
}

export interface PublicProps {
  right?: { [key: string]: boolean };
  counter?: boolean;
  modTable?: string;
  titleHead: TableHeader;
  tableFooter?: React.ReactNode;
  isSelected?: (col: any) => boolean;

  hidden?: boolean;
  withoutDataLink?: boolean;
  absoluteCol?: (item: TableRow) => JSX.Element;

  groupControl?: boolean;
  defaultGroup?: string;
}

const StrollBar = () => <div className="table-data__scrollbar"/>;

const pickDataFrom = (tableRows: TableGroups, tableData: TableRows, groupControl: boolean, selectedGroup: string, defaultGroup: string) => {
  if (selectedGroup === defaultGroup) {
    return [{
      key: '_',
      row: {
        items: tableData
      }
    }]
  }

  return Object
    .keys(tableRows)
    .filter(key => tableRows[key].items.length > 0)
    .filter(key => !groupControl || key === selectedGroup || selectedGroup === defaultGroup)
    .map(key => ({key, row: tableRows[key]}))
};

function getDataWindow<T>(tableData: T[], height: number, scrollTop: number, clientHeight: number) {
  const n = Math.ceil(clientHeight / height);
  const start = Math.max(0, Math.floor(scrollTop / height));
  const n2 = Math.ceil(n / 2);
  const dataStart = Math.max(0, start - n2);
  return (fn: (a: {
    data: T[],
    start: number,
    n: number,
  }) => React.ReactNode) => fn({
    data: tableData.slice(dataStart, dataStart + n + n),
    start: dataStart,
    n: n * 2,
  })
}

class PureBlocker extends React.Component {
  shouldComponentUpdate() {
    //   if (this.props.children instanceof React.Component){
    //     var a = this.props.children!;
    //     if ( this.props.children) {
    //       return (this.props.children).shouldComponentUpdate();
    //     }
        
    // }
    return false;
  }

  render() {
    return this.props.children;
  }
}

export const BaseTableData: sBEM<TableInterface &
  PublicProps &
  { tableRows: TableGroups, tableData: TableRows }> = ({
                                                         className,
                                                         hidden,
                                                         counter,
                                                         modTable,
                                                         titleHead,
                                                         tableRows,
                                                         tableData,
                                                         isSelected,
                                                         tableFooter,
                                                         withoutDataLink,
                                                         absoluteCol,
                                                         itemsCount,
                                                         onPageSelect,
                                                         onSortSelect,
                                                         sortedBy,
                                                         sortOrder,
                                                         currentPage,
                                                         groupControl,
                                                         defaultGroup
                                                       }) => {
  const groups = [defaultGroup || "", ...Object.keys(tableRows)];
  return (
    <Value initial={defaultGroup || ""}>
      {({value: selectedGroup, setValue: changeGroup}) => (
        <div
          className={cx(
            className,
            "table-data",
            {
              "table-data--counter": counter
            },
            {"table-data--hidden": hidden},
            {[`table-data--${modTable}`]: !!modTable}
          )}
          style={{counterReset: `item ${currentPage * 10}`}}
        >
          <div className="table-data__header">
            <div className="table-data__header-row">
              {titleHead.cols.map(
                (col, index) =>
                  index === 0 ? (
                    groupControl
                      ? <DropDown
                        selected={selectedGroup}
                        onChange={({name}) => changeGroup(name)}
                        options={groups.map(name => ({name}))}
                        style={{width: `${col.width}%`}}
                        className="table-data__title"
                      />
                      : <div
                        key={index}
                        className="table-data__title"
                        children={col.content}
                        style={{width: `${col.width}%`}}
                      />
                  ) : (
                    <div
                      key={index}
                      className={cx(
                        "table-data__head",
                        "table-data__head--sortable",
                        {"table-data__head--right": col.right},
                        {"table-data__head--active": index === sortedBy}
                      )}
                      style={{width: `${col.width}%`}}
                      onClick={() => onSortSelect(index)}
                    >
                      {col.content}
                      <SortSvgLink
                        className="table-data__sort"
                        selected={index === sortedBy}
                        order={index === sortedBy && sortOrder}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="table-data__main">
            <StrollableContainer bar={StrollBar} draggable scrollKey={tableRows}>
              {
                pickDataFrom(tableRows, tableData, !!groupControl, selectedGroup, defaultGroup || '')
                  .map(({key, row}, index, groups) => {
                    const tableData = row.items;
                    const keys = tableData && tableData[0] && Object.keys(tableData[0]);
                    const LINE_HEIGHT = 50;
                    return (
                      <div
                        data-table-group={key}
                        style={{
                          height: tableData.length * LINE_HEIGHT,
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <StrollerState>
                          {({clientHeight, scrollTop}) => (
                            getDataWindow(tableData, LINE_HEIGHT, scrollTop, clientHeight)
                            (({data, start}) => (
                              <div style={{counterReset: `item ${start}`}}>
                                {data
                                  .map((tableRow, index) => (
                                    <PureBlocker
                                      key={uid(tableRow) + (isSelected && isSelected(tableRow) && 'sel') + Math.random()}
                                    >
                                      <div
                                        className={cx(
                                          "table-data__row",
                                          isSelected && isSelected(tableRow) && "table-data__row--active"
                                        )}
                                        style={{
                                          position: 'absolute',
                                          left: 0,
                                          right: 0,
                                          top: (start + index) * LINE_HEIGHT
                                        }}
                                      >
                                        {titleHead.cols.map((_, keyIndex) => (
                                          <div
                                            key={keyIndex}
                                            className={cx("table-data__col", {
                                              "table-data__col--right": titleHead.cols[keyIndex].right
                                            })}
                                            style={{width: `${titleHead.cols[keyIndex].width}%`}}
                                          >
                                          <span
                                            className="table-data__data"
                                            children={(titleHead.cols[keyIndex].display || ItoI)(
                                              tableRow[titleHead.cols[keyIndex].key || keys[keyIndex]]
                                            )}
                                          />
                                          </div>
                                        ))}
                                        <div
                                          className={cx("table-data__absolute-col", {
                                            "table-data__absolute-col--hidden": withoutDataLink
                                          })}
                                        >
                                          {!withoutDataLink && absoluteCol && absoluteCol(tableRow)}
                                        </div>
                                      </div>
                                    </PureBlocker>
                                  ))}
                              </div>
                            )))}
                        </StrollerState>
                      </div>
                    )
                  })
              }
            </StrollableContainer>
          </div>
          <div className="table-data__footer">
            {tableFooter}
            {false && ( // this will disable pagination
              <div className="table-data__footer-row">
                <div
                  className="table-data__pagination"
                >
                  {itemsCount > 10 && (
                    <Pagination
                      count={itemsCount}
                      selected={currentPage}
                      onPageSelect={onPageSelect}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Value>
  );
};

const sortTable = (
  tableData: TableRows,
  by: number,
  order: number,
  titleHead: TableHeader
) => {
  if (tableData.length > 0) {
    const first = tableData[0];
    const keys = Object.keys(first);
    const key = titleHead.cols[by].key || keys[by];
    const clonedData = tableData.slice();
    if (typeof first[key] === "number") {
      return clonedData.sort(
        (a: TableRowNumeric, b: TableRowNumeric) => order * (a[key] - b[key])
      );
    }
    return clonedData.sort(
      (a: TableRow, b: TableRow) =>
        (order > 0 ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
  }
  return [];
};

//const trimTable<T> = (table: T[], from:number, to:number):T[] =>  table.slice(from, to);

export interface TableState {
  page: number;
  sortBy: number;
  sortOrder: number;
}

export interface Predicate {
  name: string,
  selector: (t: TableRow) => boolean;
  view: {
    header?: React.ReactNode;
  }
}

const pickAll = {
  name: "default",
  selector: () => true,
  view: {}
};

const predicateData = (
  tableData: TableRows,
  predicates: Predicate[]
): TableGroups => {
  const result: TableGroups = {};
  // predicates.forEach(predicate => {
  //   result[predicate.name] = {
  //     header: predicate.view.header,
  //     items: []
  //   }
  // });
  tableData.forEach(item => (
    predicates.forEach(predicate => {
      if (predicate.selector(item)) {

        if (!result[predicate.name]) {
          result[predicate.name] = {
            header: predicate.view.header,
            items: []
          }
        }

        result[predicate.name].items.push(item);
      }
    })
  ));
  return result;
};

export class TableDataControl extends React.Component<PublicProps & { tableData: TableRows; predicates?: Predicate[] }, TableState> {
  state = {
    page: 0,
    sortBy: 1,
    sortOrder: -1
  };

  onPageSelect = (page: number) => this.setState({page});
  onSortSelect = (sortBy: number) =>
    this.setState(state => ({
      sortBy,
      sortOrder: state.sortBy === sortBy ? -state.sortOrder : 1
    }));

  render() {
    const {tableData, titleHead, predicates = [pickAll]} = this.props;
    const {page, sortBy, sortOrder} = this.state;

    return (
      <MemoizedFlow
        input={{data: tableData, sortBy, sortOrder, predicates, rows: {} as TableGroups}}
        flow={[
          // sort data
          ({data, sortBy, sortOrder}) => ({
            data: sortTable(data, sortBy, sortOrder, titleHead)
          }),
          ({data, predicates}) => ({
            rows: predicateData(data, predicates)
          })
        ]}
      >
        {({rows, data}) => {
          var res = <BaseTableData
            {...this.props}
            tableRows={rows}
            tableData={data}
            onPageSelect={this.onPageSelect}
            onSortSelect={this.onSortSelect}
            sortedBy={sortBy}
            sortOrder={sortOrder}
            currentPage={page}
            itemsCount={tableData.length}
            groupControl={this.props.groupControl && Object.keys(rows).length > 1}
          />;
          return res;
        }
      }
      </MemoizedFlow>
    );
  }
}
