var React = require('react');

function Download (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"evenodd","transform":"translate(-33.000000, -34.000000)"},React.createElement("path",{"d":"M39,38 L39,35 C39,34.4477153 39.4477153,34 40,34 C40.5522847,34 41,34.4477153 41,35 L41,38 L43,38 L40,41 L37,38 L39,38 Z M45,43 L45,38 C45,37.4477153 45.4477153,37 46,37 C46.5522847,37 47,37.4477153 47,38 L47,44 C47,44.5522847 46.5522847,45 46,45 L34,45 C33.4477153,45 33,44.5522847 33,44 L33,38 C33,37.4477153 33.4477153,37 34,37 C34.5522847,37 35,37.4477153 35,38 L35,43 L45,43 Z","fillRule":"nonzero"})));
}

Download.displayName = "Download";

Download.defaultProps = {"width":"14px","height":"11px","viewBox":"0 0 14 11","version":"1.1"};

module.exports = Download;

Download.default = Download;
