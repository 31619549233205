import { Action, handleActions } from "redux-actions";

import state from "./state";
import { TITLES_SET } from "core/state/titles/actions";

export type TitlesState = typeof state;
const titlesLine = state["0"].data;
export type TitlesStateData = typeof titlesLine;

const titles = handleActions(
  {
    [TITLES_SET]: (
      state: TitlesState,
      action: Action<{ projectId: string; data: TitlesStateData }>
    ): Partial<TitlesState> => ({
      ...state,
      [action.payload!.projectId]: {
        ...(state[action.payload!.projectId] || {}), // to keep local mocks alive
        ...action.payload
      }
    })
  },
  state
);

export { titles };
