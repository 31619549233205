import * as React from "react";
import * as cx from "classnames";

import logoImage from "./images/logo.png";
import logoImage2x from "./images/logo@2x.png";

import "./Logotype.scss";
import { sBEM } from "common/type-helpers";
import { Link } from "react-router-dom";

export const Logotype: sBEM<{
  href: string;
}> = ({ className, href }) => (
  <Link className={cx(className, "logotype")} to={href}>
    <img
      className="logotype__image"
      src={logoImage}
      srcSet={logoImage2x}
      width="330"
      height="52"
      alt="Логотип"
    />
  </Link>
);
