import * as React from "react";
import * as cx from "classnames";

import SvgAnalysis from "svg-react-loader!./images/icons/analysis.svgx";
import SvgScanning from "svg-react-loader!./images/icons/scanning.svgx";

import "./Card.scss";
import {sBEM} from "common/type-helpers";
import {uid} from "core/uid";
import {shortenNumber} from "common/shortenNumbers";
import {MONTHS_NAMES_SHORT} from "common/listMonths";

interface Item {
    mod: string;
    items: {
        mod?: string;
        content?: string | boolean;
    }[];
}

interface CardInterface {
    cardMod?: "premier" | "package" | "scanning" | "geo-analysis";
    statusMod?: "analysis" | "not-available" | "scanning";
    listLink: Item;

    listIssue: Item;

    startDate?: string,
    endDate?: string,

    svgIcon: React.ReactNode;
    svgScale?: React.ReactNode;
    cardTitle: React.ReactNode;
    CardNote: React.ReactNode;
}

export const toDate = (till: string) => {
    const date = new Date(till);
    return MONTHS_NAMES_SHORT[date.getMonth()] + " " + date.getDate() + ', ' + date.getFullYear()
};

export const toEndDate = (till: string | undefined) => {
    return prepareIntervalDate(till, "TBD");
};

function prepareIntervalDate(date: string | undefined, defaultValue?: string | undefined)
{
    return date && toDate(date) || defaultValue;
}

export const Card: sBEM<CardInterface> = ({
                                              className,
                                              cardMod,
                                              statusMod,
                                              listLink,
                                              listIssue,
                                              svgIcon,
                                              svgScale,
                                              cardTitle,
                                              startDate,
                                              endDate,
                                              CardNote,
                                          }) => (
    <button className={cx(className, "card")}>
        <div className="card__wrapper">
            <div className="card__intro-wrapper">
                <h3 className="card__title" children={cardTitle || '\u00A0'}/>
                <p
                    className={cx("card__note", {[`card__note--${cardMod}`]: !!cardMod})}
                    children={CardNote}
                />
                {startDate && endDate &&
                <p className="card__date" children={`${startDate} - ${endDate}`}/>}
            </div>
            <div className="card__info-wrapper">
                {svgIcon && (
                    <div
                        className={cx("card__status", {
                            [`card__status--${statusMod}`]: !!statusMod
                        })}
                    >
                        <span className="card__status-icon" children={svgIcon}/>
                        <span className="card__status-scale" children={svgScale}/>
                    </div>
                )}
                <ul className={cx("card__list", `card__list--${listLink.mod}`)}>
                    {listLink.items.map(item => (
                        <li
                            key={uid(item)}
                            className={cx("card__item", `card__item--${item.mod}`)}
                            children={item.content}
                        />
                    ))}
                </ul>
                <ul className={cx("card__list", `card__list--${listIssue.mod}`)}>
                    {listIssue.items.map(item => (
                        <li
                            key={uid(item)}
                            className={cx("card__item", `card__item--${item.mod}`)}
                            children={item.content}
                        />
                    ))}
                </ul>
            </div>
        </div>
    </button>
);

const radius = 44;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) => Math.round((100 - val) / 100 * diameter);

const ProgressCircle: React.SFC<{ progress: number }> = ({progress}) => (
    <svg
        width="92px"
        height="92px"
        viewBox="0 0 92 92"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            transform="translate(-160.000000, -285.000000)"
        >
            <g transform="translate(70.000000, 190.000000)" stroke-width="3">
                <g transform="translate(92.000000, 97.000000)">
                    <circle opacity="0.200000003" cx="44" cy="44" r="44"/>
                    <circle
                        cx={radius}
                        cy={radius}
                        r={radius}
                        strokeDasharray={diameter}
                        strokeWidth={4}
                        strokeDashoffset={diameter}
                        fill="none"
                        transform="rotate(-90 44 44)"
                        style={{
                            strokeDashoffset: getOffset(100 * progress),
                            transition: "stroke-dashoffset 1s linear"
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const PremierCard: sBEM<{
    title: string;
    linksFound: string;
    linksRemoved: string;
    noticesSent: string;
    dmcaSent?: string;
    completed?: boolean;
    start?: string,
    end?: string,
}> = ({
          className,
          title,
          start,
          end,
          linksFound,
          linksRemoved,
          noticesSent,
          dmcaSent,
          completed
      }) => (
    <Card
        className={className}
        cardMod="premier"
        statusMod="scanning"
        svgIcon={!completed ? <SvgScanning/> : <SvgAnalysis/>}
        svgScale={!completed && <ProgressCircle progress={+linksRemoved / +linksFound}/>}
        cardTitle={title}
        startDate={prepareIntervalDate(start)}
        endDate={toEndDate(end)}
        CardNote="Premiere"
        listLink={{
            mod: "link",
            items: [
                {
                    mod: "detected",
                    content: shortenNumber(+linksFound)
                },
                {
                    mod: "removed",
                    content: shortenNumber(+linksRemoved)
                }
            ]
        }}
        listIssue={{
            mod: "issue",
            items: [
                {
                    mod: "CD",
                    content: shortenNumber(+noticesSent)
                },
                {
                    mod: "DMCA",
                    content: dmcaSent
                }
            ]
        }}
    />
);



export const NotAvailablePremierCard: sBEM<{
    title: string;
    completed?: boolean;
    start?: string,
    end?: string,
}> = ({className, title, completed, start, end}) => (
    <Card
        className={className}
        cardMod="premier"
        statusMod="not-available"
        svgIcon={!completed ? <SvgScanning/> : <SvgAnalysis/>}
        svgScale={!completed && <ProgressCircle progress={0}/>}
        cardTitle={title}
        CardNote="Premiere"
        endDate={end}
        startDate={start}
        listLink={{
            mod: "link",
            items: [
                {
                    mod: "link-not-available",
                    content: false
                }
            ]
        }}
        listIssue={{
            mod: "issue",
            items: [
                {
                    mod: "issue-not-available",
                    content: false
                }
            ]
        }}
    />
);

export const PackageCard: sBEM<{
    title: string;
    linksFound: string;
    linksRemoved: string;
    noticesSent: string;
    dmcaSent?: string;
    completed?: boolean;
}> = ({
          className,
          title,
          linksFound,
          linksRemoved,
          noticesSent,
          completed
      }) => (
    <Card
        className={className}
        cardMod="package"
        statusMod="scanning"
        svgIcon={!completed ? <SvgScanning/> : <SvgAnalysis/>}
        svgScale={!completed && <ProgressCircle progress={+linksRemoved / +linksFound}/>}
        cardTitle={title}
        CardNote="Package"
        listLink={{
            mod: "link",
            items: [
                {
                    mod: "detected",
                    content: shortenNumber(+linksFound)
                },
                {
                    mod: "removed",
                    content: shortenNumber(+linksRemoved)
                }
            ]
        }}
        listIssue={{
            mod: "issue",
            items: [
                {
                    mod: "CD",
                    content: shortenNumber(+noticesSent)
                },
                {}
            ]
        }}
    />
);

export const ScanningCard: sBEM<{
    title: string;
    linksCount: string;
    titlesScanned: string;
    websitesFound: string;
    completed?: boolean;
}> = ({
          className,
          title,
          linksCount,
          titlesScanned,
          websitesFound,
          completed
      }) => (
    <Card
        className={className}
        cardMod="scanning"
        statusMod="analysis"
        svgIcon={<SvgAnalysis/>}
        cardTitle={title}
        CardNote="Scanning results"
        listLink={{
            mod: "link",
            items: [
                {
                    mod: "scan-detected",
                    content: shortenNumber(+linksCount)
                }
            ]
        }}
        listIssue={{
            mod: "issue",
            items: [
                {
                    mod: "titles",
                    content: shortenNumber(+titlesScanned)
                },
                {
                    mod: "websites",
                    content: shortenNumber(+websitesFound)
                }
            ]
        }}
    />
);

export const GeoAnalysisCard: sBEM<{
    title: string;
    detectedIPS: string;
    titlesScanned: string;
    websitesFound: string;
    completed?: boolean;
}> = ({
          className,
          title,
          detectedIPS,
          titlesScanned,
          websitesFound,
          completed
      }) => (
    <Card
        className={className}
        cardMod="geo-analysis"
        statusMod="analysis"
        svgIcon={<SvgAnalysis/>}
        cardTitle={title}
        CardNote="Bittorrent Geo-Analysis"
        listLink={{
            mod: "link",
            items: [
                {
                    mod: "scan-ips",
                    content: shortenNumber(+detectedIPS)
                }
            ]
        }}
        listIssue={{
            mod: "issue",
            items: [
                {
                    mod: "titles",
                    content: shortenNumber(+titlesScanned)
                },
                {
                    mod: "websites",
                    content: shortenNumber(+websitesFound)
                }
            ]
        }}
    />
);
