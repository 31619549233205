export const inFormData = (data: { [key: string]: string }) => {
  const form = new FormData();
  Object.keys(data).forEach(key => form.append(key, data[key]));
  return form;
};

const inDeep = <P, D>(form: URLSearchParams, parentkey: P, data: D) => {
  Object.keys(data).forEach(key => {
    if (typeof data[key] === "object" && data[key]) {
      inDeep(form, parentkey + "." + key, data[key]);
    } else {
      form.append(parentkey + "." + key, data[key]);
    }
  });
};

export const inSearchParams = (data: { [key: string]: any }) => {
  const form = new URLSearchParams();
  Object.keys(data).forEach(key => {
    if (typeof data[key] === "object" && data[key]) {
      inDeep(form, key, data[key]);
    } else {
      form.append(key, data[key]);
    }
  });
  return form;
};

export const inJSON = (data: any) => JSON.stringify(data);
