var React = require('react');

function Pen (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"nonzero"},React.createElement("path",{"d":"M6.21759647,1.66230829 L8.25486151,3.69957333 L3.10545539,8.8364809 L1.0806889,6.81171441 L6.21759647,1.66230829 Z M9.80468277,1.1748645 L8.892288,0.26246973 C8.54232836,-0.0874899099 7.96739466,-0.0874899099 7.61743502,0.26246973 L6.74253592,1.13736883 L8.77980097,3.17463387 L9.79218421,2.16225063 C10.0671525,1.87478378 10.0671525,1.43733423 9.80468277,1.1748645 Z M0.0058128646,9.66138576 C-0.0316828111,9.82386702 0.118299892,9.97384973 0.280781153,9.93635405 L2.54302025,9.38641747 L0.530752324,7.36165099 L0.0058128646,9.66138576 Z"})));
}

Pen.displayName = "Pen";

Pen.defaultProps = {"width":"10px","height":"10px","viewBox":"0 0 10 10","version":"1.1"};

module.exports = Pen;

Pen.default = Pen;
