import { put, takeLatest, all, take, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import { USER_AUTHENTICATED, USER_SELECT } from "core/state/user/actions";
import { PROJECTS_LOAD } from "core/state/projects/actions";
import {
  INTERVAL_SET,
  REGISTER_HOOK,
  SELECT_TITLES,
  UNREGISTER_HOOK
} from "core/state/meta/actions";

function* onUserAuth() {
  yield put({ type: PROJECTS_LOAD });
}

function* updateHook_saga() {
  let hooks: (() => any)[] = [];
  while (1) {
    const event = yield take([
      INTERVAL_SET,
      SELECT_TITLES,
      REGISTER_HOOK,
      UNREGISTER_HOOK
    ]);
    if (event.payload && event.payload.noHook) {
      continue;
    }
    switch (event.type) {
      case REGISTER_HOOK:
        hooks.push(event.payload);
        break;
      case UNREGISTER_HOOK:
        hooks = hooks.filter(h => h !== event.payload);
        break;
      default:
        hooks.forEach(h => h());
    }
  }
}

export function* metaSaga(): SagaIterator {
  yield all([]);
}

export function* hooksSaga(): SagaIterator {
  yield all([
    takeLatest([USER_AUTHENTICATED, USER_SELECT], onUserAuth),
    call(updateHook_saga)
  ]);
}
