import * as React from "react";
import { ApplicationContainer } from "./ApplicationContainer";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";

export const Application = () => (
  <ApplicationContainer>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </ApplicationContainer>
);
