export const countryToIso = [
  { id: 1, name: "Russia", iso: "RU" },
  { id: 2, name: "United States", iso: "US" },
  { id: 3, name: "India", iso: "IN" },
  { id: 4, name: "United Kingdom", iso: "GB" },
  { id: 5, name: "Ukraine", iso: "UA" }
].reduce((acc: any, line: any) => {
  acc[line.id] = line.iso;
  acc[line.iso] = line.id;
  return acc;
}, {});
