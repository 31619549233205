var React = require('react');

function Exit (props) {
    return React.createElement("svg",props,React.createElement("g",{"fillRule":"evenodd","transform":"translate(-1280.000000, -28.000000)"},React.createElement("g",{"transform":"translate(1281.000000, 28.000000)"},[React.createElement("path",{"fill":"none","strokeWidth":"2","strokeLinecap":"round","d":"M3.75518287,2.4665804 C1.80947058,3.58972147 0.5,5.69193465 0.5,8.09999108 C0.5,11.689842 3.41014913,14.5999911 7,14.5999911 C10.5898509,14.5999911 13.5,11.689842 13.5,8.09999108 C13.5,5.69813899 12.1972684,3.60055617 10.2598438,2.47528118","key":0}),React.createElement("rect",{"stroke":"none","x":"6","y":"0","width":"2","height":"9","rx":"1","key":1})])));
}

Exit.displayName = "Exit";

Exit.defaultProps = {"width":"16px","height":"16px","viewBox":"0 0 16 16","version":"1.1"};

module.exports = Exit;

Exit.default = Exit;
