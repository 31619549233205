var React = require('react');

function Close (props) {
    return React.createElement("svg",props,React.createElement("g",{"stroke":"none","strokeWidth":"1","fillRule":"evenodd","transform":"translate(-466.000000, -9.000000)"},React.createElement("path",{"d":"M472,15 L472,8 L474,8 L474,15 L481,15 L481,17 L474,17 L474,24 L472,24 L472,17 L465,17 L465,15 L472,15 Z","transform":"translate(473.000000, 16.000000) rotate(-315.000000) translate(-473.000000, -16.000000) "})));
}

Close.displayName = "Close";

Close.defaultProps = {"width":"14px","height":"14px","viewBox":"0 0 14 14","version":"1.1"};

module.exports = Close;

Close.default = Close;
