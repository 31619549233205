import * as React from "react";
import * as cx from "classnames";

import "./TopWebsites.scss";
import { shortenNumber } from "common/shortenNumbers";
import { sBEM } from "common/type-helpers";

const ItoI = (I: any) => I;

export const BaseTopWebsites: sBEM<{
  titleHead: {
    width: number;
    content: string;
    display?: (a: any) => any;
    right?: boolean;
    key: string;
  }[];
  websitesData: {}[];
}> = ({ className, titleHead, websitesData }) => (
  <table className={cx(className, "websites")}>
    <thead>
      <tr className="websites__header-row">
        {titleHead.map(
          (col, index) =>
            index === 0 ? (
              <th
                key={index}
                className="websites__title"
                style={{ width: `${col.width}%` }}
                children={col.content}
              />
            ) : (
              <th
                key={index}
                className={cx(
                  "websites__head",
                  col.right && "websites__head--right"
                )}
                style={{ width: `${col.width}%` }}
                children={col.content}
              />
            )
        )}
      </tr>
    </thead>
    <tbody>
      {websitesData.map((tableRow, index) => (
        <tr className="websites__row" key={index}>
          {titleHead.map((col, keyIndex) => (
            <td
              key={keyIndex}
              className={cx(
                "websites__col",
                col.right && "websites__col--right"
              )}
              style={{ width: `${col.width}%` }}
              children={(col.display || ItoI)(tableRow[col.key])}
            />
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const mapWebsitesData = [
  {
    name: "youtube.com",
    visit: 32197000,
    links: 1931
  },
  {
    name: "vk.ru",
    visits: 29197000,
    links: 235
  },
  {
    name: "hdrezka.me",
    visits: 2197000,
    links: 25
  },
  {
    name: "bigcinema.to",
    visits: 2051000,
    links: 18
  },
  {
    name: "extratorrent.cc",
    visits: 1895000,
    links: 12
  }
];

export interface WebsiteMapStat {
  host: string;
  visits: number;
  links: number;
}

export const MapTopWebsites: sBEM<{ data: WebsiteMapStat[] }> = props => (
  <BaseTopWebsites
    {...props}
    titleHead={[
      {
        width: 40,
        content: "Top websites",
        key: "host"
      },
      {
        content: "Mon. visits",
        width: 30,
        display: shortenNumber,
        right: true,
        key: "visits"
      },
      {
        content: "Links",
        width: 30,
        display: shortenNumber,
        right: true,
        key: "links"
      }
    ]}
    websitesData={props.data || mapWebsitesData}
  />
);
