import { Action, handleActions } from "redux-actions";

import state from "./state";
import {
  WEBSITES_LINKS_SET,
  WEBSITES_SET,
  WEBSITES_TYPES_SET
} from "core/state/websites/actions";

export type WebsitesState = typeof state;
const websitesLine = state["5"];
export type TopWebsitesStateData = typeof websitesLine;
export type WebsitesStateData = typeof websitesLine.sites.data;
export type WebsitesStateDataItem = typeof websitesLine.sites.data[0];

const merge = (state: any, id: any, prop: any, value: any) => {
  const oldState = state[id] || {};
  return {
    ...state,
    [id]: {
      ...oldState,
      [prop]: {
        ...(oldState[prop] || {}),
        ...value
      }
    }
  };
};

export type WebsiteVisit = { countryId: number; visitsFraction: number };

const websites = handleActions(
  {
    [WEBSITES_SET]: (
      state: WebsitesState,
      action: Action<{ projectId: string; data: WebsitesStateData }>
    ): Partial<WebsitesState> =>
      merge(state, action.payload!.projectId, "sites", action.payload!),

    [WEBSITES_TYPES_SET]: (
      state: WebsitesState,
      action: Action<{ projectId: string; data: WebsitesStateData }>
    ): Partial<WebsitesState> =>
      merge(state, action.payload!.projectId, "types", action.payload!),

    [WEBSITES_LINKS_SET]: (
      state: WebsitesState,
      action: Action<{ projectId: string; data: WebsitesStateData }>
    ): Partial<WebsitesState> =>
      merge(state, action.payload!.projectId, "links", action.payload!)
  },
  state
);

export { websites };
