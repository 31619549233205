export const OPEN_MODAL = "modal/OPEN";
export const CLOSE_MODAL = "modal/CLOSE";

export const openModal = (modalName: string) => ({
  type: OPEN_MODAL,
  payload: modalName
});
export const closeModal = (modalName: string) => ({
  type: CLOSE_MODAL,
  payload: modalName
});
