var React = require('react');

function Calendar (props) {
    return React.createElement("svg",props,React.createElement("g",{"strokeWidth":"1","fillRule":"evenodd"},[React.createElement("rect",{"fill":"none","strokeWidth":"2","x":"1","y":"3","width":"14","height":"14","rx":"2","key":0}),React.createElement("rect",{"stroke":"none","x":"3","y":"0","width":"1","height":"2","key":1}),React.createElement("rect",{"stroke":"none","x":"12","y":"0","width":"1","height":"2","key":2}),React.createElement("rect",{"stroke":"none","fillRule":"evenodd","x":"2","y":"7","width":"12","height":"1","key":3}),React.createElement("rect",{"fill":"none","strokeWidth":"1","x":"2.5","y":"7.5","width":"11","height":"1","key":4})]));
}

Calendar.displayName = "Calendar";

Calendar.defaultProps = {"width":"16px","height":"18px","viewBox":"0 0 16 18","version":"1.1"};

module.exports = Calendar;

Calendar.default = Calendar;
