import * as React from "react";
import * as cx from "classnames";
import { sBEM } from "common/type-helpers";

import SvgDownload from "svg-react-loader!./images/icons/download.svgx";

import "./Download.scss";
import { uid } from "core/uid";
import { URLConsumer } from "components/URLConsumer/URLConsumer";
import { downloadWebsite } from "core/state/websites/actions";
import { connect } from "react-redux";
import {
    isMasterAccount
} from "core/state/user/selectors";


enum DownloadType {
  FileReport,
  FinalReport,
  ComplianceReport
}

interface Item {
  id: string;
  caption: string;
  type: DownloadType;
}

interface Formats {
  items: Item[];
  onClick: (i: Item) => any;
}

const makeDownloadCaption = (item: Item) => {
  switch (item.type) {
    case DownloadType.FileReport:
      return `Download .${item.caption}`;
    case DownloadType.FinalReport:
    case DownloadType.ComplianceReport:
      return `${item.caption}`;

    default:
      throw "Wrong type";
  }
};

export const BaseDownload: sBEM<Formats> = ({ className, items, onClick }) => (
  <form className={cx(className, "download")}>
    <ul className="download__list">
      {items.map(item => (
        <li className="download__item" key={uid(item)}>
          <button
            className="download__button"
            type="button"
            onClick={() => onClick(item)}
          >
            <SvgDownload className="download__svg" />
            {makeDownloadCaption(item)}
          </button>
        </li>
      ))}
    </ul>
  </form>
);

const documentsFormatsList = [
  { id: "csv", caption: "Csv", type: DownloadType.FileReport },
  { id: "xls", caption: "exсel", type: DownloadType.FileReport }
];

const reportTypesList = [
  { id: "final", caption: "Final Report", type: DownloadType.FinalReport },
  { id: "compl", caption: "Compliance Report", type: DownloadType.ComplianceReport }
];

const UnconnectedDocumentDownload: sBEM<{ isMaster: boolean, downloadWebsite: any }> = ({
  isMaster,
  downloadWebsite,
  ...props
}) => (
  <URLConsumer>
    {match => (
      <BaseDownload
        {...props}
        items={isMaster ? [...documentsFormatsList, ...reportTypesList] : documentsFormatsList }
        onClick={type => downloadWebsite(match, type.id)}
      />
    )}
  </URLConsumer>
);



export const DocumentDownload = connect((s: any) => ({ isMaster: isMasterAccount(s) }), { downloadWebsite })(
  UnconnectedDocumentDownload
);

