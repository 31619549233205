import * as React from "react";
import * as cx from "classnames";

import "./ListOfCountries.scss";
import { shortenNumber } from "common/shortenNumbers";
import { sBEM } from "common/type-helpers";
import { webConnect } from "core/redux";
import {
  CountryWithValue,
  getLinksGeoStatistic,
  getPeersGeoStatistic
} from "core/state/stat/selectors";
import { StoreShape } from "core/reducers";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

type DataCountry = CountryWithValue;

type ListCountries = DataCountry[];

interface PublicProps {
  hidden?: boolean;
  onLineHover?: (country: DataCountry | null, level: number) => void;
}

const getRelativePosition = (node: HTMLElement) => {
  const elPos = node.getBoundingClientRect();
  const parent = (node.parentNode! as HTMLElement).getBoundingClientRect();
  return elPos.top - parent.top + 80;
};

const UnconnectedBaseListOfCountries: sBEM<
  {
    listCountries: ListCountries;
    captionIndex: string;
    scale: number[];
  } & PublicProps &
    RouteComponentProps<any>
> = ({
  className,
  listCountries,
  captionIndex,
  scale,
  hidden,
  onLineHover = () => {},
  match
}) => (
  <div
    className={cx(className, "list-of-countries", {
      "list-of-countries--hidden": hidden
    })}
  >
    <div className="list-of-countries__info">
      <h3 className="list-of-countries__title" children="Top countries" />
      <p className="list-of-countries__note" children={captionIndex} />
    </div>
    <ul className="list-of-countries__list">
      {listCountries.map((item, index) => (
        <li
          key={item.code}
          className="list-of-countries__item"
          onMouseEnter={(e: React.MouseEvent<HTMLLIElement>) =>
            onLineHover(
              item,
              getRelativePosition(e.currentTarget as any)
            )
          }
          onMouseLeave={() => onLineHover(null, 0)}
        >
          <span className="list-of-countries__link">
            <h4
              className="list-of-countries__country"
              children={item.name}
            />
            <span
              className="list-of-countries__index"
              children={shortenNumber(item.value)}
            />
            <span
              className="list-of-countries__scale"
              style={{ width: `${scale[index]}%` }}
            />
          </span>
        </li>
      ))}
    </ul>
  </div>
);

export const BaseListOfCountries = withRouter(UnconnectedBaseListOfCountries);

const getScales = (values: number[]): number[] => {
  const max = values.reduce( (acc:number, x) => acc+x, 0);
  return values.map(v => 100 * v / max).map(Math.round);
};

type ConnectedData = { countries: { list: DataCountry[]; values: number[] } };

const ViewLinksListOfCountries: sBEM<PublicProps & ConnectedData> = props => {
  return (
  <BaseListOfCountries
    {...props}
    captionIndex="Monthly Visits"
    scale={getScales(props.countries.values.sort((a, b) => -a + b).slice(0, 5))}
    listCountries={props.countries.list.sort((a, b) => -a.value + b.value).slice(0, 5)}
  />
)
};

const ViewPeersListOfCountries: sBEM<PublicProps & ConnectedData> = props => (
  <BaseListOfCountries
    {...props}
    captionIndex="# of Peers"
    scale={getScales(props.countries.values)}
    listCountries={props.countries.list.slice(0, 5)}
  />
);

export const LinksListOfCountries = webConnect(
  (state: StoreShape, { projectId }: { projectId: string }) => ({
    countries: getLinksGeoStatistic(state, projectId)
  })
)(ViewLinksListOfCountries);

export const PeersListOfCountries = webConnect(state => ({
  countries: getPeersGeoStatistic()
}))(ViewPeersListOfCountries);
