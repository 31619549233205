import * as React from "react";
import * as cx from "classnames";

import { BlueCheckbox } from "components/UIKit/checkbox/index";
import { AddSvgLink } from "components/UIKit/svglink/index";

import "./AccountNotifications.scss";
import { sBEM } from "common/type-helpers";
import { uid } from "core/uid";
import {
  getCopyEmails,
  getDailyEmails,
  getUserNotifications
} from "core/state/user/selectors";
import { webConnect } from "core/redux";
import { shortMailName } from "components/account-dashboard/components/shortName";
import { setBlockNotification } from "core/state/user/actions";

interface DataNotificationsOptions {
  key: string;
  label: string;
  team: string[];
}

type listNotificationsOptions = DataNotificationsOptions[];

interface Props {
  accountNotificationsTitle?: string;
  listNotificationsOptions: listNotificationsOptions;
  onClick: (key: string) => any;
  changeUserLevelNotification: (item: string, flag: boolean) => any;
  notifications: {
    sentDailyReports: boolean;
    sentNotices: boolean;
  };
}

export const BaseAccountNotifications: sBEM<Props> = ({
  className,
  accountNotificationsTitle = "E-mail notification",
  listNotificationsOptions,
  onClick,
  notifications,
  changeUserLevelNotification
}) => (
  <section className={cx(className, "account-notifications")}>
    <h3
      className={"account-notifications__title"}
      children={accountNotificationsTitle}
    />
    <ul className={"account-notifications__list"}>
      {listNotificationsOptions.map(item => (
        <li className="account-notifications__item" key={uid(item)}>
          <BlueCheckbox
            className="account-notifications__checkbox"
            id={"notifications-id-" + item.label}
            name="save"
            caption={item.label}
            checked={notifications[item.key]}
            onChange={e =>
              changeUserLevelNotification(item.key, e.target.checked)
            }
          />
          <ul className={"account-notifications__team"}>
            {item.team.map((member, index) => (
              <li
                className={"account-notifications__member"}
                children={shortMailName(member)}
                key={index}
              />
            ))}
          </ul>
          <AddSvgLink
            className={"account-notifications__add-member"}
            onClick={() => onClick(item.key)}
          />
        </li>
      ))}
    </ul>
  </section>
);

export const AccountNotifications = webConnect(
  state => ({
    notifications: getUserNotifications(state),
    listNotificationsOptions: [
      {
        key: "sentNotices",
        label: "Send notification when the copy appears",
        team: getCopyEmails(state).map(({ name }) => name)
      },
      {
        key: "sentDailyReports",
        label: "Daily notification and report",
        team: getDailyEmails(state).map(({ name }) => name)
      }
    ]
  }),
  {
    changeUserLevelNotification: setBlockNotification
  }
)(BaseAccountNotifications);
