var React = require('react');

function Loader (props) {
    return React.createElement("svg",props,[React.createElement("title",{"key":0},"Combined Shape"),React.createElement("desc",{"key":1},"Created with Sketch."),React.createElement("g",{"id":"Final-version","key":2},React.createElement("g",{"id":"Hovers","transform":"translate(-1075.000000, -630.000000)"},React.createElement("g",{"id":"noun_loading_35633","transform":"translate(1075.000000, 630.000000)"},React.createElement("g",{"id":"Group"},React.createElement("path",{"id":"Combined-Shape","d":"M16,3.2c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9\r\n\t\t\t\t\tC16.4,4.1,16,3.7,16,3.2z M19.8,7c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9C20.2,7.9,19.8,7.5,19.8,7\r\n\t\t\t\t\tz M22.1,13.2c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C23,12.7,22.6,13.2,22.1,13.2z M20.7,18.6\r\n\t\t\t\t\tc-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1S21.3,18.6,20.7,18.6z M15.8,21.3c0-0.6,0.5-1.1,1.1-1.1\r\n\t\t\t\t\tc0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C16.3,22.4,15.8,21.9,15.8,21.3z M10.5,22.8c0-0.7,0.6-1.2,1.2-1.2\r\n\t\t\t\t\tc0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2C11.1,24,10.5,23.4,10.5,22.8z M6.6,22.5c-0.7,0-1.2-0.6-1.2-1.2\r\n\t\t\t\t\tc0-0.7,0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2C7.9,22,7.3,22.5,6.6,22.5z M1.4,17.4C1.4,16.7,2,16,2.8,16c0.8,0,1.4,0.6,1.4,1.4\r\n\t\t\t\t\tc0,0.8-0.6,1.4-1.4,1.4C2,18.9,1.4,18.2,1.4,17.4z M0,12.2c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4\r\n\t\t\t\t\tC0.6,13.6,0,13,0,12.2z M1.2,7c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6C1.9,8.7,1.2,7.9,1.2,7z\r\n\t\t\t\t\t M5,3.2c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6C5.7,4.9,5,4.1,5,3.2z M10,1.8c0-1,0.8-1.8,1.8-1.8\r\n\t\t\t\t\tc1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8C10.8,3.6,10,2.8,10,1.8z"})))))]);
}

Loader.displayName = "Loader";

Loader.defaultProps = {"version":"1.1","id":"Слой_1","x":"0px","y":"0px","viewBox":"-1 -1 26 26","style":{"enableBackground":"new 0 0 23 24"},"xmlSpace":"preserve"};

module.exports = Loader;

Loader.default = Loader;
