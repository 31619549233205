import * as React from "react";
import * as cx from "classnames";

import "./Notifications.scss";
import { sBEM } from "common/type-helpers";
import { uid } from "core/uid";
import { webConnect } from "core/redux";
import { getNotifications } from "core/state/notifications/selectors";
import { Link } from "react-router-dom";
import { shortenDate } from "common/shortedDate";

export const BaseNotifications: sBEM<{
  listNotifications: {
    message: string;
    time: number;
    id: string;
    read: boolean;
  }[];
}> = ({ className, listNotifications }) => (
  <div className={cx(className, "notifications")}>
    <h3 className="notifications__title" children="Notifications list" />
    <div className="notifications__scroll-wrapper">
      <ul className="notifications__list">
        {listNotifications.map(item => (
          <li
            className={cx("notifications__item", {
              "notifications__item--active": item.read
            })}
            key={uid(item)}
          >
            <Link
              className={cx("notifications__link", {
                "notifications__link--active": item.read
              })}
              children={item.message}
              to={`notifications/${item.id}`}
            />
            <span
              className="notifications__time"
              children={shortenDate(item.time)}
            />
          </li>
        ))}
      </ul>
      <div className="notifications__scrollbar" />
    </div>
  </div>
);

export const Notifications = webConnect(state => ({
  listNotifications: getNotifications(state)
}))(BaseNotifications);
